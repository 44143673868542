import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-ratings',
  templateUrl: './custom-ratings.component.html',
  styleUrls: ['./custom-ratings.component.scss'],
})
export class CustomRatingsComponent implements OnInit {

  @Input() rate: number;
  @Input() data: any;
  @Input() showRate = true;

  constructor() { }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'CustomRatingsComponent';
  }

  ngOnInit() {
  }

  getIcon(value: number) {
    let icon = 'star';
    if (this.rate >= value) {
      icon = 'star-active';
    } else if ((value > this.rate) && ((value % this.rate) < 1) && ((value - (this.rate + 1)) < 0)) {
      icon = 'star-half';
    } else {
      icon = 'star';
    }
    return icon;
  }

}
