/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { KreadeetTransactionInfo, PaymentTransactionInfo } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { NgPipesModule } from 'ngx-pipes';


@Component({
  selector: 'app-transaction-details',
  providers: [NgPipesModule],
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss'],
})
export class TransactionDetailsComponent implements OnInit {

  @Input() loading = false;
  @Input() data: KreadeetTransactionInfo | PaymentTransactionInfo = {};
  @Input() type = 'kreadeet';
  @Input() receiptType = 1;
  currentUser: any = {};
  canGenerateReceipt = false;


  constructor(
    public navController: NavController,
    public screenSizeService: ScreenSizeService,
    public loadingController: LoadingController,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService,
    public alertController: AlertController) { }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'TransactionDetailsComponent';
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  async getProfile() {
    await this.apiService.getAuthenticatedUser()
      .then(async (currentUser: ApiGenericResponseInfo) => {
        this.currentUser = currentUser;
        this.canGenerateReceipt = this.currentUser.switched_to_corporate ? false : true;
      })
      .catch(async (err: ApiErrorResponse) => {
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  async viewDetails() {

  }

  goBack() {
    this.navController.back();
  }

  ngOnInit() {
    this.getProfile();
  }

  async generateReceipt() {
  }


}
