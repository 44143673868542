/* eslint-disable id-blacklist */
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppSelectableComponent } from '@app-components/common/app-selectable/app-selectable.component';
import { SelectableOption, SelectableOptionItem } from '@app-components/common/app-selectable/interface';
import { VerifyPinComponent } from '@app-components/common/verify-pin/verify-pin.component';
import { environment } from '@app-environments/environment';
import {
  ApiErrorResponse, ApiGenericResponseInfo,
  BundleOrBouquetResponse, ElectricityResponse
} from '@app-models/api.response.interface';
import { balances, BundleOrBouquet, UsersInfo } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { ComponentPickerService } from '@app-services/component-picker/component-picker.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { SliderConfigService } from '@app-services/slider-config/slider-config.service';
import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { SwiperOptions } from 'swiper';
@Component({
  selector: 'app-electricity',
  templateUrl: './electricity.component.html',
  styleUrls: ['./electricity.component.scss'],
})
export class ElectricityComponent implements OnInit, OnDestroy {

  @Input() title = 'Electricity';
  isDesktop: boolean;
  sliderConfig: SwiperOptions = {
    initialSlide: 0
  };
  data: any = {
    phone: !environment.production ? '08011111111' : '',
  };
  balances: balances = this.apiService.userBalance;
  balancesSubscription: any;
  distributors: ReadonlyArray<BundleOrBouquet> = [];
  types: SelectableOptionItem[] = [
    {
      name: 'prepaid',
      label: 'Prepaid',
    },
    {
      name: 'postpaid',
      label: 'Postpaid',
    }
  ];
  isPwa: boolean;
  corporateMode = false;
  corporateBalance;

  constructor(// tslint:disable: align
    public apiService: ApiService,
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    private actionSheetCtrl: ActionSheetController,
    private helperMethods: HelperMethodsService,
    public componentPickerService: ComponentPickerService,
    private ref: ChangeDetectorRef,
    private sliderConfigService: SliderConfigService,
    public modalController: ModalController,
    public alertController: AlertController,
    public cryptoService: CryptoService) {
    this.isPwa = environment.isPwa;
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
    this.sliderConfigService.getSliderConfig2().then((config) => {
      this.sliderConfig = config;
    });
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'ElectricityComponent';
  }

  ngOnInit() {
    this.sliderConfigService.getSliderConfig2().then((config) => {
      this.sliderConfig = config;
    });
  }

  async ionViewDidEnter() {
    await this.apiService.getAuthenticatedUser().then((res: UsersInfo) => {
      this.corporateMode = res.profile.switchedToCorporate;
      if (!this.corporateMode) {
        this.subscribeToCurrentBalance();
        this.getElectricityProviders();
        return;
      }
      this.getCorporateElectricityProviders();
      this.corporateBalance = {
        account:
          this.helperMethods.formatAmount(
            res?.profile?.loggedInCorporateAccount?.corporateStaffLimitConfig?.monthlyKreadeetSpent?.toString()
          ),
        balance: res.profile.loggedInCorporateAccount.corporateStaffLimitConfig.balance,
        balanceTitle: 'What you have spent: '
      };
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

  subscribeToCurrentBalance() {
    this.balancesSubscription = this.apiService.getCurrentBalances.subscribe((newBalances) => {
      this.balances = newBalances;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.balancesSubscription) {
      this.balancesSubscription.unsubscribe();
    }
  }

  ionViewWillLeave() {
    if (this.balancesSubscription) {
      this.balancesSubscription.unsubscribe();
    }
  }

  /**
   * Dismiss Modal
   */
  dismissModal(moreData: any = {}) {
    this.modalController.dismiss({
      dismissed: true,
      moreData
    }).then(() => {
      // console.log(data);
    }).catch((err) => console.log(err));
  }


  async getElectricityProviders() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.getElectricityProviders())
      .then(async (res: BundleOrBouquetResponse) => {
        await loading.dismiss();
        this.distributors = res.data;
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
        await loading.dismiss();
      });
  }

  async getCorporateElectricityProviders() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.getCorporateElectricityProviders())
      .then(async (res: BundleOrBouquetResponse) => {
        await loading.dismiss();
        this.distributors = res.data;
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
        await loading.dismiss();
      });
  }

  async openCustomDiscoSelector(items: any) {
    const selectableOption: SelectableOption = {
      title: 'Select Disco Provider',
      enableSearch: true,
      items: items as Array<SelectableOptionItem>,
      itemTextField: 'name',
      // itemNoteField: 'formattedAmount',
      // itemIconField: 'imageUrl',
    };
    const modal = await this.apiService.openComponent(AppSelectableComponent, { selectableOption }, 70, false);
    await modal.onWillDismiss().then((info) => {
      console.log(info);
    });
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.data = { ...data.moreData, disco: data.moreData.name };
      console.log(this.data);
    }
  }

  async openCustomTypeSelector(items: any) {
    const selectableOption: SelectableOption = {
      title: 'Select Disco Provider',
      enableSearch: true,
      items: items as Array<SelectableOptionItem>,
      itemTextField: 'label',
      // itemNoteField: 'formattedAmount',
      // itemIconField: 'imageUrl',
    };
    const modal = await this.apiService.openComponent(AppSelectableComponent, { selectableOption }, 70, false);
    await modal.onWillDismiss().then((info) => {
      console.log(info);
    });
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.data = { ...this.data, ...data.moreData, type: data.moreData.name };
      console.log(this.data);
    }
  }


  async openVerifyPin() {
    const modal = await this.apiService.openComponent(VerifyPinComponent, {}, 80, false);
    await modal.onWillDismiss().then((info) => {
      console.log(info);
    });
    const { data } = await modal.onDidDismiss();
    console.log(data);
  }

  async canDismiss() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Are you sure you want cancel payment?',
      buttons: [
        {
          text: 'Yes, I want to',
          role: 'destructive'
        },
        {
          text: 'No, Continue',
          role: 'cancel',
        }
      ]
    });

    await actionSheet.present();
    const { role } = await actionSheet.onDidDismiss();
    if (role === 'destructive') {
      return true;
    }
    return false;
  }


  async checkMeterNumber() {
    if (this.data.meterNumber.length >= 10) {
      const loading = await this.apiService.getLoader();
      await loading.present();
      await this.helperMethods.promiseTimeout(this.apiService.validateMeter(this.data))
        .then(async (res: ApiGenericResponseInfo) => {
          await loading.dismiss();
          if (res.status) {
            this.data = {
              ...this.data,
              customerName: res?.data?.customerName,
              cableStatus: res?.data?.status,
            };
            if (res?.data?.currentBouquet !== 'N/A') {
              this.data = {
                ...this.data,
                address: res?.data?.address,
                phone: res?.data?.customerNumber,
              };
            }
            console.log(this.data, res.data);
          } else {
            this.data.address = '';
            this.data.phone = '';
            // this.apiService.errorAlert(res.message, res?.header);
          }
        })
        .catch(async (err: ApiErrorResponse): Promise<void> => {
          console.log(err);
          await loading.dismiss();
          this.data.address = '';
          this.data.phone = '';
        });
    }
  }

  async checkCorporateMeterNumber() {
    if (this.data.meterNumber.length >= 10) {
      const loading = await this.apiService.getLoader();
      await loading.present();
      await this.helperMethods.promiseTimeout(this.apiService.validateCorporateMeter(this.data))
        .then(async (res: ApiGenericResponseInfo) => {
          await loading.dismiss();
          if (res.status) {
            this.data = {
              ...this.data,
              customerName: res?.data?.customerName,
              cableStatus: res?.data?.status,
            };
            if (res?.data?.currentBouquet !== 'N/A') {
              this.data = {
                ...this.data,
                address: res?.data?.address,
                phone: res?.data?.customerNumber,
              };
            }
            console.log(this.data, res.data);
          } else {
            this.data.address = '';
            this.data.phone = '';
            // this.apiService.errorAlert(res.message, res?.header);
          }
        })
        .catch(async (err: ApiErrorResponse): Promise<void> => {
          console.log(err);
          await loading.dismiss();
          this.data.address = '';
          this.data.phone = '';
        });
    }
  }

  electricityPurchaseHandler() {
    if (!this.corporateMode) {
      this.purchaseElectricity();
      return;
    }
    this.purchaseCorporateElectricity();
  }

  async purchaseElectricity() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.purchaseElectricity(this.data))
      .then(async (res: ElectricityResponse) => {
        await loading.dismiss();
        if (res.status) {
          this.dismissModal();
          const code = res.data.token;
          // let message = `<div class="message">Your single-use code is <br>`;
          // message += `<p class="token"> ${code} </p>Congratulations, your code was successfully created.</div>`;
          const message = `<div class="message">Congratulations, your code was successfully created.</div>`;
          this.tokenModal(message);
          // this.showTokenAlert(res.message, res?.data?.token);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  async purchaseCorporateElectricity() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    // const payload: any = {
    //   meterNumber: this.data.meterNumber,
    //   code: this.data.code,
    //   type: this.data.type,
    //   phone: this.data.phone,
    //   amount: +this.data.amount
    // };
    await this.helperMethods.promiseTimeout(this.apiService.purchaseCorporateElectricity(this.data))
      .then(async (res: ElectricityResponse) => {
        await loading.dismiss();
        if (res.status) {
          this.dismissModal();
          const code = res.data.token;
          // let message = `<div class="message">Your single-use code is <br>`;
          // message += `<p class="token"> ${code} </p>Congratulations, your code was successfully created.</div>`;
          const message = `<div class="message">Congratulations, your code was successfully created.</div>`;
          this.tokenModal(message);
          // this.showTokenAlert(res.message, res?.data?.token);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  async showTokenAlert(message: string, token: string) {
    let html = '';
    html += `<h1 class="header ion-margin-y-12">${token}</h1>`;
    html += `<div class="message">${message}</div>`;
    const alert = await this.alertController.create({
      cssClass: 'my-alert',
      mode: 'ios',
      message: html,
      buttons: [
        {
          text: 'close',
          role: 'cancel',
          cssClass: 'warning',
          handler: () => {
          }
        },
        {
          text: 'Share Token',
          cssClass: 'success',
          handler: async () => {
            await this.shareDetails(token);
          }
        }
      ],
    });
    await alert.present();
  }


  async tokenModal(_message: string, _header = 'Successful', img = 'success') {
    let html = '';
    html += img ? `<img src="assets/alert/${img}.svg">` : '';
    html += _header ? `<h1 class="header ion-margin-y-12 ion-color-${img}">${_header}</h1>` : '<h1></h1>';
    html += `${_message}`;
    const alert = await this.alertController.create({
      cssClass: 'my-alert secondary',
      message: html,
      mode: 'ios',
      buttons: [
        {
          text: 'Okay',
          cssClass: 'medium',
          handler: () => {
          },
        },
        // {
        //   text: 'Share Token',
        //   cssClass: 'success',
        //   handler: () => {
        //     const message = _message.replace(/(<([^>]+)>)/ig, '');
        //     this.shareDetails(`${message}`);
        //   },
        // },
      ],
      backdropDismiss: false
    });
    await alert.present();
  }

  async shareDetails(token: string) {
    if (!this.isPwa) {
      await Share.share({
        title: token,
        text: `Ye!, here is a token ${token}`,
        url: ``,
        dialogTitle: 'Share with buddies',
      }).catch(() => {
        this.copyToken(token);
      });
    } else {
      this.copyToken(token);
    }
  }

  /**
   * copyToken
   */
  copyToken(token: string) {
    Clipboard.write({
      string: `Ye!, here is a token ${token}`,
    }).then(() => {
    });
    setTimeout(() => {
    }, 2000);
    this.apiService.successPop('Message Copied to Clipboard');
  }


}

