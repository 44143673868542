import { Component, Input, OnInit } from '@angular/core';
import { TopFive } from '../../../models/app.interface';

@Component({
  selector: 'app-sale-item',
  templateUrl: './sale-item.component.html',
  styleUrls: ['./sale-item.component.scss'],
})
export class SaleItemComponent implements OnInit {

  @Input() item: TopFive = {
    address: '25 freedom way lekki Phase 1',
    amount: 10000,
    date: '25th Jun, 2022',
  };

  defaultUrl = 'assets/img/sale.png';

  constructor() { }

  ngOnInit() { }

}
