import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppMock {

  constructor() { };

  async getMockData(name: string) {
    const response = await fetch('assets/mocks/' + name + '.json');
    return await response.json();
  }

}
