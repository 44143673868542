import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Angular4PaystackModule } from 'angular4-paystack';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SharedDirectivesModule } from 'src/app/directives/shared-directives.module';
import { CorporatePublicLayoutComponent } from './corporate-public-layout/corporate-public-layout.component';
import { MerchantPublicLayoutComponent } from './merchant-public-layout/merchant-public-layout.component';
import { UserPublicLayoutComponent } from './user-public-layout/user-public-layout.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    Angular4PaystackModule,
    NgChartsModule,
    IonicSelectableModule,
    SharedDirectivesModule,
    TranslateModule,
  ],
  declarations: [
    MerchantPublicLayoutComponent,
    UserPublicLayoutComponent,
    CorporatePublicLayoutComponent
  ],
  exports: [
    MerchantPublicLayoutComponent,
    UserPublicLayoutComponent,
    CorporatePublicLayoutComponent
  ]
})
export class SharedLayoutComponentsModule { }
