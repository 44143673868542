import { Component, Input, OnInit } from '@angular/core';
import { ApiErrorResponse } from '@app-models/api.response.interface';
import { BalanceInfo, UsersInfo, VirtualAccount } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { ComponentPickerService } from '@app-services/component-picker/component-picker.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-virtual-account',
  templateUrl: './virtual-account.component.html',
  styleUrls: ['./virtual-account.component.scss'],
})
export class VirtualAccountComponent implements OnInit {

  @Input() balance: BalanceInfo;
  @Input() account: VirtualAccount;
  @Input() isModal = false;

  currentUser: UsersInfo;
  isDesktop: boolean;

  constructor(// tslint:disable: align
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public componentPickerService: ComponentPickerService,
    public modalController: ModalController,
    public apiService: ApiService,
    public alertController: AlertController,
    public cryptoService: CryptoService) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'VirtualAccountComponent';
  }

  ngOnInit() {
    this.getProfile();
  }

  async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: UsersInfo) => {
      this.currentUser = res;
      console.log('currentUser: ', this.currentUser);
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

  /**
   * Dismiss Modal
   */
  dismissModal(moreData: any = {}) {
    this.modalController.dismiss({
      dismissed: true,
      moreData
    }).then(() => {
      // console.log(data);
    }).catch((err) => console.log(err));
  }

}
