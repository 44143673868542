/* eslint-disable one-var */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, HostListener } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiService } from '@app-services/api/api.service';
import { DataService } from '@app-services/data/data.service';
import { LanguageService } from '@app-services/language/language.service';
import { NetworkService } from '@app-services/network/network.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { TabsService } from '@app-services/tabs/tabs.service';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { StatusBar as StatusBars } from '@awesome-cordova-plugins/status-bar/ngx';
import { Device, DeviceInfo } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  islogin = false;
  isDesktop: boolean;
  enablePanicMode = false;
  enableAlwaysLoggedInMode = false;
  setIdleTimeOut = environment.production ? 10 * 60 : 10 * 60;
  infoData: DeviceInfo | any = {};
  isPwa: boolean;

  constructor(
    private platform: Platform,
    public apiService: ApiService,
    public modalController: ModalController,
    private navController: NavController,
    private networkService: NetworkService,
    private statusBar: StatusBars,
    private screenSizeService: ScreenSizeService,
    private oneSignal: OneSignal,
    private dataService: DataService,
    private idle: Idle,
    public tabsService: TabsService,
    public toastController: ToastController,
    public languageService: LanguageService
  ) {
    this.setIdleTimeOut = environment.production ? 10 * 60 : 10 * 60;
    this.initializeApp().then(async () => {
      this.isPwa = environment.isPwa;
      await this.apiService.getInfo().then((data) => {
        this.infoData = data;
        console.log(this.infoData);
        if (environment.isPwa) {
          // this.checkForUpdate();
        }
      });
      this.initIdle();
      await this.apiService.checkCurrentToken().then(async () => {
        const currentState = await this.apiService.isAuthenticated();
        console.log(currentState);
        this.apiService.setCurrentDomain();
        if (currentState) {
          this.islogin = true;
          console.log('AppComponent', this.apiService.currentDomain);
          this.apiService.getAuthenticatedToken(this.apiService.currentDomain).then((accessToken) => {
            this.apiService.accessToken = accessToken;
          });
          this.apiService.isAuthenticated().then(async isAuth => {
            if (isAuth) {
              if (this.apiService.currentDomain) {
                this.navController.navigateRoot(`/member/${this.apiService.currentDomain}/home`);
              }
            } else {
              this.apiService.errorAlert('Kindly Login Again');
            }
          }).catch(async (err) => console.log(err));
        }
        SplashScreen.hide();
        return false;
      }).then(() => {
        // // console.log(this.islogin);
      });
    });
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenSizeService.onResize(event.target.innerWidth, event.target.innerHeight);
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: any) {
    this.screenSizeService.onResize(event.target.innerWidth, event.target.innerHeight);
  }

  initIdle() {
    this.idle.setIdle(30);
    this.idle.setTimeout(this.setIdleTimeOut);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      // console.warn('Timeout Warning - ' + countdown);
    });

    this.idle.onTimeout.subscribe(() => {
      this.inactiveToggle();
      this.apiService.logout().then(() => {
        this.dataService.setData('action', 'logout');
        this.navController.navigateRoot('/public/user');
        this.idle.stop();
        this.idle.ngOnDestroy();
        this.removeAllModals();
      }).catch((err) => console.log(err));
    });

    this.idle.watch();
  }

  removeAllModals() {
    this.modalController.dismiss({ dismissed: true }).then().catch((err) => console.log(err));
    this.modalController.dismiss({ dismissed: true }).then().catch((err) => console.log(err));
    this.modalController.dismiss({ dismissed: true }).then().catch((err) => console.log(err));
    this.modalController.dismiss({ dismissed: true }).then().catch((err) => console.log(err));
    this.modalController.dismiss({ dismissed: true }).then().catch((err) => console.log(err));
  }

  checkForUpdate() {
    // this.apiService.getReleaseVersion().then((res) => {
    //   this.apiService.getVersion().then((data) => {
    //     if (data !== res?.data?.value) {
    //       this.apiService.currentVersion = res?.data?.value;
    //       this.apiService.updateToast(res?.data?.value);
    //     }
    //   });
    // }).catch(() => {
    //   this.apiService.updateToast('1.2.0');
    // });
  }

  async inactiveToggle() {
    const toast = await this.toastController.create({
      message: 'Your have been logged out cause of inactivity.',
      duration: 2000,
      color: 'light',
      cssClass: 'toast web-toast'
    });
    toast.present();
  }

  async initializeApp() {
    return await this.platform.ready().then(async () => {
      const info = await Device.getInfo();
      // console.log(info.platform);
      if (info.platform !== 'web') {
        if (info.platform === 'ios') {
          StatusBar.setStyle({ style: Style.Light });
        } else {
          this.statusBar.styleDefault();
          // this.statusBar.overlaysWebView(true);
          this.statusBar.backgroundColorByHexString('#ffffff');
        }
      }
      this.checkAlwaysLoggedInMode().then(() => {
        if (!this.enableAlwaysLoggedInMode) {
          SplashScreen.hide();
        }
      }).catch(() => {
        SplashScreen.hide();
      });
      this.networkService.initializeNetworkSubscription();
      this.screenSizeService.onResize(this.platform.width(), this.platform.height());
      if (info.platform !== 'web' && !this.isPwa) {
        this.getOneSignal();
      }
    });
  }

  checkAlwaysLoggedInMode() {
    return this.apiService.getAlwaysLoggedInMode().then((res) => {
      console.log(res);
      this.enableAlwaysLoggedInMode = res;
    }).catch((err) => console.log(err));
  }

  getOneSignal() {
    this.oneSignal.startInit(environment.onesignal.appId, environment.onesignal.googleProjectNumber);
    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    this.oneSignal.handleNotificationReceived().subscribe(() => {
      // do something when notification is received
    });
    this.oneSignal.handleNotificationOpened().subscribe(() => {
      // do something when a notification is opened
    });
    this.oneSignal.endInit();
  }

  talkTo() {

  }

}
