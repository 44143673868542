import { Component, Input, OnInit } from '@angular/core';
import { MerchantInfo } from '@app-models/app.interface';

@Component({
  selector: 'app-merchant-visited-card-item',
  templateUrl: './merchant-visited-card-item.component.html',
  styleUrls: ['./merchant-visited-card-item.component.scss'],
})
export class MerchantVisitedCardItemComponent implements OnInit {

  @Input() merchant: Partial<MerchantInfo> = {};

  constructor() { }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'MerchantVisitedCardItemComponent';
  }

  ngOnInit() { }

}
