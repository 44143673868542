import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoArrowDirective } from './no-arrow/no-arrow.directive';
import { DisableRoleDirective } from './disableRole/disable-role.directive';
import { HasPermissionDirective } from './hasPermission/has-permission.directive';
import { EnableRoleDirective } from './enableRole/enable-role.directive';
import { IsDevStageDirective } from './isDevStage/is-dev-stage-permission.directive';
import { LazyImgDirective } from './lazyImg/lazy-img.directive';



@NgModule({
  declarations: [
    NoArrowDirective,
    DisableRoleDirective,
    HasPermissionDirective,
    EnableRoleDirective,
    IsDevStageDirective,
    LazyImgDirective
  ],
  imports: [
    CommonModule
  ],
  exports:
    [
      NoArrowDirective,
      DisableRoleDirective,
      HasPermissionDirective,
      EnableRoleDirective,
      IsDevStageDirective,
      LazyImgDirective
    ]
})
export class SharedDirectivesModule { }
