import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CorporatePagination } from '@app-models/app.interface';

@Component({
  selector: 'app-corporate-pagination',
  templateUrl: './corporate-pagination.component.html',
  styleUrls: ['./corporate-pagination.component.scss'],
})
export class CorporatePaginationComponent implements OnInit, OnChanges {
  @Input() paginationParams: CorporatePagination;
  @Output() activePage = new EventEmitter();
  paginationItemList = [];

  constructor() { }

  ngOnInit() {
    this.paginationCounterHandler();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (!changes[propName].firstChange) {
        switch (propName) {
          case 'paginationParams':
            this.paginationParams = { ...changes[propName].currentValue };
            this.paginationCounterHandler();
            break;
          default:
            break;
        }
      }
    }
  }

  paginateHandler(page: number) {
    if (
      isNaN(page) ||
      page < 1 ||
      page > this.paginationParams.totalPages ||
      (page === 1 && this.paginationParams.currentPage === 1)
    ) {
      return;
    }
    this.activePage.emit(page);
  }

  private paginationCounterHandler() {
    this.paginationItemList = [];
    const start = this.paginationParams.totalPages <= 6 ? 1 : this.paginationParams.currentPage;
    let lastSixPaginationItem = [];

    if (this.paginationParams?.totalPages > 6) {
      lastSixPaginationItem = [
        this.paginationParams.totalPages - 5,
        this.paginationParams.totalPages - 4,
        this.paginationParams.totalPages - 3,
        this.paginationParams.totalPages - 2,
        this.paginationParams.totalPages - 1,
        this.paginationParams.totalPages,
      ];
    }

    if (
      lastSixPaginationItem.indexOf(start) > -1 &&
      lastSixPaginationItem.indexOf(start + 1) > -1 &&
      lastSixPaginationItem.indexOf(start + 2) > -1) {
      this.paginationItemList = [...lastSixPaginationItem];
    } else if (lastSixPaginationItem.indexOf(start) === -1 && this.paginationParams.totalPages > 6) {
      this.paginationItemList = [start, start + 1, start + 2];
      this.paginationItemList = [
        ...this.paginationItemList,
        '...',
        lastSixPaginationItem[3],
        lastSixPaginationItem[4],
        lastSixPaginationItem[5],
      ];
    } else if (this.paginationParams.totalPages < 6) {
      for (let i = 1; i <= this.paginationParams.totalPages; i++) {
        this.paginationItemList.push(i);
      }
    }
    else {
      this.paginationItemList = [
        1, 2, 3, '...', 4, 5, 6
      ];
    }
  }

}
