import { Component, OnInit } from '@angular/core';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-request-kreadeet',
  templateUrl: './request-kreadeet.component.html',
  styleUrls: ['./request-kreadeet.component.scss'],
})
export class RequestKreadeetComponent implements OnInit {
  amount: number;

  constructor(
    private modalController: ModalController,
    public apiService: ApiService,
    private helperMethods: HelperMethodsService
  ) { }

  ngOnInit() { }

  dismissModal() {
    this.modalController.dismiss();
  }

  async requestKreadeetHandler() {
    const loading = await this.apiService.getLoader();
    await loading.present();

    await this.helperMethods.promiseTimeout(this.apiService.corporateRequestKreadeet({ amount: this.amount }))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.apiService.corporateAlert(
            res.message,
            'Kreadeet Request Submitted',
            null,
            'corporate-alert-success.png',
            'mr-green-success.png',
            null,
            'success',
          );
          this.dismissModal();
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }

        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });
  }

}
