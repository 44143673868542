import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@app-environments/environment';
import { ApiService } from '@app-services/api/api.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { DataService } from '@app-services/data/data.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent implements OnInit {

  isDesktop: boolean;
  isPwa: any;

  constructor(
    private router: Router,
    public navController: NavController,
    private dataService: DataService,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService,
    private cryptoService: CryptoService,
    public alertController: AlertController) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'PaymentStatusComponent';
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.navController.back();
  }

  ngOnInit() {
  }


}
