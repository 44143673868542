import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@app-environments/environment';
import { AppMock } from '@app-mocks/app-mock';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-verify-pin',
  templateUrl: './verify-pin.component.html',
  styleUrls: ['./verify-pin.component.scss'],
})
export class VerifyPinComponent implements OnInit {

  isPwa = false;
  isDesktop = false;
  isPhoneView = true;
  currentUrl: string;
  enableBiometric = true;
  currentValue = '';
  pin = '';
  pinLength = 4;
  readonly blankValue: ReadonlyArray<any> = Array.from({ length: this.pinLength }, () => null);
  pinValue: any[] = [...this.blankValue];
  isDisable = false;
  currentState = 'pin';
  pinMatch = false;
  wasFilled = false;
  isNumDisable = false;
  currentUser: any = {};


  constructor(
    private router: Router,
    public navController: NavController,
    public screenSizeService: ScreenSizeService,
    public apiService: ApiService,
    public helperMethods: HelperMethodsService,
    public modalController: ModalController,
    public appMock: AppMock,
    public alertController: AlertController) {
    this.isPwa = environment.isPwa;
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'VerifyPinComponent';
  }

  ionViewDidEnter() {
    this.currentUrl = this.getCurrentUrl();
    if (this.isPwa) {
      // this.goTo('merchant');
    }
    this.appMock.getMockData('profile').then(data => {
      console.log(data);
      this.currentUser = data.data.profile;
      console.log(this.currentUser);
    });
  }

  ngOnInit() {
    if (this.screenSizeService.widthSize.value >= 568) {
      this.isPhoneView = false;
    }
  }

  getCurrentUrl(): string {
    if (this.router.url) {
      return this.apiService.getPublicActiveTab(this.router.url);
    }
    return '';
  }

  /**
   * Dismiss Modal
   */
  dismissModal(moreData: any = {
    pin: this.pin
  }) {
    this.modalController.dismiss({
      dismissed: true,
      moreData
    }).then(() => {
      // console.log(data);
    }).catch((err) => console.log(err));
  }

  getCurrentValue(event: any) {
    const eventArray = Array.from(event);
    console.log('eventArray: ', eventArray);
    this.wasFilled = false;
    if (this.currentState === 'pin') {
      this.pinValue = [...this.blankValue];
      eventArray.forEach((element, index) => {
        this.pinValue[index] = element;
        this.wasFilled = false;
      });
      if (eventArray.length === this.pinLength) {
        console.log(eventArray.length);
        this.pin = this.pinValue.join('');
        this.isNumDisable = true;
        return;
      } else {
        this.isNumDisable = false;
      }
    }
    console.log(this.pinValue);
  }

}
