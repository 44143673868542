import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-action-confirmation-modal',
  templateUrl: './action-confirmation-modal.component.html',
  styleUrls: ['./action-confirmation-modal.component.scss'],
})
export class ActionConfirmationModalComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() mainBtnTitle: string;
  @Input() btnType: 'danger' | 'warning' | 'freeze';
  @Input() cancelBtnTitle: string;

  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  dismissModal(data?: boolean) {
    this.modalController.dismiss(data);
  }

  handleAction(e: boolean) {
    this.dismissModal(e);
  }

}
