import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-segment-item',
  templateUrl: './segment-item.component.html',
  styleUrls: ['./segment-item.component.scss'],
})
export class SegmentItemComponent implements OnInit {

  @Input() cssClass = '';

  constructor() { }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'SegmentItemComponent';
  }

  ngOnInit() { }

}
