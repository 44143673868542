import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-corporate-modal-header',
  templateUrl: './corporate-modal-header.component.html',
  styleUrls: ['./corporate-modal-header.component.scss'],
})
export class CorporateModalHeaderComponent implements OnInit {
  @Input() title: string;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);

  constructor() { }

  ngOnInit() { }

  dismissHandler() {
    this.closeModal.emit(true);
  }

}
