import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppMock } from '@app-mocks/app-mock';
import { ApiErrorResponse } from '@app-models/api.response.interface';
import { MerchantUsersInfo } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-merchant-web-header',
  templateUrl: './merchant-web-header.component.html',
  styleUrls: ['./merchant-web-header.component.scss'],
})
export class MerchantWebHeaderComponent implements OnInit {

  @Input() currentUser: MerchantUsersInfo = {};
  @Input() currentPage = '';
  @Output() toggleSidebar = new EventEmitter<boolean>(false);
  navBars = [
    {
      title: 'Data Capture',
      url: '/data-capture',
      icon: 'data-capture',
      isSelected: true,
    },
    {
      title: 'Reports',
      url: '/reports',
      icon: 'reports',
      isSelected: false,
    },
    {
      title: 'On-boarding',
      url: '/on-boarding',
      icon: 'on-boarding',
      isSelected: false,
    },
    {
      title: 'Admin Settings',
      url: '/admin-settings',
      icon: 'admin-settings',
      isSelected: false,
    }
  ];
  fold = false;
  isDesktop: boolean;

  constructor(
    private router: Router,
    public navController: NavController,
    private helperMethods: HelperMethodsService,
    public appMock: AppMock,
    public apiService: ApiService,
    public screenSizeService: ScreenSizeService) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit() {
    this.screenSizeService.widthSize.subscribe(widthSize => {
      if (widthSize < 1320) {
        this.fold = true;
        this.emitToggleSidebar();
      }
    });
    this.getProfile();
  }

  async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: MerchantUsersInfo) => {
      this.currentUser = res;
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

  toggle() {
    this.fold = !this.fold;
    this.emitToggleSidebar();
  }

  goTo(page: string) {
    const currentUrl = this.router.url;
    console.log('currentUrl: ', currentUrl);
    this.navController.navigateForward(`/member/merchant/home/${page}`, { animated: false });
  }


  private emitToggleSidebar() {
    this.toggleSidebar.emit(this.fold);
  }

}
