import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { DataResolverService } from '@app-services/resolver/data-resolver.service';

const routes: Routes = [
  { path: '', redirectTo: 'public', pathMatch: 'full' },
  {
    path: 'member',
    // canActivate: [AuthGuard],
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/member/member-routing.module').then(m => m.MemberRoutingModule)
  },
  {
    path: 'public',
    resolve: {
      special: DataResolverService
    },
    loadChildren: () => import('./pages/public/public-routing.module').then(m => m.PublicRoutingModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
