import { Component, OnInit } from '@angular/core';
import { ApiErrorResponse } from '@app-models/api.response.interface';
import { CorporateVirtualAccount } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  accountDetails: CorporateVirtualAccount;
  accountDetailsLoader = false;

  constructor(private modalController: ModalController,
    private helperMethods: HelperMethodsService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.getAccountDetails();
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  private async getAccountDetails() {
    this.accountDetailsLoader = true;
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetVirtualAccount())
      .then(async (res: any) => {
        this.accountDetailsLoader = false;
        if (res.status) {
          this.accountDetails = res.data;
          return;
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.accountDetailsLoader = false;
      });
  }

}
