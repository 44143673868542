import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VirtualAccountComponent } from '@app-components/user/home/virtual-account/virtual-account.component';
import { ClearKreadeetComponent } from '@app-components/user/kreadeet/clear-kreadeet/clear-kreadeet.component';
import { FundKreadeetComponent } from '@app-components/user/kreadeet/fund-kreadeet/fund-kreadeet.component';
import { environment } from '@app-environments/environment';
import { ApiErrorResponse, AuthKreadeetResponse } from '@app-models/api.response.interface';
import { BalanceInfo, balances, UsersInfo } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { ComponentPickerService } from '@app-services/component-picker/component-picker.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-balance-card-item',
  templateUrl: './balance-card-item.component.html',
  styleUrls: ['./balance-card-item.component.scss'],
})
export class BalanceCardItemComponent implements OnInit {

  @Input() isLoading = false;
  @Input() showTop = true;
  @Input() showBalanceTitle = true;
  @Input() showBalance = true;
  @Input() cssClass = '';
  @Input() onButtonClick?: (balance: any) => Promise<any>;
  @Input() balance: any = {
    plan: 'Premium Plan',
    name: 'Kreadeet Balance',
    balance: 1000.00,
    account: null
  };
  @Output() reloadBalance = new EventEmitter();
  balances: balances = this.apiService.userBalance;
  currentUser: UsersInfo = {};

  isPwa = false;
  isDesktop: boolean;
  enableShowBalance = true;

  constructor(
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public componentPickerService: ComponentPickerService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService,
    public alertController: AlertController,
    private ref: ChangeDetectorRef,
    public cryptoService: CryptoService,) {
    this.isPwa = environment.isPwa;
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'BalanceCardItemComponent';
  }

  async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: UsersInfo) => {
      this.currentUser = res;
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }


  async ngOnInit() {
    console.log(this.balance);
    this.apiService.getShowBalance().then((data) => {
      this.enableShowBalance = data;
      this.apiService.enableShowBalance.subscribe((val) => {
        this.enableShowBalance = val;
      });
    }).catch((err) => console.log(err));
    this.checkShowBalance();
    await this.getProfile();
    await this.getKreadeetInformation();
  }

  async openVirtualAccount(balance: BalanceInfo) {
    const modal = await this.apiService.openComponent(VirtualAccountComponent, {
      balance, account: this.currentUser?.virtualAccount
    }, 64);
    await modal.onDidDismiss().then(async (data) => {
      console.log(data);
      if (data) {
        await this.getProfile();
        await this.getKreadeetInformation();
      }
    });
  }
  async openFundKreadeet(balance: BalanceInfo) {
    const modal = await this.apiService.openComponent(FundKreadeetComponent, {
      balance, account: this.currentUser?.virtualAccount
    }, 75);
    await modal.onDidDismiss().then(async (data) => {
      console.log(data);
      if (data) {
        await this.getProfile();
        await this.getKreadeetInformation();
      }
    });
  }

  async openClearKreadeet(balance: BalanceInfo) {
    const modal = await this.apiService.openComponent(ClearKreadeetComponent, {
      balance, account: this.currentUser?.virtualAccount
    }, 68);
    await modal.onDidDismiss().then(async (data) => {
      console.log(data);
      if (data) {
        await this.getProfile();
        await this.getKreadeetInformation();
      }
    });
  }

  /**
   * Check Show Balance
   */
  checkShowBalance() {
    this.apiService.getShowBalance().then((res) => {
      this.enableShowBalance = res;
    }).catch((err) => console.log(err));
  }

  /**
   * Set Show Balance
   */
  setShowBalance() {
    this.enableShowBalance = !this.enableShowBalance;
    this.apiService.setShowBalance(this.enableShowBalance).then(() => {
    }).catch((err) => console.log(err));
  }

  async getKreadeetInformation() {
    await this.helperMethods.promiseTimeout(this.apiService.getKreadeetInformation())
      .then(async (res: AuthKreadeetResponse) => {
        if (res.status) {
          this.balances.kreadeetDetails.account = this.helperMethods.formatAmount(res?.data?.mainKreadeetSpent?.toString());
          this.balances.kreadeetDetails.balance = res?.data?.mainKreadeetBalance;
          //
          this.balances.kreadeetPlusDetails.balance = res?.data?.subKreadeetBalance;
          this.balances.kreadeetPlusDetails.account = this.currentUser?.virtualAccount?.accountNumber;

          this.ref.detectChanges();
          this.apiService.setCurrentBalances(this.balances);
          this.reloadBalance.emit(this.balances);
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }


}
