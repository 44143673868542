import { Component, OnInit } from '@angular/core';
import { environment } from '@app-environments/environment';
import { PinChangePayload } from '@app-models/api.payload.interface.d';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.scss'],
})
export class ChangePinComponent implements OnInit {

  isPwa: any;
  data: PinChangePayload = {
    oldPin: '',
    newPin: '',
    confirmPin: ''
  };

  constructor(
    public navController: NavController,
    public apiService: ApiService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public toastController: ToastController,
    public loadingController: LoadingController) {
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'ChangePinComponent';
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  ngOnInit() {
  }

  goBack(page: string = null) {
    if (page) {
      this.navController.navigateBack(page);
    } else {
      this.navController.back();
    }
  }

  async changeUserPin() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.changeUserPin(this.data))
      .then(async (res: ApiGenericResponseInfo) => {
        await loading.dismiss();
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message, res.header || 'PIN successfully updated');
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

}
