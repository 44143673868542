import { Directive, ElementRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img'
})

export class LazyImgDirective {
  constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;
    const initialImg = nativeElement.src;
    let responseOk = false;
    if (supports) {
      nativeElement.setAttribute('loading', 'lazy');
    } else {
      // fallback to IntersectionObserver
    }

    fetch(initialImg).then((response) => {
      responseOk = response.ok;
    }).catch((err) => {
      responseOk = false;
      console.log(err);
    });

    if (responseOk) {
      // nativeElement.setAttribute('src', 'assets/icon/no-image.svg');
    }

  }
}
