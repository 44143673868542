import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ComponentPickerService } from '@app-services/component-picker/component-picker.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { v4 as NEW_UUID } from 'uuid';
import { SelectableOption, SelectableOptionItem } from './interface';

@Component({
  selector: 'app-selectable',
  templateUrl: './app-selectable.component.html',
  styleUrls: ['./app-selectable.component.scss'],
})
export class AppSelectableComponent implements OnInit {

  @Input() selectableOption: SelectableOption = {
    title: 'Select Option',
    enableSearch: true,
    items: [],
    itemTextField: 'name',
    itemIconField: 'icon',
    itemNoteField: 'note'
  };
  @Input() search = '';
  @Output() searchChange: EventEmitter<any> = new EventEmitter();

  selectableOptionItem: ReadonlyArray<any>;
  selected: SelectableOptionItem = {};

  constructor(// tslint:disable: align
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public componentPickerService: ComponentPickerService,
    public modalController: ModalController,
    public alertController: AlertController,
    public cryptoService: CryptoService) {
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'AppSelectableComponent';
  }

  ngOnInit() {
    this.selectableOptionItem = [...
      this.selectableOption.items.map((x) => ({ ...x, _selectableOptionItemUUID: NEW_UUID() }))
    ];
  }

  /**
   * Dismiss Modal
   */
  dismissModal(moreData: any = this.selected) {
    this.modalController.dismiss({
      dismissed: true,
      moreData
    }).then(() => {
      // console.log(data);
    }).catch((err) => console.log(err));
  }

  selectItem(item: any) {
    this.selected = item;
    this.dismissModal();
  }

  getSearchData(event) {
    console.log(event.detail.value);
    this.search = event.detail.value;
    this.searchChange.emit(event.detail.value);
  }
}
