import { Component, OnInit } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { NavController } from '@ionic/angular';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, SwiperOptions, Zoom } from 'swiper';
SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-public-left-side',
  templateUrl: './public-left-side.component.html',
  styleUrls: ['./public-left-side.component.scss'],
})
export class PublicLeftSideComponent implements OnInit {

  public isPwa: Readonly<boolean>;
  public isDesktop: boolean;
  public sliderConfig: SwiperOptions = {
    initialSlide: 0,
    autoplay: false,
    pagination: true,
  };

  constructor(
    public screenSizeService: ScreenSizeService,
    public navController: NavController,
  ) {
    this.isPwa = environment.isPwa;
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
  }

  public getClassName() {
    return 'PublicLeftSideComponent';
  }

  public ngOnInit() {
  }


}
