/* eslint-disable guard-for-in */
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { ApiErrorResponse, ApiGenericResponseInfo, BanksResponse } from '../../../models/api.response.interface';
import { Branch, FilterParam } from '../../../models/app.interface';
import { ApiService } from '../../../services/api/api.service';
import { HelperMethodsService } from '../../../services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '../../../services/screen-size/screen-size.service';

const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true
};

declare let google: any;


@Component({
  selector: 'app-add-outlet',
  templateUrl: './add-outlet.component.html',
  styleUrls: ['./add-outlet.component.scss'],
})
export class AddOutletComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @Input() mode = 'create';
  @Input() viewBranch: any = {};
  @Input() branch: Branch = {};
  public geocoder: any;
  isDesktop: boolean;
  isPwa: any;
  seePassword = false;
  isPhoneView = true;

  totalBranch = 1;
  branches: Array<Branch> = [];
  branchLoading = false;
  page = 1;
  selectedBranch: Branch = {};
  results: any = [];

  formData = new FormData();
  imageUploaded = false;
  currentImage: any = null;
  bankList: any = [];

  searchParam: FilterParam = {
    page: 1,
    limit: 10
  };

  constructor(
    private router: Router,
    public navController: NavController,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public loadingController: LoadingController,
    private ref: ChangeDetectorRef,
    public apiService: ApiService,
    private platform: Platform,
    public actionSheetController: ActionSheetController,
    public alertController: AlertController) {
    this.isPwa = environment.isPwa;
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.navController.back();
  }

  async ngOnInit() {
    if (google !== undefined) {
      this.geocoder = new google.maps.Geocoder();
    }
    if (this.screenSizeService.widthSize.value >= 568) {
      this.isPhoneView = false;
    }
    await this.getBanks();
  }

  async getBanks() {
    await this.helperMethods.promiseTimeout(this.apiService.getBanks())
      .then(async (res: BanksResponse) => {
        console.log(res);
        this.bankList = [...res.data];
        this.branch = this.viewBranch;
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
      });
  }

  showPassword() {
    this.seePassword = !this.seePassword;
  }

  async createBranch() {
    const loading = await this.loadingController.create({
      spinner: null,
      message: '<img id="roller" src="assets/icon/loader.svg">',
      translucent: true,
      cssClass: 'custom-loading'
    });
    await loading.present();
    console.log(this.branch);
    for (const i in this.branch) {
      this.formData.set(i, this.branch[i]);
    }
    await this.helperMethods.promiseTimeout(this.apiService.merchantCreateBranches(this.formData))
      .then(async (res: ApiGenericResponseInfo) => {
        console.log(res);
        if (res.status) {
          this.dismiss();
          this.apiService.successAlert(
            res.message === 'Success' ? `Outlet created successfully <br> <b>${this.branch.brandName}</b>`
              : res.message, res.header || 'Successful');
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse) => {
        console.log(err);
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  async editBranch() {
    const loading = await this.loadingController.create({
      spinner: null,
      message: '<img id="roller" src="assets/icon/loader.svg">',
      translucent: true,
      cssClass: 'custom-loading'
    });
    await loading.present();
    console.log(this.branch);
    for (const i in this.branch) {
      this.formData.set(i, this.branch[i]);
    }
    if (!this.formData.has('logo')) {
      this.formData.set('logo', this.branch.image_url);
    };

    await this.helperMethods.promiseTimeout(this.apiService.merchantUpdateBranch(this.branch.id, this.formData))
      .then(async (res: ApiGenericResponseInfo) => {
        console.log(res);
        if (res.status) {
          this.dismiss();
          this.apiService.successAlert(res.message, res.header || 'Successful');
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse) => {
        console.log(err);
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  continue() {
    if (this.mode === 'create') {
      this.createBranch();
    }
    if (this.mode === 'edit') {
      this.editBranch();
    }
  }

  async deleteContinue() {
    const alert = await this.alertController.create({
      header: 'Delete Alert',
      // subHeader: 'Subtitle',
      cssClass: 'my-alert danger',
      message: `You are about to delete the branch - ${this.branch.name}, Are you sure you want to delete your?`,
      mode: 'ios',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          cssClass: 'danger',
          handler: () => {
            this.delete();
          }
        }
      ],
      backdropDismiss: false
    });
    await alert.present();
  }

  async delete() {
    const loading = await this.loadingController.create({
      spinner: null,
      message: '<img id="roller" src="assets/icon/loader.svg">',
      translucent: true,
      cssClass: 'custom-loading'
    });
    await loading.present();
    console.log(this.branch);
    // tslint:disable-next-line:forin
    await this.helperMethods.promiseTimeout(this.apiService.merchantDeleteBranch(this.branch))
      .then(async (res: ApiGenericResponseInfo) => {
        console.log(res);
        if (res.status) {
          this.dismiss();
          this.apiService.successAlert(res.message, res.header || 'Successful');
          this.navController.back();
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse) => {
        console.log(err);
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  loadData(event) {
    console.log('Done');
    this.helperMethods.promiseTimeout(this.apiService.merchantGetBranches(this.searchParam))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.branches = [...this.branches, ...res.data.branches];
          this.totalBranch = res.data.pagination.total;
          if (res.data.branches.length > 0) {
            this.page += 1;
          }
        }
        this.branchLoading = false;
        event.target.complete();
      })
      .catch(async (err: ApiErrorResponse) => {
        event.target.complete();
        this.branchLoading = false;
        console.log(err);
      });
  }

  selectDestination(destination: any) {
    this.branch.address = destination.description;
    this.results = [];
    this.ref.detectChanges();

    this.geocoder.geocode({ placeId: destination.place_id }, (destinations, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        // trigger change detection cycle
        console.log(destinations[0].geometry.location.lat);
        this.branch.address = destinations[0].formatted_address;
        this.branch.lat = destinations[0].geometry.location.lat();
        // this.branch.lng = destinations[0].geometry.location.lng();
        this.branch.long = destinations[0].geometry.location.lng();
        console.log(this.branch);
        this.ref.detectChanges();
      } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
        this.ref.detectChanges(); // trigger change detection cycle
      }
    });
  }

  onSubmit(e: any = null) {
    // console.log(e.target.value);
    this.results = [];
    const displaySuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        // alert(status);
        return;
      }
      this.results = predictions ? predictions : predictions.slice(0, 10); // show top 4 results
      this.ref.detectChanges();
    };
    const service = new google.maps.places.AutocompleteService();
    // service.setComponentRestrictions({ country: 'ng' });
    service.getQueryPredictions({
      input: e.target.value
      // + ' Lagos, Nigeria'
      ,
      componentRestrictions: {
        country: 'NG',
        // types: ['Courier service']
      }
    }, displaySuggestions);
  }

  emptyResult() {
    this.results = [];
  }


  ///

  async choosePicture(source: CameraSource) {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source
    });
    console.log('logo: ', image);
    this.branch.logo = image.base64String;
    const blobData = this.helperMethods.b64toBlob(image.base64String, `image/${image.format}`);
    const date = new Date(); const time = date.getTime(); const fileName = `${time}`;
    const file: File = this.helperMethods.blobToFile(blobData, `${fileName}.${image.format}`, `image/${image.format}`);
    if (file.size <= 10351930) {
      this.currentImage = {
        base64: `data:image/${image.format};base64,` + image.base64String,
        blob: blobData,
        fileName,
        format: image.format,
        file
      };
      // this.uploadImage(blobData, fileName, image.format);
      this.uploadImageFile(file);
    } else {
      this.apiService.errorAlert('Your Image should not be more that 10M');
    }
  }

  async uploadFile(event: EventTarget | any) {
    const eventObj: any = event as any;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const file: File = target.files[0];
    let base64 = '';
    await this.helperMethods.resolveBase64(file).then((data) => {
      // console.log(data);
      base64 = data;
    });
    const blobData = this.helperMethods.b64toBlob(base64.split('base64,')[1], `${file.type}`);
    if (file.size <= 10351930) {
      this.currentImage = {
        base64,
        blob: blobData,
        fileName: file.name,
        format: file.type,
        file
      };
      console.log('file: ', file);
      this.uploadImageFile(file);
    } else {
      this.apiService.errorAlert('Your Image should not be more that 10M');
    }
  }

  async uploadImage(blob: Blob, name: string, ext: string) {
    this.formData.set('logo', blob, `file-${name}.${ext}`);
    this.imageUploaded = true;
    this.updateProfileImage(this.formData);
  }

  async uploadImageFile(file: File) {
    const date = new Date();
    const compressFile = await this.helperMethods.compressImage(file);
    this.formData.set('logo', compressFile, `${compressFile.name}`);
    this.imageUploaded = true;
    this.updateProfileImage(this.formData);
  }

  async updateProfileImage(param: FormData) {
    const loading = await this.loadingController.create({
      spinner: null,
      message: '<img id="roller" src="assets/img/loader.svg">',
      translucent: true,
      cssClass: 'custom-loading'
    });
    await loading.present();
    // this.goToFaceId();
    await loading.dismiss();
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Choose Image Source',
      mode: 'md',
      buttons: [{
        text: 'Take Photo',
        icon: 'assets/icon/camera_alt-24px.svg',
        handler: () => {
          this.choosePicture(CameraSource.Camera);
          actionSheet.dismiss();
        }
      },
      {
        text: 'Choose Photo',
        icon: 'assets/icon/add_a_photo-24px.svg',
        handler: () => {
          if (!this.platform.is('hybrid')) {
            this.fileInput.nativeElement.click();
            actionSheet.dismiss();
          } else {
            this.choosePicture(CameraSource.Photos);
            actionSheet.dismiss();
          }
        }
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }],
      backdropDismiss: false
    });
    await actionSheet.present();
  }


  async verifyBankAccount() {
    if (this.branch.accountNumber.length >= 10 && this.branch.bankId) {
      const loading = await this.loadingController.create({
        spinner: null,
        message: '<img id="roller" src="assets/icon/loader.svg">',
        translucent: true,
        cssClass: 'custom-loading'
      });
      await loading.present();
      const param = {
        accountNumber: this.branch.accountNumber,
        bankCode: this.branch.bankCode,
        bankId: this.branch.bankId
      };
      return await this.helperMethods.promiseTimeout(this.apiService.resolveBankName(param))
        .then(async (res) => {
          console.log(res);
          if (res.status) {
            this.branch.accountName = res.data;
          }
          await loading.dismiss();
        })
        .catch(async (err) => {
          console.log(err);
          this.apiService.errorAlert(err.message, err?.header);
          await loading.dismiss();
          if (err?.shouldLogout) {
            await this.apiService.logout();
          }
        });
    } else {
      this.branch.accountName = '';
    }
  }

}


