import { Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiErrorResponse } from '@app-models/api.response.interface';
import { CorporatePagination, CorporateProfile, CorporateTransaction, FilterParam } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ModalController } from '@ionic/angular';
import { CorporateTransactionDetailsComponent } from '../corporate-transaction-details/corporate-transaction-details.component';

@Component({
  selector: 'app-corporate-transactions',
  templateUrl: './corporate-transactions.component.html',
  styleUrls: ['./corporate-transactions.component.scss'],
})
export class CorporateTransactionsComponent implements OnInit {
  @Input() title: string;
  @Input() showFilter = true;
  @Input() showExportBtn = false;
  @Input() showViewAllLink = false;
  @Input() showPagination = true;
  @Input() staffId: number;
  @Input() corporateBranchId: string;
  currentUser: CorporateProfile;
  transactionParams: FilterParam = { page: 1, limit: 10 };
  corporateTransactions: ReadonlyArray<CorporateTransaction> = [];
  transactionsLoader = false;
  pagination: CorporatePagination;
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  constructor(
    private modalController: ModalController,
    private apiService: ApiService,
    private helperMethods: HelperMethodsService) { }

  async ngOnInit() {
    console.log(this.showPagination);
    await this.getProfile();
    this.route.queryParams.subscribe(async (params) => {
      const paramsKey = Object.keys(params);
      if (paramsKey.length) {
        await this.handlePageUrlQueryParams(params);
      }
      if (this.staffId) {
        this.getStaffTransactions();
        return;
      }
      this.getTransactions();
    });
  }

  paginationEventHandler(page: number) {
    this.transactionParams.page = page;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  viewAllTransactions() {
    this.router.navigateByUrl(`/member/corporate/dashboard/kreadeet`);
  }

  async openTransactionDetails(transactionItem: CorporateTransaction) {
    const modal = await this.modalController.create({
      component: CorporateTransactionDetailsComponent,
      componentProps: { transactionItem },
      backdropDismiss: false,
      cssClass: 'custom-corporate-modal'
    });

    await modal.present();
    return modal;

  }

  private async getTransactions() {
    this.transactionsLoader = true;
    if (this.corporateBranchId || this.currentUser.isPrimary === 'NO') {
      this.transactionParams.branchId = this.corporateBranchId ?? this.currentUser.corporateBranchId;
    }
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetKreadeetTransactions(this.transactionParams))
      .then(async (response: any) => {
        this.transactionsLoader = false;
        this.corporateTransactions = response.data.transactions;
        this.pagination = response?.data.pagination;
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.transactionsLoader = false;
      });
  }

  private async getStaffTransactions() {
    this.transactionsLoader = true;
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetStaffTransactions(this.staffId))
      .then(async (response: any) => {
        this.transactionsLoader = false;
        this.corporateTransactions = response.data.transactions;
        this.pagination = response?.data.pagination;
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.transactionsLoader = false;
      });
  }

  private async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: CorporateProfile) => {
      this.currentUser = res.profile;
      console.log(this.currentUser);
    }).catch((err: ApiErrorResponse) => {
    });
  }

  private handlePageUrlQueryParams(urlParams: any) {
    // eslint-disable-next-line guard-for-in
    for (const obj in urlParams) {
      switch (obj) {
        case 'page':
        case 'staffPage':
          this.transactionParams = {
            ...this.transactionParams,
            page: urlParams.page
          };
          break;
        default:
          break;
      }
    }
  }

}
