import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export class DataResolverService implements Resolve<any> {

  constructor(private dataService: DataService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id');
    const paymentId = route.paramMap.get('paymentId');
    const searchId = route.paramMap.get('searchId');
    const merchantId = route.paramMap.get('merchantId');
    const formData = route.paramMap.get('formData');
    const slog = route.paramMap.get('slog');
    return {
      id: this.dataService.getData(id) || null,
      paymentId: this.dataService.getData(paymentId) || null,
      searchId: this.dataService.getData(searchId) || null,
      merchantId: this.dataService.getData(merchantId) || null,
      formData: this.dataService.getData(formData) || null,
      slog: this.dataService.getData(slog) || null,
    };
  }
}
