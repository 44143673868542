import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiErrorResponse, CorporateStaffResponse } from '@app-models/api.response.interface';
import { CorporatePagination, CorporateProfile, CorporateStaff, FilterParam } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { NavController, ModalController } from '@ionic/angular';
import { ActionConfirmationModalComponent } from '../action-confirmation-modal/action-confirmation-modal.component';
import { AddStaffProviderComponent } from '../add-staff-provider/add-staff-provider.component';
import { CorporateStaffFormComponent } from '../corporate-staff-form/corporate-staff-form.component';

@Component({
  selector: 'app-corporate-staff-list',
  templateUrl: './corporate-staff-list.component.html',
  styleUrls: ['./corporate-staff-list.component.scss'],
})
export class CorporateStaffListComponent implements OnInit {
  @Input() corporateBranchId: string;
  @Input() title: string;
  @Input() showPagination = true;
  currentUser: CorporateProfile;
  staffList: ReadonlyArray<CorporateStaff> = [];
  staffListLoader = true;
  staffParams: FilterParam = { page: 1, limit: 10 };
  pagination: CorporatePagination;

  constructor(
    protected readonly route: ActivatedRoute,
    private router: Router,
    private navController: NavController,
    private modalController: ModalController,
    private apiService: ApiService,
    private helperMethods: HelperMethodsService) { }

  async ngOnInit() {
    await this.getProfile();
    this.route.queryParams.subscribe(async (params) => {
      const paramsKey = Object.keys(params);
      if (paramsKey.length) {
        // this.page = params.page;
        await this.handlePageUrlQueryParams(params);
        // await this.generateRequestParamsFromFilter();
      }
      this.getStaffs();
    });
  }

  paginationEventHandler(page: number) {
    this.staffParams.page = page;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  async openStaffFormModal() {
    const corporateBranchId = this.corporateBranchId;
    console.log(corporateBranchId);
    // if (!corporateBranchId) {
    //   corporateBranchId = String(this.currentUser.corporateBranchId);
    // }
    const modal = await this.modalController.create({
      component: CorporateStaffFormComponent,
      componentProps: { corporateBranchId },
      backdropDismiss: false,
      cssClass: 'custom-corporate-modal'
    });

    modal.onDidDismiss().then(response => {
      if (response.data) {
        this.getStaffs();
      }
    });

    await modal.present();
    return modal;

  }

  async openProviderAssignment(staff: CorporateStaff) {
    const modal = await this.modalController.create({
      component: AddStaffProviderComponent,
      componentProps: { staff },
      backdropDismiss: false,
      cssClass: 'custom-corporate-modal'
    });

    modal.onDidDismiss().then(response => {
      if (response.data) {
        this.getStaffs();
      }
    });

    await modal.present();
    return modal;
  }

  goToStaffDetailsPage(id) {
    this.navController.navigateRoot(`/member/corporate/dashboard/staff/${id}`);
  }

  async confirmStaffDeleteHandler(i) {
    const modal = await this.modalController.create({
      component: ActionConfirmationModalComponent,
      componentProps: {
        title: 'Delete Staff',
        message: 'Are you sure you want to delete this staff? Deleting this staff will remove his profile from your system',
        mainBtnTitle: 'Yes, Delete Staff',
        btnType: 'danger',
        cancelBtnTitle: 'Not Now'
      },
      backdropDismiss: false,
      cssClass: 'action-confirmation-modal'
    });

    modal.onDidDismiss().then(response => {
      if (response.data) {
        this.staffDeleteHandler();
      }
    });

    await modal.present();
    return modal;

  }

  async confirmStaffSuspendHandler(i) {
    const modal = await this.modalController.create({
      component: ActionConfirmationModalComponent,
      componentProps: {
        title: 'Suspend Staff',
        message: 'Are you sure you want to suspend this staff? The staff will not have access to Kreadeet afterwards',
        mainBtnTitle: 'Yes, Suspend this Staff',
        btnType: 'warning',
        cancelBtnTitle: 'Not Now'
      },
      backdropDismiss: false,
      cssClass: 'action-confirmation-modal'
    });

    modal.onDidDismiss().then(response => {
      if (response.data) {
        this.staffSuspendHandler();
      }
    });

    await modal.present();
    return modal;

  }

  private async getStaffs() {
    this.staffListLoader = true;

    if (this.corporateBranchId || this.currentUser.isPrimary === 'NO') {
      this.staffParams.branchId = this.corporateBranchId ?? this.currentUser.corporateBranchId;
    }

    await this.helperMethods.promiseTimeout(this.apiService.corporateGetStaffs(this.staffParams))
      .then(async (res: CorporateStaffResponse) => {
        this.staffListLoader = false;
        if (res.status) {
          this.staffList = [...res?.data.staffs];
          this.pagination = res?.data.pagination;
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.staffListLoader = false;
      });
  }

  private staffDeleteHandler() {
    this.apiService.corporateAlert(
      `Staff successfully Deleted`,
      'Staff Deleted',
      null,
      'corporate-alert-error.png',
      'delete-alert.svg',
      null,
      'error',
    );
  }

  private staffSuspendHandler() {
    this.apiService.corporateAlert(
      `You have successfully suspended this staff`,
      'Staff Suspended',
      null,
      'corporate-alert-success.png',
      null,
      null,
      'warning',
    );
  }

  private async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: CorporateProfile) => {
      this.currentUser = res.profile;
      console.log(this.currentUser);
    }).catch((err: ApiErrorResponse) => {
    });
  }

  private handlePageUrlQueryParams(urlParams: any) {
    // eslint-disable-next-line guard-for-in
    for (const obj in urlParams) {
      switch (obj) {
        case 'page':
        case 'staffPage':
          this.staffParams = {
            ...this.staffParams,
            page: urlParams.page
          };
          break;
        default:
          break;
      }
    }
  }

}
