/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { ServiceProviderCategory } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-suggest-vendor',
  templateUrl: './suggest-vendor.component.html',
  styleUrls: ['./suggest-vendor.component.scss'],
})
export class SuggestVendorComponent implements OnInit {
  @ViewChildren('suggestFormBody', { read: ElementRef }) suggestFormBody: QueryList<ElementRef>;
  categoryLoader = false;
  serviceProviderCategoryList: ReadonlyArray<ServiceProviderCategory> = [];

  suggestVendorForm: UntypedFormGroup;

  constructor(
    private modalController: ModalController,
    private fb: UntypedFormBuilder,
    public apiService: ApiService,
    private helperMethods: HelperMethodsService
  ) { }

  get suggestedVendors(): UntypedFormArray {
    return this.suggestVendorForm.get('suggestedVendors') as FormArray;
  }

  ngOnInit() {
    this.getCategories();
    this.suggestVendorForm = this.fb.group({
      suggestedVendors: new UntypedFormArray([this.newSuggestedVendor()]),
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  addSuggestedVendor() {
    this.suggestedVendors.push(this.newSuggestedVendor());
  }

  removeSuggestedVendor(i: number) {
    if (this.suggestedVendors.length > 1) {
      this.suggestedVendors.removeAt(i);
    }
  }

  toggleSuggestFormBody(index: number) {
    const suggestFormBodyArr = this.suggestFormBody.toArray();
    const elemHeight = suggestFormBodyArr[index].nativeElement.offsetHeight;
    const elemScrollHeight = suggestFormBodyArr[index].nativeElement.scrollHeight;

    if (elemHeight) {
      suggestFormBodyArr[index].nativeElement.style['max-height'] = `0`;
    } else {
      suggestFormBodyArr[index].nativeElement.style[
        'max-height'
      ] = `${elemScrollHeight}px`;
    }
  }

  async suggestProviderHandler() {
    const loading = await this.apiService.getLoader();
    await loading.present();

    const payload = [...this.suggestVendorForm.value.suggestedVendors];
    await this.helperMethods.promiseTimeout(this.apiService.corporateSuggestProvider({ formdata: payload }))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.apiService.corporateAlert(
            res.message,
            'Successful',
            null,
            'corporate-alert-success.png',
            'mr-green-success.png',
            null,
            'success',
          );
          this.dismissModal();
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }

        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });
  }

  private newSuggestedVendor(): UntypedFormGroup {
    return this.fb.group({
      category_id: new UntypedFormControl('', [Validators.required]),
      brand_name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      location: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    });
  }

  private async getCategories() {
    this.categoryLoader = true;
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetCategories())
      .then(async (res: any) => {
        this.categoryLoader = false;
        if (res.status) {
          this.serviceProviderCategoryList = [...res?.data];
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.categoryLoader = false;
      });
  }

}
