/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { ApiErrorResponse, ApiGenericResponseInfo, CorporateStaffResponse } from '@app-models/api.response.interface';
import { CorporateStaff } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-callback-kreadeet',
  templateUrl: './callback-kreadeet.component.html',
  styleUrls: ['./callback-kreadeet.component.scss'],
})
export class CallbackKreadeetComponent implements OnInit {
  @Input() staff: Readonly<CorporateStaff>;
  staffList: ReadonlyArray<CorporateStaff> = [];
  staffListLoader = false;
  callbackKreadeet = {
    corporate_staff_user_id: null,
    amount: null,
    purpose: null
  };

  constructor(
    private modalController: ModalController,
    public apiService: ApiService,
    private helperMethods: HelperMethodsService
  ) { }

  ngOnInit() {
    if (this.staff) {
      this.callbackKreadeet.corporate_staff_user_id = this.staff.staffId;
    }
    this.getStaffs();
  }

  dismissModal(data?: boolean) {
    this.modalController.dismiss(data);
  }

  async callbackKreadeetHandler() {
    const loading = await this.apiService.getLoader();
    await loading.present();

    await this.helperMethods.promiseTimeout(this.apiService.corporateCallbackKreadeet({ ...this.callbackKreadeet }))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.apiService.corporateAlert(
            res.message,
            'Call back Kreadeet Successful',
            null,
            'corporate-alert-success.png',
            'mr-green-success.png',
            null,
            'success',
          );
          this.dismissModal(true);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }

        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });
  }

  private async getStaffs() {
    this.staffListLoader = true;
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetStaffs())
      .then(async (res: CorporateStaffResponse) => {
        this.staffListLoader = false;
        this.staffList = [...res?.data.staffs];
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.staffListLoader = false;
      });
  }

}
