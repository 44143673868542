/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, Input, OnInit } from '@angular/core';
import { ApiErrorResponse, ApiGenericResponseInfo, BranchResponse } from '@app-models/api.response.interface';
import { Branch, FilterParam } from '@app-models/app.interface';
import { PARTNER_TYPES } from '@app-models/constants';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api/api.service';
import { HelperMethodsService } from 'src/app/services/helper-methods/helper-methods.service';
import { ScreenSizeService } from 'src/app/services/screen-size/screen-size.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {

  @Input() mode = 'create';
  @Input() user: any = {
    password: '',
    branchId: null,
    address: ' ',
  };
  isDesktop: boolean;
  isPwa: any;
  seePassword = false;

  totalBranch = 1;
  branches: Array<Branch> = [];
  branchLoading = false;
  searchParam: FilterParam = {
    page: 1,
    limit: 50
  };
  selectedBranch: Branch = {};
  partnerTypes = PARTNER_TYPES;

  constructor(
    public navController: NavController,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public loadingController: LoadingController,
    public apiService: ApiService,
    public actionSheetController: ActionSheetController,
    public alertController: AlertController) {
    this.isPwa = environment.isPwa;
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
    if (this.mode === 'create') {
      this.user.password = '';
    }
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.navController.back();
  }

  ngOnInit() {
    this.getBranch();
    this.user.address = this.user.address || ' ';
  }

  showPassword() {
    this.seePassword = !this.seePassword;
  }


  async createUser() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    console.log(this.user);
    await this.helperMethods.promiseTimeout(this.user.userType === PARTNER_TYPES.MANAGER
      ? this.apiService.merchantCreateManager(this.user) : this.apiService.merchantCreateCashier(this.user))
      .then(async (res: ApiGenericResponseInfo) => {
        console.log(res);
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message, 'Successful',);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse) => {
        console.log(err);
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  async editUser() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    console.log(this.user);
    const param = {
      address: this.user.address || 'null',
      name: this.user.name,
      phone: this.user.phone,
      email: this.user.email,
      password: this.user.password,
      id: this.user.cashierId || this.user.managerId || this.user.id,
    };

    this.user.password ? param.password = this.user.password : console.log(null);
    await this.helperMethods.promiseTimeout(this.user.userType === PARTNER_TYPES.MANAGER || this.user.isSuperAdmin === 1
      ? this.apiService.merchantUpdateManager(param) : this.apiService.merchantUpdateCashier(param))
      .then(async (res: ApiGenericResponseInfo) => {
        console.log(res);
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message, 'Successful',);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse) => {
        console.log(err);
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  continue() {
    if (this.mode === 'create') {
      this.createUser();
    }
    if (this.mode === 'edit') {
      this.editUser();
    }
  }

  async deleteContinue() {
    const alert = await this.alertController.create({
      header: 'Delete Alert',
      // subHeader: 'Subtitle',
      cssClass: 'my-alert danger',
      message: `You are about to delete the user - ${this.user.name}, Are you sure you want to delete your?`,
      mode: 'ios',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          cssClass: 'danger',
          handler: () => {
            this.delete();
          }
        }
      ],
      backdropDismiss: false
    });
    await alert.present();
  }

  async delete() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    console.log(this.user);
    // tslint:disable-next-line:forin
    await this.helperMethods.promiseTimeout(this.user.userType === PARTNER_TYPES.MANAGER
      ? this.apiService.merchantDeleteManager(this.user) : this.apiService.merchantDeleteCashier(this.user))
      .then(async (res: ApiGenericResponseInfo) => {
        console.log(res);
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message, 'Successful',);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse) => {
        console.log(err);
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  async getBranch() {
    await this.helperMethods.promiseTimeout(this.apiService.merchantGetBranches(this.searchParam))
      .then(async (res: BranchResponse) => {
        console.log(res);
        this.branches = [...res?.data?.branches];
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
      });
  }

  loadData(event) {

  }

  portBranch(event) {
    console.log('port:', event.item);
    this.user.branchId = event.item.branchId;
    this.selectedBranch = event.item;
  }

}


