import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporate-metric-card',
  templateUrl: './corporate-metric-card.component.html',
  styleUrls: ['./corporate-metric-card.component.scss'],
})
export class CorporateMetricCardComponent implements OnInit {
  @Input() icon: string;
  @Input() iconBgCss: string;
  @Input() title: string;
  @Input() val1: number;
  @Input() val1Currency: string;
  @Input() percentVal1: number;
  @Input() percentVal1Type: 'increase' | 'decrease';
  @Input() val2: number;
  @Input() val2Title: string;
  @Input() percentVal2: number;
  @Input() percentVal2Type: 'increase' | 'decrease';

  constructor() {}

  ngOnInit() {}
}
