/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { CurrentGeolocationCoordinates } from '@app-models/app.interface';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LaunchNavigator, LaunchNavigatorOptions } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { Geolocation, Position } from '@capacitor/geolocation';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApiService } from '../api/api.service';
declare let google: any;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  locSubscription: Subscription;
  currentLocation = new BehaviorSubject<CurrentGeolocationCoordinates>(null);
  geocoder: any;

  geolocation = Geolocation;

  constructor(
    // private geolocation: Geolocation,
    private launchNavigator: LaunchNavigator,
    private iab: InAppBrowser,
    public apiService: ApiService,
    private platform: Platform) {
    if (google !== undefined) {
      this.geocoder = new google.maps.Geocoder();
    } else {
      this.apiService.errorAlert('Kindly enable location and reopen app.');
    }
  }

  getCurrentLocationInfo() {
    return this.currentLocation.asObservable();
  }


  getCurrentLocation() {
    const options = { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true };
    const locationObs = new Observable((observable: any) => {
      this.geolocation.getCurrentPosition(options)
        .then((resp: Position) => {
          if (resp.coords !== undefined) {
            observable.next(resp.coords);
            this.currentLocation.next(resp.coords);
            console.log(resp);
          }
        }).catch((err) => {
          // TODO: show error;
          // this.apiService.errorAlert(err.message, 'Error');
          console.log('Error getting location', err);
        });
    });
    return locationObs;
  }

  stopWatchLocation() {
    this.locSubscription.unsubscribe();
  }

  stringCurrentAddress(lat: any, lng: any) {
    if (google !== undefined) {
      return new Promise((resolve, reject) => {
        this.geocoder.geocode({ location: { lat, lng } }, (results: any, status: string) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              console.log(results[0]);
              resolve(results[0]);
            } else {
              reject({ formatted_address: '' });
            }
          } else {
            reject({ formatted_address: '' });
          }
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ formatted_address: '' });
      });
    }
  }

  openMap(start: any, end: any) {
    this.launchNavigator
      .isAppAvailable(this.launchNavigator.APP.GOOGLE_MAPS)
      .then((isAvailable: any) => {
        let app: any;
        if (isAvailable) {
          app = this.launchNavigator.APP.GOOGLE_MAPS;
        } else {
          console.warn(
            'Google Maps not available - falling back to user selection'
          );
          app = this.launchNavigator.APP.USER_SELECT;
        }
        const options: LaunchNavigatorOptions = {
          start,
          app
        };
        this.launchNavigator.navigate(end, options);
      }).catch(() => {
        if (this.platform.is('ios')) {
          const d = `maps://` + `?q=${''}&saddr=${start[0]},${start[1]}&daddr=${end[0]},${end[1]}`;
          const browser = this.iab.create(d, '_system');
        } else if (this.platform.is('android')) {
          // tslint:disable-next-line:max-line-length
          const browser = this.iab.create('https://www.google.com/maps/dir/?api=1&origin='
            + start[0] + ',' + start[1] + '&destination=' + end[0] + ',' + end[1], '_system');
        }
      });
  }

  async getLocationCoordinates() {
    let data = null;
    return this.getCurrentLocation().subscribe(async (location: any) =>
      await this.stringCurrentAddress(location.latitude, location.longitude).then((address: any) => {
        console.log(address);
        location.address = address.formatted_address;
        location.address_components = address.address_components.flatMap((x: any) => x.long_name);
        location.state = location.address_components[location.address_components.length - 2];
        location.country = location.address_components[location.address_components.length - 1];
        console.log(location);
        this.currentLocation.next(location);
        return data = this.currentLocation.value;
      }).catch(err => {
        console.log(err);
      }));
  }
}
