/* eslint-disable @typescript-eslint/naming-convention */
export const LOGIN_ERROR = {
  PHONE_NOT_VERIFIED: 'PHONE_NOT_VERIFIED',
  BVN_NOT_VERIFIED: 'BVN_NOT_VERIFIED',
  BANK_NOT_LINKED: 'BANK_NOT_LINKED',
};

export const PARTNER_TYPES = {
  MANAGER: 'partner_manager',
  CASHIER: 'partner_cashier',
  ADMIN: 'partner_admin',
};

export const environmentStage = {
  dev: 'dev',
  production: 'prod',
  stage: 'stage',
};

export const LOGIN_ERRORS = [LOGIN_ERROR.PHONE_NOT_VERIFIED, LOGIN_ERROR.BVN_NOT_VERIFIED, LOGIN_ERROR.BANK_NOT_LINKED];


export const MONTHS = ['January', 'February', 'March', 'April', 'May',
  'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
  'Friday', 'Saturday'];

export const daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const daysMin = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const PAYMENT_SERVICES = {
  FLUTTERWAVE: 'FlutterwaveNigeriaService',
  PAYSTACK: 'PaystackNigeriaService',
  REMITA: 'RemitaNigeriaService',
  PROVIDUS: 'ProvidusNigeriaService',
  CTMFB: 'CTMFBNigeriaService'
};
