import { Component, OnInit } from '@angular/core';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ModalController } from '@ionic/angular';
import { FundAccountComponent } from '../fund-account/fund-account.component';

@Component({
  selector: 'app-clear-kreadeet',
  templateUrl: './clear-kreadeet.component.html',
  styleUrls: ['./clear-kreadeet.component.scss'],
})
export class ClearKreadeetComponent implements OnInit {
  amount: number;
  clearSource: string;

  constructor(
    private modalController: ModalController,
    public apiService: ApiService,
    private helperMethods: HelperMethodsService
  ) { }

  ngOnInit() { }

  dismissModal() {
    this.modalController.dismiss();
  }

  async clearKreadeetHandler() {
    if (this.clearSource === 'PAYMENT_GATEWAY') {
      this.openFundAccountModal();
      this.dismissModal();
      return;
    }
    const loading = await this.apiService.getLoader();
    await loading.present();

    await this.helperMethods.promiseTimeout(this.apiService.corporateClearKreadeet({ amount: this.amount }))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.apiService.corporateAlert(
            res.message,
            'Successful',
            null,
            'corporate-alert-success.png',
            'mr-green-success.png',
            null,
            'success',
          );
          this.dismissModal();
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }

        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });
  }

  private async openFundAccountModal() {
    const modal = await this.modalController.create({
      component: FundAccountComponent,
      componentProps: { amount: this.amount, serviceMode: 'LIQUIDATE' },
      backdropDismiss: true,
      cssClass: 'custom-corporate-modal'
    });

    await modal.present();
    return modal;
  }

}
