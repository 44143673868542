import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-qr-view',
  templateUrl: './qr-view.component.html',
  styleUrls: ['./qr-view.component.scss'],
})
export class QrViewComponent implements OnInit {

  @Input() data: any = '';
  isDesktop: boolean;
  isPwa: any;

  constructor(// tslint:disable: align
    public navController: NavController,
    public loadingController: LoadingController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    public alertController: AlertController) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'QrViewComponent';
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.navController.back();
  }

  ngOnInit() {
  }

}
