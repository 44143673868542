import { Component, OnInit } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiErrorResponse } from '@app-models/api.response.interface';
import { UsersInfo } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-language-setting',
  templateUrl: './language-setting.component.html',
  styleUrls: ['./language-setting.component.scss'],
})
export class LanguageSettingComponent implements OnInit {

  isPwa: any;
  user: any = {
    language: 1,
  };
  currentUser: UsersInfo;

  constructor(
    public navController: NavController,
    public apiService: ApiService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public toastController: ToastController,
    public loadingController: LoadingController) {
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'LanguageSettingComponent';
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  ionViewDidEnter() {
    this.getProfile();
  }

  ngOnInit() {
    this.getProfile();
  }

  async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: UsersInfo) => {
      this.currentUser = res;
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

  goBack(page: string = null) {
    if (page) {
      this.navController.navigateBack(page);
    } else {
      this.navController.back();
    }
  }

  async changeLanguage() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    const param = this.helperMethods.cleanObjectData(this.user);
    this.apiService.successAlert('Language Successfully updated', 'Successfully updated');
    await loading.dismiss();
  }

}
