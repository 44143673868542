import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spacer',
  templateUrl: './spacer.component.html',
  styleUrls: ['./spacer.component.scss'],
})
export class SpacerComponent implements OnInit {

  @Input() lines = 2;
  lineArray = Array.from({ length: this.lines }, (v, k) => k + 1);

  constructor() { }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'SpacerComponent';
  }

  ngOnInit() { }

}
