import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Angular4PaystackModule } from 'angular4-paystack';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SharedPipeModule } from 'src/app/pipes/shared-pipe.module';
import { SharedItemsComponentsModule } from '../items/shared-items-components.module';
import { ClearKreadeetComponent } from './clear-kreadeet/clear-kreadeet.component';
import { FundKreadeetComponent } from './fund-kreadeet/fund-kreadeet.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    Angular4PaystackModule,
    NgChartsModule,
    IonicSelectableModule,
    TranslateModule,
    SharedPipeModule,
    SharedItemsComponentsModule
  ],
  declarations: [
    FundKreadeetComponent,
    ClearKreadeetComponent
  ],
  exports: [
    FundKreadeetComponent,
    ClearKreadeetComponent
  ]
})
export class SharedKreadeetComponentsModule { }
