import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SliderConfigService {

  sliderConfig = {
    grabCursor: true,
    slidesPerView: 1.53 + 0.4,
    spaceBetween: 1,
    centeredSlides: true,
    initialSlide: 0,
    centeredSlidesBounds: true,
    // centerInsufficientSlides: true,
    pager: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.53 + 0.4,
        spaceBetween: 6
      },
      // when window width is >= 480px
      540: {
        slidesPerView: 1.53 + 0.4,
        spaceBetween: 6
      },
      // when window width is >= 640px
      720: {
        slidesPerView: 2.53 + 0.4,
        spaceBetween: 6
      },
      // when window width is >= 640px
      960: {
        slidesPerView: 2.53 + 0.4,
        spaceBetween: 6
      },
      1140: {
        slidesPerView: 2.53 + 0.4,
        spaceBetween: 6
      },
      1240: {
        slidesPerView: 3.53 + 0.4,
        spaceBetween: 6
      },
      1440: {
        slidesPerView: 3.53 + 0.4,
        spaceBetween: 6
      },
      1640: {
        slidesPerView: 3.53 + 0.4,
        spaceBetween: 6
      },
      2640: {
        slidesPerView: 3.53 + 0.4,
        spaceBetween: 6
      }
    }
  };


  sliderConfig2 = {
    grabCursor: true,
    slidesPerView: 1 + 0.2,
    spaceBetween: 1,
    centeredSlides: true,
    initialSlide: 0,
    centeredSlidesBounds: true,
    centerInsufficientSlides: false,
    pager: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1 + 0.2,
        spaceBetween: 6
      },
      // when window width is >= 480px
      540: {
        slidesPerView: 1 + 0.2,
        spaceBetween: 6
      },
      // when window width is >= 640px
      720: {
        slidesPerView: 1 + 0.2,
        spaceBetween: 6
      },
      // when window width is >= 640px
      960: {
        slidesPerView: 1 + 0.2,
        spaceBetween: 6
      },
      1140: {
        slidesPerView: 2 + 0.2,
        spaceBetween: 6
      },
      1240: {
        slidesPerView: 3 + 0.2,
        spaceBetween: 6
      },
      1440: {
        slidesPerView: 3 + 0.2,
        spaceBetween: 6
      },
      1640: {
        slidesPerView: 3 + 0.2,
        spaceBetween: 6
      },
      2640: {
        slidesPerView: 3 + 0.2,
        spaceBetween: 6
      }
    }
  };

  exploreSliderConfig = {
    grabCursor: true,
    slidesPerView: 1 + 0.2,
    spaceBetween: 1,
    // centeredSlides: true,
    initialSlide: 0,
    centeredSlidesBounds: false,
    centerInsufficientSlides: false,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1 + 0.45,
        spaceBetween: 5
      },
      // when window width is >= 480px
      540: {
        slidesPerView: 1 + 0.45,
        spaceBetween: 5
      },
      // when window width is >= 640px
      720: {
        slidesPerView: 2 + 0.45,
        spaceBetween: 5
      },
      // when window width is >= 640px
      960: {
        slidesPerView: 2 + 0.45,
        spaceBetween: 5
      },
      1140: {
        slidesPerView: 2 + 0.45,
        spaceBetween: 5
      },
      1240: {
        slidesPerView: 2 + 0.45,
        spaceBetween: 5
      },
      1440: {
        slidesPerView: 3 + 0.45,
        spaceBetween: 5
      },
      1640: {
        slidesPerView: 3 + 0.45,
        spaceBetween: 5
      },
      2640: {
        slidesPerView: 3 + 0.45,
        spaceBetween: 5
      }
    }
  };

  constructor() { }

  async getExploreSliderConfig() {
    return await this.exploreSliderConfig;
  }

  async getSliderConfig() {
    return await this.sliderConfig;
  }

  async getSliderConfig2() {
    return await this.sliderConfig2;
  }

}
