import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Language } from './language';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private selected: Language;
  private defaultLanguage: Language = { name: 'English', code: 'en' };
  private readonly languages: ReadonlyArray<Language> = [
    { ...this.defaultLanguage }
  ];

  constructor(private translate: TranslateService, private plt: Platform) {
    this.setInitialAppLanguage();
  }

  setInitialAppLanguage() {
    const language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    Preferences.get({ key: LNG_KEY }).then(res => {
      if (res.value != null) {
        const selectedLanguage: Language = (JSON.parse((
          res.value)
        ));
        this.selected = this.setLanguage(selectedLanguage);
      } else {
        this.setLanguage(this.languages[0]);
      }
    });
  }

  getLanguages(): ReadonlyArray<Language> {
    return this.languages;
  }

  getSelectedLanguage(): Language {
    return this.selected;
  }

  setLanguage(selectedLanguage: Language): Language {
    this.translate.use(selectedLanguage.code);
    Preferences.set({ key: LNG_KEY, value: JSON.stringify(selectedLanguage) });
    return selectedLanguage;
  }
}
