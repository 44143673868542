import { Component, Input, OnInit } from '@angular/core';
import { CategoryInfo } from '@app-models/app.interface';

@Component({
  selector: 'app-service-card-item',
  templateUrl: './service-card-item.component.html',
  styleUrls: ['./service-card-item.component.scss'],
})
export class ServiceCardItemComponent implements OnInit {

  @Input() item: CategoryInfo = {
    id: 1,
    name: 'Gas station',
    icon: 'gas-station',
  };

  constructor() { }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'ServiceCardItemComponent';
  }

  ngOnInit() { }
}
