import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-empty-item',
  templateUrl: './empty-item.component.html',
  styleUrls: ['./empty-item.component.scss'],
})
export class EmptyItemComponent implements OnInit {

  @Input() title = this.translate.instant('EMPTY_STATE.NO_ITEM');
  @Input() message = this.translate.instant('EMPTY_STATE.NO_ITEM_SUBTITLE');
  @Input() icon = 'assets/icon/empty.svg';

  constructor(public translate: TranslateService) { }

  ngOnInit() { }

}
