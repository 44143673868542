import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit {

  @Input() passwordStrength = {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0
  };

  constructor() { }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'PasswordStrengthComponent';
  }

  /**
   * Init method/function to be call on  load of component/page
   */
  ngOnInit() {
  }

}
