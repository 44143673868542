import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { MerchantUsersInfo } from '@app-models/app.interface.d';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { CryptoService } from '../../../../services/crypto/crypto.service';
import { PARTNER_TYPES } from '@app-models/constants';


@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements OnInit {

  @Input() currentUser: MerchantUsersInfo = {};
  isPwa: any;
  asGenerated = false;
  paymentToken = '';
  amountValue: string | number = '0.00';
  data: any = {
    amount: 0,
  };
  isDesktop: boolean;

  constructor(
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public cryptoService: CryptoService,
    public apiService: ApiService,
    public alertController: AlertController) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'QrScannerComponent';
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.navController.back();
  }

  ngOnInit() {
  }

  async scanQR() {
    console.log(this.data);
    // Optionally request the permission early
    try {
      const status = await BarcodeScanner.checkPermission();
      if (status.granted) {
        BarcodeScanner.startScan().then(barcodeData => {
          if (barcodeData.hasContent) {
            const decryptText = this.cryptoService.decrypt(barcodeData.content); // barcodeData.content;
            const data = JSON.parse(decryptText);

            console.log({ barcodeData: barcodeData.content });
            console.log({ decryptText });
            console.log({ data });

            this.paymentToken = (data.token || data.paymentToken);
            this.data.user_id = data.userId;
            this.data.paymentToken = data.token || data.paymentToken;
            if (this.currentUser.userType === PARTNER_TYPES.CASHIER) {
              this.cashierMakePayment();
              return;
            }
            this.makePayment();
          }
        }).catch(err => {
          console.log('Error', err);
          // this.apiService.errorAlert('Feature is not support on the web');
        });
      } else {
        // the user denied permission for good
        // redirect user to app settings if they want to grant it anyway
        const permissionModal = await this.apiService.errorAlert('Please grant camera permission to scan QR code');
        permissionModal.onDidDismiss().then(async () => {
          BarcodeScanner.openAppSettings();
        });
      }
    } catch (err) {
      console.log(err);
      this.apiService.errorAlert('Feature is not support on the web');
    }
  }

  async makePayment() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.merchantQRCodeFinalize(this.data))
      .then(async (res: ApiGenericResponseInfo) => {
        console.log(res);
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse) => {
        console.log(err);
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  async cashierMakePayment() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.cashierQRCodeFinalize(this.data))
      .then(async (res: ApiGenericResponseInfo) => {
        console.log(res);
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse) => {
        console.log(err);
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  formatCurrencyOnBlur(event) {
    const uy = this.helperMethods.formatAmount(event.detail.value.toString(), '');
    this.data.amount = event.detail.value.split(',').join('');
    this.amountValue = (uy === 'NaN') ? 0 : uy;
  }

  formatCurrencyOnFocus() {
    const dValue = this.data.amount === 0 ? 0 : parseFloat(this.data.amount.toString()).toFixed(2).toString();
    this.amountValue = (dValue === 'NaN') ? 0 : dValue;
  }

  checkValue(event = null) {
    const uy = this.helperMethods.formatAmount(event.detail.value.toString(), '').split(',').join('');
    this.data.amount = (uy === 'NaN') ? 0 : parseFloat(uy);
    if (event) {
      this.formatCurrencyOnBlur(event);
    }
  }

  generateToken() {
    this.asGenerated = !this.asGenerated;
  }
}
