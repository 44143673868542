/* eslint-disable @typescript-eslint/naming-convention */
import { Branch, PromosInfo } from './../../models/app.interface.d';
/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppMock } from '@app-mocks/app-mock';
import { BmfTransactionsResponse, BranchResponse, CashierResponse, CorporateBranchResponse, CorporatePartnerResponse, CorporateStaffResponse, CorporateTransactionOverviewResponse, DashboardOverviewResponse, DateRangeResponse, PaymentGatewayResponse, SalesReportResponse, ServiceProviderCategoryResponse, TopFiveResponse, UserSaleOverviewResponse } from '@app-models/api.response.interface';
import { AnyInfo, FilterParam, MerchantSearchParam, PaymentInfo, Profile, UtilityPurchase } from '@app-models/app.interface';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';
import { AlertController, LoadingController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AddBankPayload, AddOrRemoveBeneficiariesPayload, BorrowMeFuelChangePayload, BorrowMeFuelPayload, CorporateResetPasswordResetPayload, EmailLoginRequestPayload, ForgetPasswordRequestPayload, initializeTransactionPayload, MonoPayload, OtpRequestPayload, PinChangePayload, PinResetPayload, ResetPasswordResetPayload, UserAddBankPayload, UserLoginRequestPayload, UserRegisterPayload, ValidateOTPRequestPayload } from '../../models/api.payload.interface';
import { ApiErrorResponse, ApiGenericResponseInfo, AuthKreadeetResponse, AuthMerchantResponse, AuthUserResponse, BanksResponse, BeneficiariesResponse, BorrowMeFuelResponse, BundleOrBouquetResponse, CategoryResponse, ClearAmountResponse, ElectricityResponse, ManagerResponse, PlaceResponse, PromosResponse, ProviderResponse, SmartCardResponse, TokenPaymentResponse, TransactionsResponse } from '../../models/api.response.interface';
import { AppCoreService } from '../app-core-service/app-core.service';
import { CryptoService } from '../crypto/crypto.service';
import { NetworkService } from '../network/network.service';
import { ScreenSizeService } from '../screen-size/screen-size.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends AppCoreService {

  /**
   * Creates an instance of ApiService.
   *
   *
   * @param platform
   *
   * @param http
   *
   * @param alertController
   *
   * @param toastController
   * @memberof ApiService
   */
  constructor(
    platform: Platform,
    http: HttpClient,
    router: Router,
    alertController: AlertController,
    navController: NavController,
    toastController: ToastController,
    cryptoService: CryptoService,
    storage: Storage,
    loadingController: LoadingController,
    helperMethods: HelperMethodsService,
    screenSizeService: ScreenSizeService,
    networkService: NetworkService,
    modalController: ModalController,
    appRate: AppRate,
    appMock: AppMock) {
    super(platform, http, router, alertController,
      navController, toastController, cryptoService,
      storage, loadingController, helperMethods,
      screenSizeService, networkService, modalController,
      appRate, appMock);
    this.dbInit();
    this.getInfo();
    this.getLanguageCode();
  }

  // Onboarding API

  // User Onboarding
  async login(param: UserLoginRequestPayload): Promise<AuthUserResponse | ApiErrorResponse> {
    // return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/login', param);
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/switching/login', param);
  }

  async register(param: UserRegisterPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/register', param);
  }

  async verifyBVNAndSelfie(param: FormData): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/validateBVNSelfie', param);
  }

  async addBank(param: UserAddBankPayload): Promise<AuthUserResponse | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/profileBank', param);
  }

  async forgetPin(param: OtpRequestPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/pin/request', param);
  }

  async resetPin(param: PinResetPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/pin/reset', param);
  }

  async resetOtp(param: OtpRequestPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/otp/resend', param);
  }

  async validateOtp(param: ValidateOTPRequestPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/otp/validate', param);
  }

  // Mono periculum

  async updateMonoCode(param: MonoPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/generateMonoCode', param);
  }

  async checkMonoStatus(param: MonoPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/auth/checkMonoCodeStatus', param);
  }


  // Merchant Onboarding
  async merchantLogin(param: EmailLoginRequestPayload): Promise<AuthMerchantResponse | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/merchant/auth/login', param);
  }

  async merchantRegister(param: FormData): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/merchant/auth/register', param);
  }

  async merchantValidateOtp(param: OtpRequestPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/merchant/auth/validateOTP', param);
  }

  async merchantAddBank(param: AddBankPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/auth/profileBank', param);
  }

  async getCategories(): Promise<CategoryResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/merchant/categories', `getCategories`);
  }

  async merchantCashierLogin(param: EmailLoginRequestPayload): Promise<AuthMerchantResponse | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/merchant/cashier/auth/login', param);
  }

  async merchantForgetPassword(param: ForgetPasswordRequestPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/merchant/auth/password/requestPassword', param);
  }

  async merchantResetPin(param: ResetPasswordResetPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/merchant/auth/password/resetPassword', param);
  }


  // Corporate Onboarding
  async corporateLogin(param: EmailLoginRequestPayload): Promise<AuthMerchantResponse | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/corporate/auth/login', param);
  }

  async corporateRegister(param: FormData): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/corporate/auth/register', param);
  }

  async corporateStaffLogin(param: EmailLoginRequestPayload): Promise<AuthMerchantResponse | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/corporate/staff/auth/login', param);
  }

  async corporateForgetPassword(param: ForgetPasswordRequestPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/corporate/auth/password/requestPassword', param);
  }

  async corporateResetPin(param: CorporateResetPasswordResetPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/corporate/auth/password/resetPassword', param);
  }

  async corporateResetOtp(param: OtpRequestPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/corporate/auth/otp/resend', param);
  }

  async corporateValidateOtp(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/corporate/auth/otp/validate', param);
  }


  // Authentication API Profile

  async getUserProfile(): Promise<AuthUserResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/profile', `getProfile`);
  }

  async changeUserPin(param: PinChangePayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.putRequestWithOutAuthHeader(this.apiBaseUrl + '/user/profile/updatePin', param);
  }

  async updateUserProfile(param: Profile): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.patchRequestWithOutAuthHeader(this.apiBaseUrl + '/user/profile', param);
  }


  // User Kreadeet Purse

  async getKreadeetInformation(): Promise<AuthKreadeetResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/kreadeet');
  }

  async initializeTransaction(param: initializeTransactionPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/kreadeet/funding', param);
  }

  async abortTransaction(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.deleteRequestWithAuthHeader(this.apiBaseUrl + `/user/kreadeet/funding/${param.reference}`);
  }

  async clearKreadeet(param: AnyInfo): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/kreadeet/clear', param);
  }

  async getAmountToClear(): Promise<ClearAmountResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/kreadeet/clear/amount', `getAmountToClear`);
  }

  async getPaymentGateways(): Promise<PaymentGatewayResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/paymentGateways', `paymentGateways`);
  }

  // User Beneficiaries

  async getUserBeneficiaries(): Promise<BeneficiariesResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/beneficiaries', `getUserBeneficiaries`);
  }

  async addOrRemoveBeneficiaries(param: AddOrRemoveBeneficiariesPayload): Promise<BeneficiariesResponse | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/beneficiaries', param);
  }

  async getPlacesBeen(): Promise<PlaceResponse | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/user/places', `getPlacesBeen`);
  }

  // Merchant Search

  async searchMerchant(searchParam: MerchantSearchParam): Promise<BeneficiariesResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/user/merchants?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `searchMerchant${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }


  // User Merchant Payment Mode

  async initializeQRPayment(param = null): Promise<PaymentResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/merchants/payments/modes/qrCode/initializeQRPayment', param);
  }

  async initializeCorporateQRPayment(param = null): Promise<PaymentResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/corporate/payments/initializeQr', param);
  }

  async tokenFinalize(param: PaymentInfo): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/merchants/payments/modes/token/finalizeTokenPayment', param);
  }

  async tokenCorporateFinalize(param: PaymentInfo): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/corporate/payments/finalizeToken', param);
  }

  async tokenGetInvoice(param: PaymentInfo): Promise<TokenPaymentResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/merchants/payments/modes/token/getPaymentInvoice', param);
  }

  async tokenGetCorporateInvoice(param: PaymentInfo): Promise<TokenPaymentResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/corporate/payments/info', param);
  }


  // Quick Actions

  async getQuickActions(): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/favourites', `getQuickActions`);
  }

  async createAndUpdateQuickActions(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/favourites', param);
  }


  // Miscellaneous
  // Airtime

  async getAirtimeProviders(): Promise<ProviderResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/miscellaneous/airtime/providers', `getAirtimeProviders`);
  }
  async getCorporateAirtimeProviders(): Promise<ProviderResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/corporate/bills/airtime/providers', `getCorporateAirtimeProviders`);
  }
  async purchaseAirtime(param: UtilityPurchase): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/airtime', param);
  }
  async purchaseCorporateAirtime(param: UtilityPurchase): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/corporate/bills/airtime', param);
  }

  // Data

  async getDataProviders(): Promise<ProviderResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/miscellaneous/data/providers', `getDataProviders`);
  }
  async getCorporateDataProviders(): Promise<ProviderResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/corporate/bills/data/providers', `getCorporateDataProviders`);
  }

  async getDataBundles(param: UtilityPurchase): Promise<BundleOrBouquetResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/data/bundles', param);
  }
  async getCorporateDataBundles(param: UtilityPurchase): Promise<BundleOrBouquetResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/corporate/bills/data/bundles', param);
  }

  async purchaseData(param: UtilityPurchase): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/data/bundles/purchase', param);
  }
  async purchaseCorporateData(param: UtilityPurchase): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/corporate/bills/data/bundles/purchase', param);
  }

  // Electricity

  async getElectricityProviders(): Promise<BundleOrBouquetResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/miscellaneous/electricity/providers', `getElectricityProviders`);
  }
  async getCorporateElectricityProviders(): Promise<BundleOrBouquetResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/corporate/bills/electricity/providers', `getCorporateElectricityProviders`);
  }

  async validateMeter(param: UtilityPurchase): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/electricity/validate', param);
  }
  async validateCorporateMeter(param: UtilityPurchase): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/corporate/bills/electricity/validate', param);
  }

  async purchaseElectricity(param: UtilityPurchase): Promise<ElectricityResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/electricity', param);
  }
  async purchaseCorporateElectricity(param: UtilityPurchase): Promise<ElectricityResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/corporate/bills/electricity', param);
  }

  // Cable TV

  async getCableTVProviders(): Promise<ProviderResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/user/miscellaneous/cabletv/providers', `getCableTVProviders`);
  }

  async getCableTvBouquet(param: UtilityPurchase): Promise<BundleOrBouquetResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/cabletv/bouquets', param);
  }

  async validateSmartCard(param: UtilityPurchase): Promise<SmartCardResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/cabletv/validateSmartCard', param);
  }

  async purchaseCableTv(param: UtilityPurchase): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/cabletv/buy', param);
  }

  // Startimes

  async purchaseStarTimes(param: UtilityPurchase): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/cabletv/startimes', param);
  }

  // Multichoice

  async multiChoiceRenewPackage(param: UtilityPurchase): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/cabletv/multichoice/renew', param);
  }

  async multiChoiceChangePackage(param: UtilityPurchase): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/cabletv/multichoice/change', param);
  }

  // BorrowMe Fuel

  async generateBorrowMeFuelToken(param: BorrowMeFuelPayload): Promise<BorrowMeFuelResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/borrowMeFuel/token', param);
  }

  async changeBorrowMeFuelExpiryDate(param: BorrowMeFuelChangePayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.putRequestWithAuthHeader(this.apiBaseUrl + '/user/miscellaneous/borrowMeFuel', param);
  }

  async getBorrowMeFuel(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<BorrowMeFuelResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/user/miscellaneous/borrowMeFuel?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      null
      // `getElectricityProviders${this.helperMethods.convertJsonToUrlParam(searchParam)}`
    );
  }

  // User corporate
  async setDefaultAccount(corporateUserId: number | null): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.putRequestWithAuthHeader(this.apiBaseUrl + '/user/corporate/setDefault', { corporateUserId });
  }
  async getCorporateProfile(): Promise<any> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/user/corporate/profile`,
      `getCorporateProfile`);
  }


  // Transactions

  async getMerchantTransactionsReportDateRanges(branch_id = null): Promise<DateRangeResponse | ApiErrorResponse> {
    const queryParam = branch_id ? `?branch_id=${branch_id}` : '';
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/merchant/transactions/report/dateRanges${queryParam}`,
      `getMerchantTransactionsReportDateRanges${queryParam}`);
  }

  async getMerchantTransactionsReport(dateRangeValue: number, branch_id = null): Promise<SalesReportResponse | ApiErrorResponse> {
    const queryParam = branch_id ? `?branch_id=${branch_id}` : '';
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/merchant/transactions/report/${dateRangeValue}${queryParam}`,
      `getMerchantTransactionsReport${dateRangeValue}${queryParam}`);
  }

  async getOverviewByManagerID(adminId: number, branch_id = null): Promise<UserSaleOverviewResponse | ApiErrorResponse> {
    const queryParam = branch_id ? `?branch_id=${branch_id}` : '';
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/merchant/overview/${adminId}${queryParam}`,
      `getOverviewByManagerID${adminId}${queryParam}`);
  }

  async getMerchantTotalRevenue(branch_id = null): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    const queryParam = branch_id ? `?branch_id=${branch_id}` : '';
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/merchant/transactions/totalRevenue${queryParam}`,
      `getMerchantTotalRevenue${queryParam}`);
  }

  async getMerchantTopFive(branch_id = null): Promise<TopFiveResponse | ApiErrorResponse> {
    const queryParam = branch_id ? `?branch_id=${branch_id}` : '';
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/merchant/transactions/topFive${queryParam}`,
      `getMerchantTopFive${queryParam}`);
  }

  async getMerchantTransactions(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<TransactionsResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/merchant/transactions?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `getMerchantTransactions${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async getMerchantBmfTransactions(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<BmfTransactionsResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/merchant/transactions/bmfs?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `getMerchantBmfTransactions${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async getCashierTransactions(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<TransactionsResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/merchant/cashier/transactions?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `getCashierTransactions${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async getMerchantPurseBalance(): Promise<any | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/merchant/purse`,
      `getMerchantPurseBalance`);
  }

  async getMerchantPurseTransactions(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<TransactionsResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/merchant/purse/transactions?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `getMerchantPurseTransactions${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async merchantSweepBalanceRequest(): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/purse/sweep', {});
  }

  async getUserTransactions(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<TransactionsResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/user/transactions?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `getUserTransactions${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async getUserCorporateTransactions(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<TransactionsResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/user/corporate/transactions?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `getUserCorporateTransactions${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async getUserCorporateTransactionsOverview(): Promise<TransactionsResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl +
      `/user/corporate/transactions/overview`,
      `getUserCorporateTransactionsOverview`);
  }

  // merchant/transactions/topFive

  // Dashboard

  async getDashboardOverview(branch_id = null): Promise<DashboardOverviewResponse | ApiErrorResponse> {
    const queryParam = branch_id ? `?branch_id=${branch_id}` : '';
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/merchant/dashboard/overview${queryParam}`, `getDashboardOverview`);
  }

  // Merchant

  async getMerchantProfile(): Promise<AuthMerchantResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/merchant/admin/profile`, `getMerchantProfile`);
  }

  async merchantChangePassword(param: PinChangePayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/admin/profile', param);
  }

  // Manager

  async merchantGetManagers(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<ManagerResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/merchant/owner/managers?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `merchantGetManagers-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async merchantCreateManager(param: Profile): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/owner/managers', param);
  }

  async merchantUpdateManager(param: Profile): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.putRequestWithAuthHeader(this.apiBaseUrl + `/merchant/owner/managers/${param.id}`, param);
  }

  async merchantDeleteManager(param: Profile): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.deleteRequestWithAuthHeader(this.apiBaseUrl + `/merchant/owner/managers/${param.id}`);
  }

  async merchantGetTotalManagers(): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/merchant/managers/total`, `merchantGetTotalManagers`);
  }

  // Cashier

  async getCashierProfile(): Promise<AuthMerchantResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/merchant/cashier/profile`, `getCashierProfile`);
  }

  async merchantGetCashiers(searchParam: FilterParam = { page: 1, limit: 10 }, branch_id = null): Promise<CashierResponse | ApiErrorResponse> {
    searchParam.branch_id = branch_id;
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/merchant/cashiers?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `merchantGetCashiers-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async merchantCreateCashier(param: Profile): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/cashiers', param);
  }

  async merchantUpdateCashier(param: Profile): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.putRequestWithAuthHeader(this.apiBaseUrl + `/merchant/cashiers/${param.id}`, param);
  }

  async merchantDeleteCashier(param: Profile): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.deleteRequestWithAuthHeader(this.apiBaseUrl + `/merchant/cashiers/${param.id}`);
  }

  async merchantGetTotalCashiers(branch_id = null): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    const queryParam = (branch_id) ? `?branch_id=${branch_id}` : '';
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/merchant/cashiers/total${queryParam}`, `merchantGetTotalCashiers`);
  }

  async merchantGetCashierProfile(): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/merchant/cashier/profile', 'merchantGetCashierProfile');
  }

  async cashierChangePassword(param: ResetPasswordResetPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + '/merchant/cashier/profile/changePassword', param);
  }

  async getCashierDashboardOverview(cashierId: string): Promise<DashboardOverviewResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(
      this.apiBaseUrl + `/merchant/cashier/sales/overview/${cashierId}`, `getCashierDashboardOverview${cashierId}`);
  }

  // Payment QRCode

  async cashierQRCodeFinalize(param: PaymentInfo): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/cashier/payments/modes/qrCode/finalizeQRPayment', param);
  }

  async cashierTokenInitialize(param: PaymentInfo): Promise<PaymentResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/cashier/payments/modes/token', param);
  }



  // Branches

  async merchantGetBranches(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<BranchResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/merchant/branches?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `merchantGetBranches-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async merchantCreateBranches(param: FormData): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/branches', param);
  }

  async merchantUpdateBranch(branchId: string, param: FormData): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + `/merchant/branches/${branchId}/update`, param);
  }

  async merchantDeleteBranch(param: Branch): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.deleteRequestWithAuthHeader(this.apiBaseUrl + `/merchant/branches/${param.id}`);
  }

  // Promo

  async merchantGetPromos(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<PromosResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/merchant/promos?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `merchantGetPromos-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async merchantCreatePromos(param: PromosInfo): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/promos', param);
  }

  // Payment QRCode

  async merchantQRCodeFinalize(param: PaymentInfo): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/payments/modes/qrCode/finalizeQRPayment', param);
  }

  async merchantTokenInitialize(param: PaymentInfo): Promise<PaymentResponse | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/merchant/payments/modes/token', param);
  }


  // Corporate Service Provider
  async corporateGetServiceProviders(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<CorporatePartnerResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/admin/partners?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `corporateGetServiceProviders-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async corporateGetServiceProvider(id: string): Promise<CorporatePartnerResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/admin/partners/single/${id}`,
      `corporateGetServiceProvider-${id}`);
  }

  async corporateGetStaffProviders(staffId: string, searchParam: FilterParam = { page: 1, limit: 10 }): Promise<CorporatePartnerResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/staff/partner/${staffId}?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `corporateGetStaffProviders-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async corporateGetCategories(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<ServiceProviderCategoryResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/categories?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `corporateGetCategories-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async corporateGetPartnerByCategory(categoryID: number, searchParam: FilterParam = { page: 1, limit: 10 }): Promise<BranchResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/category/partner/${categoryID}?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `corporateGetPartnerByCategory-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async corporateAddProvider(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/admin/partners/create', param);
  }

  async corporateSuggestProvider(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/admin/partners/suggest', param);
  }

  async corporateDeleteProvider(id: number): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.deleteRequestWithAuthHeader(this.apiBaseUrl + `/corporate/admin/partners/delete/${id}`);
  }

  async corporateDeleteStaffProvider(id: number): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.deleteRequestWithAuthHeader(this.apiBaseUrl + `/corporate/staff/partner/delete/${id}`);
  }

  async corporateGetVirtualAccount(): Promise<CorporateBranchResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/account`,
      `corporateGetVirtualAccount`);
  }

  // Corporate Branch
  async corporateGetBranches(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<CorporateBranchResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/branch?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `corporateGetBranches-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async corporateCreateBranch(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/branch/register', param);
  }

  async corporateGetBranch(id: string): Promise<CorporateStaffResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/branch/${id}`,
      `corporateGetBranch-${id}`);
  }

  // Corporate Staff
  async corporateGetStaffs(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<CorporateStaffResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/staff?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `corporateGetStaffs-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async corporateGetStaff(id: string): Promise<CorporateStaffResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/staff/${id}`,
      `corporateGetStaff-${id}`);
  }

  async corporateCreateStaff(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/staff/create', param);
  }

  async corporateUpdateStaff(id: number, param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + `/corporate/staff/edit/${id}`, param);
  }

  async corporateAddStaffProvider(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/staff/add-partner', param);
  }

  async corporateAllocateKreadeet(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/kreadeet/staff/config', param);
  }

  async corporateCallbackKreadeet(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/kreadeet/staff/callback', param);
  }

  async corporateClearKreadeet(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/kreadeet/clear/amount', param);
  }

  async corporateRequestKreadeet(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/kreadeet/request', param);
  }

  async corporateFreezeUnFreezeKreadeet(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/kreadeet/staff/freeze', param);
  }

  async corporateActivateStaff(id: string): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.patchRequestWithAuthHeader(this.apiBaseUrl + `/corporate/staff/activate/${id}`, {});
  }

  async corporateDeactivateStaff(id: string): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.patchRequestWithAuthHeader(this.apiBaseUrl + `/corporate/staff/deactivate/${id}`, {});
  }

  async corporateGetStaffMetrics(filterParam?): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/staff/metrics?${this.helperMethods.convertJsonToUrlParam(filterParam)}`,
      `corporateGetStaffMetrics-${this.helperMethods.convertJsonToUrlParam(filterParam)}`);
  }

  async corporateGetBranchMetrics(filterParam?): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/admin/metrics?${this.helperMethods.convertJsonToUrlParam(filterParam)}`,
      `corporateGetBranchMetrics-${this.helperMethods.convertJsonToUrlParam(filterParam)}`);
  }

  async corporateGetKreadeetMetrics(): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/admin/kreadeet/metrics`,
      `corporateGetKreadeetMetrics`);
  }

  async corporateGetTransactionMetrics(filterParam?): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/kreadeet/transactions/metrics?${this.helperMethods.convertJsonToUrlParam(filterParam)}`,
      `corporateGetTransactionsMetrics-${this.helperMethods.convertJsonToUrlParam(filterParam)}`);
  }

  async corporateGetStaffTransactions(staffId: number, searchParam: FilterParam = { page: 1, limit: 10 }): Promise<CorporateStaffResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/staff/transactions/${staffId}?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `corporateGetStaffTransactions-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async corporateGetKreadeetTransactions(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/kreadeet/transactions?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `corporateGetKreadeetTransactions-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async corporateGetKreadeetHistories(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/kreadeet/transactions/history?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `corporateGetKreadeetHistories-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async corporateGetTransactionsOverview(searchParam?): Promise<CorporateTransactionOverviewResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/corporate/transactions/overview?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `corporateGetTransactionsOverview-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  async initializeCorporateFunding(param: initializeTransactionPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/corporate/kreadeet/funding/topup', param);
  }

  async abortCorporateFunding(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.deleteRequestWithAuthHeader(this.apiBaseUrl + `/corporate/kreadeet/funding/${param.reference}`);
  }

  async corporateGetPaymentGateways(): Promise<PaymentGatewayResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/corporate/paymentGateways', `corporatePaymentGateways`);
  }

  // Promo
  async userAddPromo(param: PromosInfo): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + '/user/promos', param);
  }

  async userGetPromos(searchParam: FilterParam = { page: 1, limit: 10 }): Promise<PromosResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/user/promos?${this.helperMethods.convertJsonToUrlParam(searchParam)}`,
      `userGetPromos-${this.helperMethods.convertJsonToUrlParam(searchParam)}`);
  }

  // User Add New Bank

  async addNewBank(param: UserAddBankPayload): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + `/user/banks`, param);
  }

  async uploadBankStatementPDF(param: FormData): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + `/user/auth/uploadBankStatementPDF`, param);
  }


  async addUploadStatement(param: FormData): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithAuthHeader(this.apiBaseUrl + `/user/banks/uploadStatement`, param);
  }


  // Resources Apis

  async getCountries(): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + '/countries', `countries`);
  }

  async getBanks(countryId = 1): Promise<BanksResponse | ApiErrorResponse> {
    return this.getRequestFromServeWithLocalFallback(this.apiBaseUrl + `/banks/${countryId}`, `banks-${countryId}`);
  }

  async resolveBankName(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + `/merchant/auth/resolveBankName`, param);
  }

  async remitaTest(param: any): Promise<ApiGenericResponseInfo | ApiErrorResponse> {
    return this.postRequestWithOutAuthHeader(this.apiBaseUrl + `/webhook?gateway=RemitaNigeria`, param);
  }


}
