import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiErrorResponse } from '@app-models/api.response.interface';
import { CorporatePagination, CorporatePartner, CorporateProfile, CorporateStaff, FilterParam } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ModalController, NavController } from '@ionic/angular';
import { ActionConfirmationModalComponent } from '../action-confirmation-modal/action-confirmation-modal.component';
import { AddServiceProviderComponent } from '../add-service-provider/add-service-provider.component';
import { AddStaffProviderComponent } from '../add-staff-provider/add-staff-provider.component';
import { SuggestVendorComponent } from '../suggest-vendor/suggest-vendor.component';

@Component({
  selector: 'app-corporate-providers',
  templateUrl: './corporate-providers.component.html',
  styleUrls: ['./corporate-providers.component.scss'],
})
export class CorporateProvidersComponent implements OnInit {
  @Input() title: string;
  @Input() corporateBranchId: string;
  @Input() staffId: string;
  @Input() staff: CorporateStaff;
  currentUser: CorporateProfile;
  providerList: ReadonlyArray<CorporatePartner> = [];
  providerLoader = false;
  providerParams: FilterParam = { page: 1, limit: 10, paginate: true };
  pagination: CorporatePagination;

  constructor(
    protected readonly route: ActivatedRoute,
    private router: Router,
    private navController: NavController,
    private modalController: ModalController,
    private helperMethods: HelperMethodsService,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.getProfile();
    this.route.queryParams.subscribe(async (params) => {
      const paramsKey = Object.keys(params);
      if (paramsKey.length) {
        // this.page = params.page;
        await this.handlePageUrlQueryParams(params);
        // await this.generateRequestParamsFromFilter();
      }
      if (!this.staffId) {
        this.getServiceProviders();
      } else {
        this.getStaffProviders();
      }
    });
  }

  paginationEventHandler(page: number) {
    this.providerParams.page = page;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
  }

  goToDetailsPage(id) {
    this.navController.navigateForward(`/member/corporate/dashboard/service-provider/${id}`);
  }

  async openAddServiceProviderModal() {
    const corporateBranchId = this.corporateBranchId;
    const modal = await this.modalController.create({
      component: AddServiceProviderComponent,
      componentProps: { corporateBranchId },
      backdropDismiss: false,
      cssClass: 'custom-corporate-modal'
    });

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        if (data.data === 'SUGGEST_VENDOR') {
          this.openSuggestVendorModal();
        } else {
          this.getServiceProviders();
        }
      }
    });

    await modal.present();
    return modal;
  }

  async confirmServiceProviderDeleteHandler(id: number) {
    const modal = await this.modalController.create({
      component: ActionConfirmationModalComponent,
      componentProps: {
        title: 'Delete Service Provider',
        message: 'Are you sure you want to delete this Service Provider?',
        mainBtnTitle: 'Yes, Delete Service Provider',
        btnType: 'danger',
        cancelBtnTitle: 'Not Now'
      },
      backdropDismiss: false,
      cssClass: 'action-confirmation-modal'
    });

    modal.onDidDismiss().then(response => {
      if (response.data) {
        if (!this.staffId) {
          this.serviceProviderDeleteHandler(id);
          return;
        }
        this.staffServiceProviderDeleteHandler(id);
      }
    });

    await modal.present();
    return modal;

  }

  async confirmServiceProviderSuspendHandler(i) {
    const modal = await this.modalController.create({
      component: ActionConfirmationModalComponent,
      componentProps: {
        title: 'Suspend Staff',
        message: `Are you sure you want to suspend this Service Provider?
          The provider will not listed as Kreadeet service provider after suspending`,
        mainBtnTitle: 'Yes, Suspend Service Provider',
        btnType: 'warning',
        cancelBtnTitle: 'Not Now'
      },
      backdropDismiss: false,
      cssClass: 'action-confirmation-modal'
    });

    modal.onDidDismiss().then(response => {
      if (response.data) {
        this.serviceProviderSuspendHandler();
      }
    });

    await modal.present();
    return modal;

  }

  async openProviderAssignment() {
    const modal = await this.modalController.create({
      component: AddStaffProviderComponent,
      componentProps: { staff: this.staff },
      backdropDismiss: false,
      cssClass: 'custom-corporate-modal'
    });

    modal.onDidDismiss().then(response => {
      if (response.data) {
        this.getStaffProviders();
      }
    });

    await modal.present();
    return modal;
  }

  private async getServiceProviders() {
    this.providerLoader = true;

    if (this.corporateBranchId) {
      this.providerParams.branchId = this.corporateBranchId;
    }

    await this.helperMethods.promiseTimeout(this.apiService.corporateGetServiceProviders(this.providerParams))
      .then(async (res: any) => {
        this.providerLoader = false;
        if (res.status) {
          this.providerList = [...res?.data.partners];
          this.pagination = res?.data.pagination;
        }
        console.log(this.providerList);
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.providerLoader = false;
      });
  }

  private async getStaffProviders() {
    this.providerLoader = true;

    if (this.corporateBranchId) {
      this.providerParams.branchId = this.corporateBranchId;
    }

    await this.helperMethods.promiseTimeout(this.apiService.corporateGetStaffProviders(this.staffId, this.providerParams))
      .then(async (res: any) => {
        this.providerLoader = false;
        if (res.status) {
          this.providerList = [...res?.data.partners];
          this.pagination = res?.data.pagination;
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.providerLoader = false;
      });
  }

  private async openSuggestVendorModal() {
    const modal = await this.modalController.create({
      component: SuggestVendorComponent,
      backdropDismiss: false,
      cssClass: 'custom-corporate-modal'
    });

    await modal.present();
    return modal;
  }

  private async serviceProviderDeleteHandler(id: number) {
    const loading = await this.apiService.getLoader();
    await loading.present();

    await this.helperMethods.promiseTimeout(this.apiService.corporateDeleteProvider(id))
      .then(async (res: any) => {
        await loading.dismiss();
        if (res.status) {
          this.getServiceProviders();
          this.apiService.corporateAlert(
            res.message,
            'Service Provider Deleted',
            null,
            'corporate-alert-error.png',
            'delete-alert.svg',
            null,
            'error',
          );
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });
  }

  private async staffServiceProviderDeleteHandler(id: number) {
    const loading = await this.apiService.getLoader();
    await loading.present();

    await this.helperMethods.promiseTimeout(this.apiService.corporateDeleteStaffProvider(id))
      .then(async (res: any) => {
        await loading.dismiss();
        if (res.status) {
          this.getStaffProviders();
          this.apiService.corporateAlert(
            res.message,
            'Service Provider Deleted',
            null,
            'corporate-alert-error.png',
            'delete-alert.svg',
            null,
            'error',
          );
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });
  }

  private serviceProviderSuspendHandler() {
    this.apiService.corporateAlert(
      `You have successfully suspended this service provider`,
      'Service Provider Suspended',
      null,
      'corporate-alert-success.png',
      null,
      null,
      'warning',
    );
  }

  private async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: CorporateProfile) => {
      this.currentUser = res.profile;
    }).catch((err: ApiErrorResponse) => {
    });
  }

  private handlePageUrlQueryParams(urlParams: any) {
    // eslint-disable-next-line guard-for-in
    for (const obj in urlParams) {
      switch (obj) {
        case 'page':
        case 'staffPage':
          this.providerParams = {
            ...this.providerParams,
            page: urlParams.page
          };
          break;
        default:
          break;
      }
    }
  }

}
