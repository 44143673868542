import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { v4 as NEW_UUID } from 'uuid';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../services/api/api.service';
import { HelperMethodsService } from '../../../../services/helper-methods/helper-methods.service';
import { LocationService } from '../../../../services/location/location.service';
import { ScreenSizeService } from '../../../../services/screen-size/screen-size.service';

@Component({
  selector: 'app-upload-bank-statement',
  templateUrl: './upload-bank-statement.component.html',
  styleUrls: ['./upload-bank-statement.component.scss'],
})
export class UploadBankStatementComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  isPwa: boolean;
  isDesktop: boolean;
  formData: FormData = new FormData();
  files: any[] = [];
  protected uuid = NEW_UUID();
  // eslint-disable-next-line max-len
  protected acceptedFiles = 'application/msword, application/vnd.ms-excel, application/pdf, image/*';

  constructor(
    private router: Router,
    public navController: NavController,
    public screenSizeService: ScreenSizeService,
    public apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    public actionSheetController: ActionSheetController,
    public loadingController: LoadingController,
    private helperMethods: HelperMethodsService,
    private locationService: LocationService,
    public activeRoute: ActivatedRoute,
    public modalController: ModalController,
    public alertController: AlertController) {
    this.isPwa = environment.isPwa;
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit() { }


  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }


  async uploadStatement() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    console.log(this.formData);
    await this.helperMethods.promiseTimeout(this.apiService.addUploadStatement(this.formData))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.apiService.successAlert(res.message, res?.header);
          this.dismissModal();
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
      });
  }


  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: EventTarget | any) {
    const eventObj: any = event as any;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const files: FileList = target.files;
    console.log('files: ', files);
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   *
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.files = null;
  }

  /**
   * Convert Files list to normal array list
   *
   * @param files (Files List)
   */
  prepareFilesList(files: FileList) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    // for (let index = 0; index < files.length; index++) {
    //   this.files.push(files[index]);
    // }
    this.files[0] = files[0];
    this.formData.append('statement', this.files[0]);
  }

  /**
   * format bytes
   *
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
