import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { ApiErrorResponse, PaymentGatewayResponse } from '@app-models/api.response.interface';
import { CorporateProfile, PaymentGateway } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ModalController } from '@ionic/angular';
import { PaystackOptions } from 'angular4-paystack';

@Component({
  selector: 'app-fund-account',
  templateUrl: './fund-account.component.html',
  styleUrls: ['./fund-account.component.scss'],
})
export class FundAccountComponent implements OnInit {
  @ViewChild('PaystackButton') paystackButton: any;
  @Input() serviceMode: string;
  @Input() amount: number;
  paymentGateways: ReadonlyArray<PaymentGateway> = [];
  paystackOptions: PaystackOptions = {
    amount: 0,
    email: '',
    ref: ''
  };
  amountValue: string | number = null;
  isPaymentGateway = true;
  data: any = {
    paymentGateway: null
  };
  loading: HTMLIonLoadingElement;

  private modalController = inject(ModalController);
  private ref = inject(ChangeDetectorRef);
  private apiService = inject(ApiService);
  private helperMethods = inject(HelperMethodsService);

  constructor() { }

  ngOnInit() {
    this.getProfile();
    this.getPaymentGateways();
    this.serviceMode = this.serviceMode ? this.serviceMode : 'TOP_UP';
    if (this.amount) {
      this.data.amount = this.amount;
      this.calCharge();
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  calCharge(event = null) {
    if (event) {
      this.checkValue(event);
    } else {
      const dValue = this.data.amount === 0 ? '0' : parseFloat(this.data.amount.toString()).toFixed(2).toString();
      this.amountValue = this.helperMethods.formatAmount(((dValue === 'NaN') ? '0' : dValue), '');
    }
    this.data.amount = parseFloat(event.target.value.toString().split(',').join(''));
    // this.data.charges = this.helperMethods.payStackCharges(event.target.value);
    this.data.charges = 0;
    const amountWithCharges = parseFloat(((this.data.charges + this.data.amount) * 100).toFixed(2));

    // paystack
    this.paystackOptions.amount = amountWithCharges;

    if (event) {
      this.formatCurrencyOnBlur(event);
    }
  }

  /**
   * Paystack async InlinePay
   */
  async payViaPaystackPromise($event) {
    this.loading = await this.apiService.getLoader();
    await this.loading.present();
    await this.getGatewayTransactionRef().then(() => {
      this.paystackButton.el.click();
    });
    await this.loading.dismiss();
  }

  /**
   * Paystack Payment Init
   */
  async paymentInit($event = null) {
    this.loading = await this.apiService.getLoader();
    await this.loading.present();
    this.paystackOptions.metadata = this.data;
    this.isPaymentGateway = false;
    this.ref.detectChanges();
  }

  /**
   * Paystack Payment Cancel
   */
  async paymentCancel($event = null) {
    await this.loading.dismiss();
    const title = 'Payment failed';
    const ref = {
      reference: this.paystackOptions.ref,
      trans: null,
      status: 'failed',
      message: 'Closed',
      transaction: null,
      trxref: this.paystackOptions.ref
    };
    this.apiService.errorAlert(title);
    this.dismissModal();
    this.isPaymentGateway = true;
    this.ref.detectChanges();
    this.apiService.abortCorporateFunding({ reference: this.paystackOptions.ref });
  }

  /**
   * Paystack Payment Done
   */
  async paymentDone(ref: any) {
    await this.loading.dismiss();
    const title = 'Payment successfully';
    if (ref.message === 'Approved') {
      ref.message = 'Payment was make successfully.';
    }
    if (ref.status === 'success') {
      // this.makePayment({});
    } else {
      // this.apiService.successAlert(this.title);
    }
    this.dismissModal();
    this.isPaymentGateway = true;
    this.ref.detectChanges();
  }

  private formatCurrencyOnBlur(event) {
    const uy = this.helperMethods.formatAmount(event.target.value.toString(), '');
    this.data.amount = event.target.value.toString().split(',').join('');
    // this.data.charges = this.helperMethods.payStackCharges(this.data.amount);
    this.data.charges = 0;
    this.amountValue = (uy === 'NaN') ? 0 : uy;
  }

  private checkValue(event: any) {
    const uy = this.helperMethods.formatAmount(event.target.value.toString(), '').split(',').join('');
    // this.amountValue = (uy === 'NaN') ? 0 : (uy);
    this.data.amount = (uy === 'NaN') ? 0 : parseFloat(uy);
  }

  /**
   * Get Gateway Transaction Ref
   */
  private async getGatewayTransactionRef() {
    const param = {
      amount: this.data.amount,
      serviceMode: this.serviceMode,
      gateway: this.data.paymentGateway,
    };
    let gotTransactionRef = false;
    await this.helperMethods.promiseTimeout(this.apiService.initializeCorporateFunding(param)).then(async (res) => {
      if (res.status) {
        this.data.gatewayTransactionRef = res?.data?.reference;
        // Paystack
        this.paystackOptions.ref = this.data.gatewayTransactionRef || res?.data?.reference;

        // Remita
        // this.remitaConfig.transactionId = this.data.gatewayTransactionRef || res?.data?.reference;
      }
      gotTransactionRef = true;
    }).catch(async (err) => {
      this.apiService.errorAlert(err.message, err?.header);
      if (err?.shouldLogout) {
        await this.apiService.logout();
      }
      gotTransactionRef = false;
    });
    return gotTransactionRef;
  }

  private async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: CorporateProfile) => {
      // Paystack
      this.paystackOptions.email = res.profile.companyEmail;
    }).catch((err: ApiErrorResponse) => {
    });
  }

  private async getPaymentGateways() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetPaymentGateways())
      .then(async (res: PaymentGatewayResponse) => {
        await loading.dismiss();
        this.paymentGateways = res.data;
        console.log(this.paymentGateways);
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
      });
  }

}
