/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@app-environments/environment';
import { AnyInfo, TokenInfo, UsersInfo } from '@app-models/app.interface';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';
import { Browser } from '@capacitor/browser';
import { Device, DeviceInfo, GetLanguageCodeResult } from '@capacitor/device';
import { AlertController, LoadingController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { ComponentProps, ComponentRef } from '@ionic/core';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { now } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AppMock } from '../../mocks/app-mock';
import { balances } from '../../models/app.interface';
import { CryptoService } from '../crypto/crypto.service';
import { NetworkService } from '../network/network.service';
import { ScreenSizeService } from '../screen-size/screen-size.service';

const TOKEN_DATA = 'current_user';
const CURRENT_TOKEN = 'current_token';
const PERSISTENT_USER = 'persistent_user';
const PERSISTENT_USER_PLAN = 'persistent_user_plan';
const ENABLE_FINGERPRINT = 'enable_Fingerprint';
const ENABLE_SHOW_BALANCE = 'show_Balance';
const ENABLE_DARK_MODE = 'dark_mode';
const ENABLE_PANIC_MODE = 'panic_mode';
const ENABLE_ALWAYS_LOGGED_IN_MODE = 'always_logged_in';
const CURRENT_DOMAIN = 'currentDomain';
const NEW_RELEASE = 'new_release';
const VERSION = 'version';
const USER_GUARD = 'user';
const AGENT_GUARD = 'agent';

@Injectable({
  providedIn: 'root'
})
export class AppCoreService {

  MERCHANT_ADMIN_GUARD = 'merchant_admin';
  apiBaseUrl: string | 'https://dev.api.kreadeet.com/api/v1' | 'https://dev.api.kreadeet.com/api/v1';
  accessToken: TokenInfo | any;
  userDataState: UsersInfo;
  deviceInfo: any;
  deviceLanguageCodeResult: GetLanguageCodeResult;
  authenticationState = new BehaviorSubject(false);
  agentState = new BehaviorSubject(false);
  enableFingerprint = new BehaviorSubject(false);
  enableShowBalance = new BehaviorSubject(true);
  enableDarkMode = new BehaviorSubject(false);
  enablePanicMode = new BehaviorSubject(false);
  enableAlwaysLoggedInMode = new BehaviorSubject(false);
  currentUserDataState = new BehaviorSubject<UsersInfo>({});
  isNewRelease = new BehaviorSubject(false);
  version = new BehaviorSubject<string>('');
  currentVersion = environment.appVerCode;
  oneLink = 'http://onelink.to/e5778w';
  currentDomain: string;
  api: any;
  modalsInst = [];
  userBalance: balances = {
    kreadeetDetails: {
      action: 'Clear Kreadeet',
      account: 'N0.00',
      balance: 0,
      balanceTitle: 'What you have spent: ',
      name: '',
      plan: 'Kreadeet'
    },
    kreadeetPlusDetails: {
      account: 'N/A',
      action: 'Fund Kreadeet+',
      balance: 0,
      balanceTitle: 'Account No: ',
      name: '',
      plan: 'Kreadeet+'
    },
  };
  public currentSubUrl: any = '';
  public currentUrl: any = '';
  public currentUserBalances = new BehaviorSubject<balances>(this.userBalance);
  public headers: HttpHeaders = new HttpHeaders();
  private calledAPIs = [];

  constructor(
    public platform: Platform,
    public http: HttpClient,
    public router: Router,
    public alertController: AlertController,
    public navController: NavController,
    public toastController: ToastController,
    public cryptoService: CryptoService,
    public storage: Storage,
    public loadingController: LoadingController,
    public helperMethods: HelperMethodsService,
    public screenSizeService: ScreenSizeService,
    public networkService: NetworkService,
    public modalController: ModalController,
    public appRate: AppRate,
    public appMock: AppMock) {
    this.platform.ready().then(() => {
      this.apiBaseUrl = environment.apiBaseUrl;
      this.checkToken().then(async () => {
        const currentState = await this.isAuthenticated();
        await this.getLocalData('currentDomain').then((data) => {
          console.log(data);
          this.currentDomain = data;
        }).catch((err) => {
          console.log(err);
          this.currentDomain = 'user';
        });
        if (currentState) {
          // // console.log(currentState);
          this.getAuthenticatedUser().then((userDataState) => {
            this.userDataState = userDataState;
            console.log('here', this.userDataState);
            this.MERCHANT_ADMIN_GUARD = this.userDataState.user_type === 'owner' ? 'merchant_admin' : this.userDataState.user_type;
            console.log(this.userDataState);
          }).catch(() => {
            // console.log(e);
            this.authenticationState.next(false);
          });
          console.log(this.router.url);
          this.getAuthenticatedToken(this.currentDomain).then((accessToken) => {
            this.accessToken = accessToken;
            console.log('accessToken:', accessToken);
          }).catch((err) => {
            // console.log(e);
            console.log('accessToken:', err);
            console.log('currentDomain:', this.currentDomain);
            // this.accessToken = err.accessToken;
            this.authenticationState.next(false);
          });
        }
        return false;
      });
    });
    this.getInfo();
    this.getLanguageCode();
  }

  /**
   * This function returns the currentUserDataState as an observable
   *
   * @returns Observable
   */
  get getCurrentUserDataState() {
    return this.currentUserDataState.asObservable();
  }

  get getCurrentBalances() {
    return this.currentUserBalances.asObservable();
  }

  setCurrentUserDataState(newCurrentUserDataState: UsersInfo) {
    this.userDataState = newCurrentUserDataState;
    this.currentUserDataState.next(newCurrentUserDataState);
  }

  setCurrentBalances(newCurrentBalances: balances) {
    this.userBalance = newCurrentBalances;
    this.currentUserBalances.next(newCurrentBalances);
  }

  /**
   * dbInit
   */
  dbInit() {
    this.storage.create();
    this.storage.defineDriver(CordovaSQLiteDriver);
  }


  /**
   * It gets the access token from the local storage, and then returns a new HttpHeader object with the
   * access token and other required headers
   *
   *
   * @returns a new HttpHeaders object.
   */
  async getAuthHeader() {
    await this.getAuthenticatedToken();
    return new HttpHeaders()
      // .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.accessToken.token}`);
  }

  async genericRequestWithOutAuthHeader(method: 'post' | 'put' | 'patch' = 'post', url: string, body: any): Promise<any> {
    const headers = this.headers;
    return await new Promise((resolve, reject) => {
      this
        .http[method](url, body, { headers, observe: 'response' })
        .subscribe((data) => {
          resolve(data.body);
        }, (error: AnyInfo) => {
          reject(error);
        });
    });
  }

  async postRequestWithOutAuthHeader(url: string, body: any): Promise<any> {
    return this.genericRequestWithOutAuthHeader('post', url, body);
  }

  async patchRequestWithOutAuthHeader(url: string, body: any): Promise<any> {
    return this.genericRequestWithOutAuthHeader('patch', url, body);
  }

  async putRequestWithOutAuthHeader(url: string, body: any): Promise<any> {
    return this.genericRequestWithOutAuthHeader('put', url, body);
  }

  async genericRequestWithAuthHeader(method: 'post' | 'put' | 'patch' = 'post', url: string, body: any): Promise<any> {
    let headers: HttpHeaders;
    await this.getAuthHeader().then((data) => {
      headers = data;
    });
    return await new Promise((resolve, reject) => {
      this
        .http[method](url, body, { headers, observe: 'response' })
        .subscribe((data) => {
          resolve(data.body);
        }, (error: AnyInfo) => {
          reject(error);
        });
    });
  }

  async postRequestWithAuthHeader(url: string, param: any): Promise<any> {
    return this.genericRequestWithAuthHeader('post', url, param);
  }

  async patchRequestWithAuthHeader(url: string, body: any): Promise<any> {
    return this.genericRequestWithAuthHeader('patch', url, body);
  }

  async putRequestWithAuthHeader(url: string, body: any): Promise<any> {
    return this.genericRequestWithAuthHeader('put', url, body);
  }

  async deleteRequestWithAuthHeader(url: string): Promise<any> {
    let headers: HttpHeaders;
    await this.getAuthHeader().then((data) => {
      headers = data;
    });
    return await new Promise((resolve, reject) => {
      this
        .http
        .delete(url, { headers, observe: 'response' })
        .subscribe((data) => {
          resolve(data.body);
        }, (error: AnyInfo) => {
          reject(error);
        });
    });
  }

  async getRequestFromServeWithLocalFallback(url: string, localFallBackName = null) {
    const checkNetwork = await this.networkService.getCurrentNetworkStatus();
    if (this.calledAPIs.includes(localFallBackName) && !checkNetwork.connected) {
      return await new Promise((resolve, reject) => {
        this.getLocalData(localFallBackName)
          .then((data) => {
            resolve(data);
            this.getRequestFromServe(url, localFallBackName);
          }).catch((error) => {
            console.log(error);
            return this.getRequestFromServe(url, localFallBackName);
          });
      });
    } else {
      return this.getRequestFromServe(url, localFallBackName);
    }
  }

  pushToArrayIfNotExists(array: any[], item: any) {
    if (!array.includes(item)) {
      array.push(item);
    }
  }

  async getRequestFromServe(url: string, localFallBackName = null): Promise<any> {
    let headers: HttpHeaders;
    await this.getAuthHeader().then((data) => {
      headers = data;
    });
    return await new Promise((resolve, reject) => {
      this
        .http
        .get(url, { headers, observe: 'response' })
        .subscribe(
          (data) => {
            resolve(data.body);
            if (localFallBackName) {
              this.storeApiData(data.body, localFallBackName);
            }
          },
          (error: AnyInfo) => {
            if (localFallBackName) {
              this.getLocalData(localFallBackName)
                .then((data) => {
                  data.status ? resolve(data) : reject(error);
                })
                .catch(() => {
                  reject(error);
                });
            } else {
              reject(error);
            };
          });
    });
  }

  /**
   * Store Data to local
   */
  storeApiData(data: any, name: string) {
    if (data.status) {
      this.pushToArrayIfNotExists(this.calledAPIs, name);
      return this.storeLocalData(name, data);
    }
    return new Promise((resolve) => resolve(true));
  }

  async getLoader() {
    return await this.loadingController.create({
      spinner: null,
      message: '<img id="roller" src="assets/icon/loader.svg">',
      translucent: true,
      cssClass: 'custom-loading'
    });
  }

  /**
   * It gets the current domain from local storage and sets it to the currentDomain variable
   */
  async getCurrentDomain() {
    await this.storage.get(CURRENT_DOMAIN).then((data) => {
      console.log('currentDomain:', data);
      this.currentDomain = data;
    }).catch((err) => {
      console.log(err);
      this.currentDomain = 'user';
    });

    return this.currentDomain;
  }



  /**
   * It gets the current domain from local storage and sets it to the currentDomain variable
   */
  async setCurrentDomain(domain: string = null) {
    if (domain) {
      this.storage.set(CURRENT_DOMAIN, domain);
      this.currentDomain = domain;
    } else {
      this.getCurrentDomain();
    }
  }

  /**
   * It returns the subdomain of the current URL, if the URL is a member profile
   *
   * @param c - The URL of the page you're on.
   *
   * @returns The subdomain of the current URL.
   */
  async getActiveSubdomain(c: string, prefix: string = 'member') {
    if (c.includes(`/${prefix}/`)) {
      const d = c.substring(prefix.length + 2);
      const e = d.indexOf('/') !== -1 ? d.indexOf('/') : d.length;
      return d.substring(0, e);
    }
  }

  getActiveTab(c: string, prefix: string = 'member') {
    if (c.includes(`/${prefix}/${this.currentSubUrl}/`)) {
      const d = c.substring((prefix.length + 2) + this.currentSubUrl.length + 1);
      const e = d.indexOf('/') !== -1 ? d.indexOf('/') : d.length;
      return (d.substring(0, e));
    }
  }

  getPublicActiveTab(c: string) {
    if (c.includes('/public/')) {
      const d = c.substr(8);
      const e = d.indexOf('/') !== -1 ? d.indexOf('/') : d.length;
      return d.substr(0, e);
    }
  }


  // Storage & Utility
  /**
   * It gets the device information and returns it.
   *
   * @returns The device information.
   */
  async getInfo(): Promise<DeviceInfo> {
    this.deviceInfo = await Device.getInfo();
    const { uuid } = await Device.getId();
    this.deviceInfo.uuid = uuid;
    return this.deviceInfo;
  }


  /**
   * It returns the language code of the device.
   *
   *
   * @returns The language code of the device.
   */
  async getLanguageCode(): Promise<GetLanguageCodeResult> {
    this.deviceLanguageCodeResult = await Device.getLanguageCode();
    return this.deviceLanguageCodeResult;
  }

  /**
   * It returns a promise that resolves to a boolean value that is the value of the `enablePanicMode`
   * BehaviorSubject
   *
   * @returns A promise that resolves to a boolean value.
   */
  async getPanicMode(): Promise<boolean> {
    return await this.storage.get(ENABLE_PANIC_MODE).then(res => {
      if (res != null) {
        return new Promise((resolve) => {
          this.enablePanicMode.next((res === 'true'));
          resolve(this.enablePanicMode.value);
        });
      } else {
        return new Promise((resolve) => {
          this.setPanicMode(false);
          resolve(false);
        });
      }
    });
  }

  /**
   * It sets the value of the enablePanicMode variable in the local storage.
   *
   * @param enablePanicMode - boolean = !this.enablePanicMode.value
   *
   * @returns A promise that resolves to the result of the storage operation.
   */
  async setPanicMode(enablePanicMode: boolean = !this.enablePanicMode.value): Promise<void> {
    return await this.storage.set(ENABLE_PANIC_MODE, JSON.stringify(enablePanicMode)).then(res => new Promise((resolve) => {
      this.enablePanicMode.next(enablePanicMode);
      resolve(res);
    }));
  }


  /**
   * It gets the value of the key `ENABLE_DARK_MODE` from the storage, and if it's not null, it sets the
   * value of `enableDarkMode` to the value of the key, and returns a promise that resolves to the value
   * of `enableDarkMode`. If the value of the key is null, it sets the value of `enableDarkMode` to
   * false, and returns a promise that resolves to false
   *
   * @returns A promise that resolves to a boolean value.
   */
  async getDarkMode(): Promise<boolean> {
    return await this.storage.get(ENABLE_DARK_MODE).then(res => {
      if (res != null) {
        return new Promise((resolve) => {
          this.enableDarkMode.next((res === 'true'));
          resolve(this.enableDarkMode.value);
        });
      } else {
        return new Promise((resolve) => {
          this.setDarkMode(false);
          resolve(false);
        });
      }
    });
  }

  /**
   * It sets the value of the enableDarkMode variable in the storage, and then updates the enableDarkMode
   * variable in the service
   *
   * @param enableDarkMode - boolean = !this.enableDarkMode.value
   *
   * @returns A promise that resolves to the result of the storage operation.
   */
  async setDarkMode(enableDarkMode: boolean = !this.enableDarkMode.value): Promise<void> {
    return await this.storage.set(ENABLE_DARK_MODE, JSON.stringify(enableDarkMode)).then(res => new Promise((resolve) => {
      this.enableDarkMode.next(enableDarkMode);
      resolve(res);
    }));
  }

  /**
   * It returns a boolean value that is stored in the local storage. If the value is not found, it sets
   * the value to true and returns true
   *
   * @returns A promise that resolves to a boolean value.
   */
  async getEnableFingerprint(): Promise<boolean> {
    return await this.storage.get(ENABLE_FINGERPRINT).then(res => {
      if (res != null) {
        return new Promise((resolve) => {
          this.enableFingerprint.next((res === 'true'));
          resolve(this.enableFingerprint.value);
        });
      } else {
        return new Promise((resolve) => {
          this.setEnableFingerprint(true);
          resolve(true);
        });
      }
    });
  }

  /**
   * It sets the value of the enableFingerprint variable in the local storage.
   *
   * @param enableFingerprint - boolean = !this.enableFingerprint.value
   *
   * @returns A promise that resolves to the result of the storage operation.
   */
  async setEnableFingerprint(enableFingerprint: boolean = !this.enableFingerprint.value): Promise<void> {
    return await this.storage.set(ENABLE_FINGERPRINT, JSON.stringify(enableFingerprint)).then(res => new Promise((resolve) => {
      this.enableFingerprint.next(enableFingerprint);
      resolve(res);
    }));
  }


  /**
   * It returns a promise that resolves to a boolean value
   *
   * @returns A promise that resolves to a boolean value.
   */
  async getShowBalance(): Promise<boolean> {
    return await this.storage.get(ENABLE_SHOW_BALANCE).then(res => {
      if (res != null) {
        return new Promise((resolve) => {
          this.enableShowBalance.next((res === 'true'));
          resolve(this.enableShowBalance.value);
        });
      } else {
        return new Promise((resolve) => {
          this.setShowBalance(true);
          resolve(true);
        });
      }
    });
  }

  /**
   * It sets the value of the enableShowBalance variable in the local storage.
   *
   * @param enableShowBalance - boolean = !this.enableShowBalance.value
   *
   * @returns A promise that resolves to the result of the storage operation.
   */
  async setShowBalance(enableShowBalance: boolean = !this.enableShowBalance.value): Promise<void> {
    return await this.storage.set(ENABLE_SHOW_BALANCE, JSON.stringify(enableShowBalance)).then(res => new Promise((resolve) => {
      this.enableShowBalance.next(enableShowBalance);
      resolve(res);
    }));
  }


  /**
   * It checks if the user has enabled the "Always Logged In" mode, and if not, it sets it to false
   *
   * @returns A promise that resolves to a boolean value.
   */
  async getAlwaysLoggedInMode(): Promise<boolean> {
    return await this.storage.get(ENABLE_ALWAYS_LOGGED_IN_MODE).then(res => {
      if (res != null) {
        return new Promise((resolve) => {
          this.enableAlwaysLoggedInMode.next((res === 'true'));
          resolve(this.enableAlwaysLoggedInMode.value);
        });
      } else {
        return new Promise((resolve) => {
          this.setAlwaysLoggedInMode(false);
          resolve(true);
        });
      }
    });
  }

  /**
   * It sets the value of the enableAlwaysLoggedInMode variable in the storage, and then it updates the
   * enableAlwaysLoggedInMode BehaviorSubject with the new value
   *
   * @param enableAlwaysLoggedInMode - boolean = !this.enableAlwaysLoggedInMode.value
   *
   * @returns A promise that resolves to the result of the storage.set() call.
   */
  async setAlwaysLoggedInMode(enableAlwaysLoggedInMode: boolean = !this.enableAlwaysLoggedInMode.value): Promise<void> {
    return await this.storage.set(ENABLE_ALWAYS_LOGGED_IN_MODE, JSON.stringify(enableAlwaysLoggedInMode))
      .then(res => new Promise((resolve) => {
        this.enableAlwaysLoggedInMode.next(enableAlwaysLoggedInMode);
        resolve(res);
      }));
  }

  /**
   * It gets the value of the key NEW_RELEASE from the storage, if it exists, it returns the value, if it
   * doesn't exist, it returns false
   *
   * @returns A promise that resolves to a boolean value.
   */
  async getNewRelease(): Promise<any> {
    return await this.storage.get(NEW_RELEASE).then(res => {
      if (res != null) {
        return new Promise((resolve) => {
          resolve(JSON.parse(res));
        });
      } else {
        return new Promise((resolve) => {
          resolve(false);
        });
      }
    });
  }

  /**
   * It sets the value of the NEW_RELEASE key in the storage to the value of the newRelease parameter,
   * and then it sets the value of the isNewRelease BehaviorSubject to the value of the newRelease
   * parameter
   *
   * @param newRelease - boolean - This is the new value that you want to set for the new
   * release.
   *
   * @returns A promise that resolves to the result of the storage.set() call.
   */
  async setNewRelease(newRelease: boolean): Promise<void> {
    return await this.storage.set(NEW_RELEASE, JSON.stringify(newRelease)).then(res => new Promise((resolve) => {
      this.isNewRelease.next(newRelease);
      resolve(res);
    }));
  }

  /**
   * It takes an object, encrypts it, and stores it in the device's local storage
   *
   * @param AuthenticatedUser - This is the user object that you get from the API.
   *
   * @returns The promise is being returned.
   */
  async setAuthenticatedUser(AuthenticatedUser: any): Promise<void> {
    // AuthenticatedUser.face_image = null;
    AuthenticatedUser.a_curDate = new Date();
    const AuthenticatedUserData = this.cryptoService.encrypt(JSON.stringify(AuthenticatedUser));
    this.currentUserDataState.next(AuthenticatedUser);
    return await this.storage.set(TOKEN_DATA, AuthenticatedUserData).then(res => new Promise((resolve) => {
      this.userDataState = AuthenticatedUser;
      resolve(res);
    }));
  }

  /**
   * It gets the token from the storage and decrypts it
   *
   * @param [domain=user] - The domain of the token. This is used to differentiate between
   * tokens for different domains.
   *
   * @returns A promise that resolves to a TokenInfo object.
   */
  async getAuthenticatedToken(domain: string = 'user'): Promise<TokenInfo> {
    const KEY = `${domain}_${CURRENT_TOKEN}`;
    console.log(KEY);
    return await this.storage.get(KEY).then(res => {
      if (res != null) {
        return new Promise((resolve, reject) => {
          try {
            const data = JSON.parse(
              this.cryptoService.decrypt(res)
            );
            this.accessToken = data;
            resolve(this.accessToken);
          } catch (e) {
            this.accessToken = { tokenType: 'Bearer', accessToken: '', expires_at: now() };
            const err = {
              e, accessToken: this.accessToken
            };
            this.authenticationState.next(false);
            reject(err);
          }
        });
      } else {
        return new Promise((reject) => {
          const e = `${CURRENT_TOKEN} is undefined`;
          this.accessToken = { tokenType: 'Bearer', accessToken: '', expires_at: now() };
          const err = {
            e, accessToken: this.accessToken
          };
          this.authenticationState.next(false);
          reject(err);
        });
      }
    });
  }

  /**
   * It sets the token in the storage.
   *
   * @param AuthenticatedToken - The token object that you want to store.
   *
   * @param [domain=user] - The domain of the token. This is used to differentiate between
   * different tokens.
   *
   * @returns A promise that resolves to the result of the storage operation.
   */
  async setAuthenticatedToken(tokenInfo: string, domain: string = 'user'): Promise<void> {
    const authenticatedTokenData: TokenInfo = {
      tokenType: 'Bearer',
      accessToken: tokenInfo,
      expires_at: '',
      a_curDate: new Date()
    };
    console.log(authenticatedTokenData);
    const KEY = `${domain}_${CURRENT_TOKEN}`;
    const AuthenticatedTokenData = this.cryptoService.encrypt(JSON.stringify(authenticatedTokenData));
    return await this.storage.set(KEY, AuthenticatedTokenData).then(res => new Promise((resolve) => {
      this.accessToken = authenticatedTokenData;
      resolve(res);
    }));
  }

  /**
   * It gets the user's token data from the local storage, decrypts it, and returns the decrypt data as
   * a promise
   *
   * @returns The user's information.
   */
  async getAuthenticatedUser(): Promise<UsersInfo> {
    return await this.storage.get(TOKEN_DATA).then(res => {
      if (res != null) {
        return new Promise((resolve) => {
          resolve(JSON.parse(this.cryptoService.decrypt(res)));
        });
      }
    });
  }

  /**
   * It returns a promise that resolves to a boolean value
   *
   * @returns A boolean value.
   */
  async isAuthenticated(): Promise<boolean> {
    return this.authenticationState.value;
  }

  /**
   * If the agentState is true, return true, otherwise return false.
   *
   * @returns A promise that resolves to a boolean.
   */
  async isAgent(): Promise<boolean> {
    return this.agentState.value;
  }

  /**
   * It removes the token from storage, sets the always logged in mode to false, sets the authentication
   * state to false, clears the user data state, and clears the local storage
   *
   * @returns The promise is being returned.
   */
  async logout(): Promise<void> {
    return await this.storage.remove(TOKEN_DATA).then(res => {
      // this.logoutUser();
      this.enableAlwaysLoggedInMode.next(false);
      this.setAlwaysLoggedInMode(false);
      this.closeAllModals();
      this.clearLocalStorage();
      this.authenticationState.next(false);
    });
  }

  /**
   * It takes a name and data as parameters, encrypts the data, and then stores it in the local storage
   *
   * @param name - The name of the data you want to store.
   *
   * @param data - The data you want to store.
   *
   * @returns The promise of the storage set function.
   */
  async storeLocalData(name: string, data: any) {
    const localData = this.cryptoService.encrypt(JSON.stringify(data));
    return this.storage.set(`local_${name}`, localData);
  }

  /**
   * It gets the local data from the storage, decrypts it, and returns it as a promise
   *
   * @param name - The name of the data you want to get.
   *
   * @returns A promise that resolves to the value of the key in the storage.
   */
  async getLocalData(name: string): Promise<any> {
    return await this.storage.get(`local_${name}`).then(res => new Promise((resolve, reject) => {
      if (res != null) {
        const data = JSON.parse(
          this.cryptoService.decrypt(res)
        );
        if (data.status) {
          resolve(data);
        } else {
          this.storage.remove(`local_${name}`);
          reject(data);
        }
      } else {
        reject({ error: 'unable to get data', message: 'data is null' });
      }
    }));
  }

  /**
   * It stores data in the local storage
   *
   * @param name - The name of the data you want to store.
   *
   * @param data - The data to be stored.
   *
   * @returns A promise.
   */
  async storeLocalPlainData(name: string, data: any) {
    const KEY = `local_plain_${name}`;
    const localData = this.cryptoService.encrypt(JSON.stringify(data));
    return this.storage.set(KEY, localData);
  }

  /**
   * It gets the local plain data from the storage.
   *
   * @param name - The name of the data you want to get.
   *
   * @returns A promise that resolves to the value of the key.
   */
  async getLocalPlainData(name: string): Promise<any> {
    const KEY = `local_plain_${name}`;
    return await this.storage.get(KEY).then(res => new Promise((resolve, reject) => {
      if (res != null) {
        const data = JSON.parse(
          this.cryptoService.decrypt(res)
        );
        resolve(data);
      } else {
        reject(null);
      }
    }));
  }

  /**
   * It gets the user from the storage, decrypts it, and returns it
   *
   * @param GUARD - The guard you want to get the user from.
   *
   * @returns The user's information.
   */
  async getAuthenticatedPersistentUser(GUARD: string): Promise<UsersInfo> {
    const KEY = `${PERSISTENT_USER}_${GUARD}`;
    return await this.storage.get(KEY).then(res => {
      if (res != null) {
        return new Promise(resolve => {
          resolve(JSON.parse(this.cryptoService.decrypt(res)));
        });
      }
    });
  }

  /**
   * It takes a user object and a guard string, encrypts the user object, and saves it to the device's
   * storage
   *
   * @param PersistentUser - This is the user object that you want to store.
   *
   * @param GUARD - The guard you want to set the user for.
   *
   * @returns A promise that resolves to the result of the storage operation.
   */
  async setAuthenticatedPersistentUser(PersistentUser: any, GUARD: string): Promise<void> {
    // // console.log(PersistentUser);
    PersistentUser.user_type = GUARD;
    PersistentUser.a_curDate = new Date();
    const KEY = `${PERSISTENT_USER}_${GUARD}`;
    const PersistentUserData = this.cryptoService.encrypt(JSON.stringify(PersistentUser));
    return await this.storage.set(KEY, PersistentUserData)
      .then(res => new Promise(resolve => {
        resolve(res);
      }));
  }

  /**
   * It gets the user's plan from the storage and decrypts it
   *
   * @returns The user's plan.
   */
  async getAuthenticatedUserPlan(): Promise<UsersInfo> {
    return await this.storage.get(PERSISTENT_USER_PLAN).then(res => {
      if (res != null) {
        return new Promise(resolve => {
          resolve(JSON.parse(this.cryptoService.decrypt(res)));
        });
      }
    });
  }

  /**
   * It takes a user plan object, encrypts it, and saves it to the device's storage
   *
   * @param PersistentUserPlan - any
   */
  async setAuthenticatedUserPlan(PersistentUserPlan: any): Promise<void> {
    // // console.log(PersistentUser);
    PersistentUserPlan.a_curDate = new Date();
    const PersistentUserPlanData = this.cryptoService.encrypt(JSON.stringify(PersistentUserPlan));
    return await this.storage.set(PERSISTENT_USER_PLAN, PersistentUserPlanData)
      .then(res => new Promise(resolve => {
        resolve(res);
      }));
  }

  /**
   * It checks if the user has a fingerprint registered.
   *
   * @param GUARD - The guard you want to use.
   *
   * @returns A boolean value.
   */
  async fingerprintAIO(GUARD: string) {
    const KEY = `${PERSISTENT_USER}_${GUARD}`;
    return await this.storage.get(KEY).then(res => {
      if (res.value != null) {
        this.storage.set(TOKEN_DATA, res).then(() => this.authenticationState.next(true));
        return (true);
      } else {
        this.authenticationState.next(false);
        return (false);
      }
    });
  }

  /**
   * It checks if the user is logged in, and if so, it sets the authentication state to true
   *
   * @param GUARD - The guard you want to use.
   *
   * @returns The authentication state is being returned.
   */
  async silentLogin(GUARD: string): Promise<void> {
    const KEY = `${PERSISTENT_USER}_${GUARD}`;
    return await this.storage.get(KEY).then(res => {
      if (res != null) {
        return this.storage.set(TOKEN_DATA, res).then(() => this.authenticationState.next(true));
      } else {
        return this.authenticationState.next(false);
      }
    });
  }

  /**
   * It checks if the token is stored in the local storage, if it is, it decrypts it and returns true, if
   * not, it returns false
   *
   * @returns A boolean value.
   */
  async checkToken(): Promise<boolean> {
    return await this.storage.get(TOKEN_DATA).then(res => {
      if (res != null) {
        this.authenticationState.next(true);
        return true;
      } else {
        this.authenticationState.next(false);
        return false;
      }
    });
  }

  /**
   * It checks if the current token is valid and returns a boolean value
   *
   * @returns A boolean value.
   */
  async checkCurrentToken(): Promise<boolean> {
    return await this.storage.get(CURRENT_TOKEN).then(res => {
      if (res != null) {
        this.authenticationState.next(true);
        return true;
      } else {
        this.authenticationState.next(false);
        return false;
      }
    });
  }

  /**
   * It removes the token data from the local storage and sets the authentication state to false
   */

  async clearLocalStorage(): Promise<void> {
    this.storage.remove(TOKEN_DATA);
    // this.storage.remove(CURRENT_TOKEN);
    // this.storage.remove(PERSISTENT_USER);
    // return await this.storage.clear().then(() => {
    this.accessToken = {};
    this.authenticationState.next(false);
    this.authenticationState.next(false);
    // });
  }

  /**
   * It removes the token data, the current token, and the persistent user from storage, then clears the
   * storage, and finally sets the access token to an empty object and sets the authentication state to
   * false
   *
   * @returns The accessToken is being returned.
   */
  async forceClearLocalStorage(): Promise<void> {
    this.storage.remove(TOKEN_DATA);
    this.storage.remove(CURRENT_TOKEN);
    this.storage.remove(PERSISTENT_USER);
    return await this.storage.clear().then(() => {
      this.accessToken = {};
      this.authenticationState.next(false);
      this.authenticationState.next(false);
    });
  }
  /**
   * It creates an alert with a custom HTML message
   *
   * @param _message - The message to be displayed in the alert.
   *
   * @param [_header=null] - The header of the alert.
   *
   * @param [img=info] - The image to be displayed in the alert.
   *
   * @returns An alert object
   */
  async errorAlert(_message: string, _header = 'Oops!', img = null): Promise<HTMLIonAlertElement> {
    let html = '';
    html += img ? `<img src="assets/alert/${img}.svg">` : '';
    html += _header ? `<h1 class="header ion-margin-y-12">${_header}</h1>` : '<h1></h1>';
    html += `<div class="message">${_message}</div>`;
    const alert = await this.alertController.create({
      // header: 'Status',
      // subHeader: 'Subtitle',
      cssClass: 'my-alert',
      mode: 'ios',
      message: html,
      buttons: [{
        text: 'Okay',
        role: 'cancel',
        cssClass: 'danger only',
        handler: () => {
          console.log();
        }
      }],
      backdropDismiss: false
    });
    await alert.present();
    return alert;
  }

  /**
   * This function creates an alert with a header and a message, and returns the alert
   *
   * @param _header - The header of the alert
   *
   * @param _message - The message you want to display in the alert.
   *
   * @returns The alert element.
   */
  async errorAlertWithHeader(_header: string, _message: string): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      // header: 'Status',
      header: _header,
      // subHeader: 'Subtitle',
      cssClass: 'my-alert',
      mode: 'ios',
      message: _message,
      buttons: [{
        text: 'Okay',
        role: 'cancel',
        cssClass: 'success only',
        handler: () => {
        }
      }],
      backdropDismiss: false
    });
    await alert.present();
    return alert;
  }

  /**
   * This function creates a toast message with a primary color, an information icon, and a duration of 2
   * seconds
   *
   * @param _message - string - The message to be displayed in the toast
   *
   * @returns The toast element.
   */
  async successPop(_message: string): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create({
      message: _message,
      color: 'primary',
      icon: 'information-circle',
      position: 'top',
      duration: 2000
    });
    await toast.present();
    return toast;
  }

  /**
   * It creates an alert with a custom HTML message, and returns the alert object
   *
   * @param _message - string - The message to be displayed in the alert.
   *
   * @param [_header=null] - The header of the alert.
   *
   * @param  [img=info] - 'info' | 'success' | 'error' = 'info'
   * @param [buttonTitle=Okay] - The text that will be displayed on the button.
   *
   * @returns An alert
   */
  async successAlert(_message: string, _header = null, img: 'info' | 'success' | 'error' = 'success', buttonTitle = 'Okay'): Promise<HTMLIonAlertElement> {
    let html = '';
    html += img ? `<img src="assets/alert/${img}.svg">` : '';
    html += _header ? `<h1 class="header ion-margin-y-12 ion-color-${img}">${_header}</h1>` : '<h1></h1>';
    html += `<div class="message">${_message}</div>`;
    const alert = await this.alertController.create({
      cssClass: 'my-alert',
      mode: 'ios',
      message: html,
      buttons: [
        {
          text: buttonTitle,
          role: 'cancel',
          cssClass: 'success only',
          handler: () => {
            console.log('close alert');
          }
        }
      ],
      // backdropDismiss: false
    });

    await alert.present();
    return alert;
  }

  /**
   * It creates an alert with a custom HTML message, and returns the alert object
   *
   * @param _message - string - The message to be displayed in the alert.
   *
   * @param [_header=null] - The header of the alert.
   *
   * @param  [img=info] - 'info' | 'success' | 'error' = 'info'
   * @param [buttonTitle=Okay] - The text that will be displayed on the button.
   *
   * @returns An alert
   */
  async corporateAlert(
    _message: string,
    _header = null,
    _title = null,
    headerIcon: string,
    imgUrl: string = null,
    redirectUrl: string = null,
    btnCssClass: 'success' | 'error' | 'warning',
    buttonTitle = 'OK',
  ): Promise<HTMLIonAlertElement> {
    let html = '';
    html += _header ? `<div class="header ion-align-items-center ion-margin-top-56">
      <div class="ion-margin-right-14">
        <img src="assets/alert/${headerIcon}" alt="" />
      </div>
      <h1>${_header}</h1></div>` : '';
    html += imgUrl ? `<div class="alert-icon ion-align-items-center ${!_header ? 'ion-margin-top-51' : ''}">
      <img src="assets/alert/${imgUrl}"></div>` : '';
    html += `${_title ? `<div class="alert-title ion-margin-top-30">${_title}</div>` : ``}`;
    html += `<div class="message ion-margin-top-32 ion-margin-bottom-53">${_message}</div>`;
    const alert = await this.alertController.create({
      cssClass: 'corporate-alert',
      message: html,
      buttons: [
        {
          text: buttonTitle,
          role: 'cancel',
          cssClass: `corporate-alert-btn ${btnCssClass}-btn`,
          handler: () => {
            if (redirectUrl) {
              this.navController.navigateRoot(redirectUrl);
            }
          }
        }
      ],
      // backdropDismiss: false
    });

    await alert.present();
    return alert;
  }

  /**
   * It creates an alert with a header, a message, and a button that says "Okay"
   *
   * @param _header - The title of the alert
   *
   * @param _message - The message you want to display in the alert.
   *
   * @returns The alert element
   */
  async successAlertWithHeader(_header: string, _message: string): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      header: _header,
      // subHeader: 'Subtitle',
      cssClass: 'my-alert',
      mode: 'ios',
      message: _message,
      buttons: [
        {
          text: 'Okay',
          role: 'cancel',
          cssClass: 'success only',
          handler: () => {
          }
        }
      ],
      // backdropDismiss: false
    });

    await alert.present();
    return alert;
  }

  /**
   * It creates a toast message with the given message, position, color, duration, icon, cssClass, and
   * buttons.
   *
   * @param _message - The message you want to display in the toast.
   *
   * @param [position=top] - 'top' | 'bottom' | 'middle' = 'top'
   *
   * @param [color=success] - The color of the toast.
   *
   * @param [duration=1000] - The duration of the toast in milliseconds. Default: 2000
   *
   * @returns The toast element
   */
  async successToast(
    _message: string,
    position: 'top' | 'bottom' | 'middle' = 'top',
    color: string = 'success', duration: number = 3500): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create({
      message: `<div>${_message}</div>`,
      position,
      color,
      duration,
      // icon: 'information-circle',
      cssClass: 'customToast customToast-success',
      buttons: [
        {
          side: 'start',
          icon: 'assets/toast/success.svg',
          cssClass: 'customToast-icon',
          text: '',
          handler: () => {
            console.log('Cart Button Clicked');
          }
        },
        // {
        //   side: 'end',
        //   icon: 'assets/toast/close.svg',
        //   text: '',
        //   role: 'cancel',
        //   handler: () => {
        //     console.log('Close clicked');
        //   }
        // }
      ]
    });
    await toast.present();
    return toast;
  }

  /**
   * It creates a toast with a message, position, color, duration, cssClass, and buttons
   *
   * @param _message - The message you want to display in the toast.
   *
   * @param [position=top] - 'top' | 'bottom' | 'middle' = 'top'
   *
   * @param [color=danger] - The color of the toast.
   *
   * @param [duration=2000] - The duration in milliseconds to show the toast. Default: 2000
   *
   * @returns The toast element
   */
  async errorToast(
    _message: string,
    position: 'top' | 'bottom' | 'middle' = 'top',
    color: string = 'danger', duration: number = 3500): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create({
      message: `<div>${_message}</div>`,
      position,
      color,
      duration,
      cssClass: 'customToast customToast-error',
      buttons: [
        {
          side: 'start',
          icon: 'assets/toast/danger.svg',
          cssClass: 'customToast-icon',
          text: '',
          handler: () => {
            console.log('Cart Button Clicked');
          }
        },
      ]
    });
    await toast.present();
    return toast;
  }

  /**
   * It creates an alert with the message "Coming soon" and returns the alert.
   *
   * @param [_message=Coming soon] - string = 'Coming soon'
   *
   * @returns The alert element
   */
  async comingSoon(_message: string = 'Feature is in progress'): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      // header: 'Feature is in the works',
      // subHeader: 'Subtitle',
      cssClass: 'my-alert',
      mode: 'ios',
      message: `${_message}`,
      buttons: [
        {
          text: 'Okay',
          role: 'cancel',
          cssClass: 'success only',
          handler: () => {
          }
        }
      ],
      backdropDismiss: false
    });

    await alert.present();
    return alert;
  }

  /**
   * It prompts the user to rate the app after a certain number of uses
   */
  showRatePrompt() {
    this.appRate.setPreferences({
      displayAppName: 'Microvest',
      promptAgainForEachNewVersion: true,
      // usesUntilPrompt: 5,
      storeAppURL: {
        ios: environment.appInfo.ios || '1316416568',
        android: `market://details?id=${environment.appInfo.android || 'com.microvest.app'}`
      },
      customLocale: {
        title: `How would you rate your experience?`,
        // eslint-disable-next-line max-len
        message: `If you enjoy using Microvest, would you mind taking a moment to rate it? It won't take more than a minute. Thanks for your support!`,
        cancelButtonLabel: 'No, Thanks',
        laterButtonLabel: 'Remind Me Later',
        rateButtonLabel: 'Rate It Now'
      }
    });
    this.appRate.promptForRating(true);
  }


  /**
   * It creates a toast with a message and a button that reloads the page
   *
   * @param version - The version of the app that is currently installed on the user's device.
   *
   * @returns The toast is being returned.
   */
  async updateToast(version: any) {
    const toast = await this.toastController.create({
      header: 'New Update available!',
      message: 'Close all tabs for the webapp to load the latest update',
      position: 'bottom',
      color: 'light',
      cssClass: 'toast web-toast',
      buttons: [{
        cssClass: 'toastReloadCancel',
        text: 'OKAY',
        role: 'Cancel',
        handler: () => {
          if (environment.isPwa) {
            this.setVersion(this.currentVersion || version);
            window.location.reload();
            document.location.reload();
          } else {
            this.openWeb(this.oneLink);
          }
        }
      }
      ]
    });
    return toast.present();
  }

  /**
   * "Open the browser to the given URL."
   *
   * The first line of the function is the async keyword. This tells the function that it will be
   * asynchronous
   *
   * @param url - The URL to open in the browser.
   */
  async openWeb(url: any) {
    await Browser.open({ url });
  }

  /**
   * It gets the version from storage, if it's not there, it sets it to the environment version and
   * returns that
   *
   * @returns A promise that resolves to a string.
   */
  async getVersion(): Promise<string> {
    return await this.storage.get(VERSION).then(res => {
      if (res != null) {
        return new Promise((resolve) => {
          this.version.next(res);
          resolve(res);
        });
      } else {
        return new Promise((resolve) => {
          this.setVersion(environment.appVerCode);
          resolve(environment.appVerCode);
        });
      }
    });
  }

  /**
   * It sets the version of the app.
   *
   * @param version - string = this.currentVersion
   *
   * @returns A promise that resolves to the result of the storage set operation.
   */
  async setVersion(version: string = this.currentVersion): Promise<void> {
    return await this.storage.set(VERSION, (version)).then(res => new Promise((resolve) => {
      this.version.next(version);
      resolve(res);
    }));
  }

  /**
   * If the modal instance is not in the array, add it. If it is, do nothing
   *
   * @param x - any - This is the modal instance that is being passed in.
   */
  storeModal(x: any) {
    this.modalsInst.indexOf(x) === -1 ? this.modalsInst.push(x) : (console.log('This item already exists'));
    console.log(this.modalsInst);
  }

  /**
   * It loops through the array of modals, and calls the dismiss() function on each one
   */
  closeAllModals() {
    this.modalsInst.forEach(element => {
      element.dismiss();
    });
    this.modalsInst = [];
    console.log(this.modalsInst);
  }

  /**
   * It removes the modal from the modalsInst array
   *
   * @param x - any - This is the modal that you want to close.
   */
  removeModal(x: any) {
    const index = this.modalsInst.indexOf(x);
    if (index > -1) {
      this.modalsInst[index].dismiss();
      this.modalsInst.splice(index, 1);
    }
    console.log(this.modalsInst);
  }

  async getMockData(name: string) {
    return this.appMock.getMockData(name);
  }


  async openComponent(component: ComponentRef, componentProps: ComponentProps = {}, size = 45,
    hasBreakPoints = true,
    canDismiss: boolean | (() => Promise<boolean>) = true) {
    const sizeChecker = size !== 100;
    const breakPoints = sizeChecker && hasBreakPoints ? {
      initialBreakpoint: 1,
      breakpoints: [0, 0.6, 1],
    } : {};
    const modal = await this.modalController.create({
      component,
      backdropDismiss: true,
      canDismiss,
      componentProps,
      animated: false,
      ...breakPoints,
      mode: 'ios',
      cssClass: !sizeChecker
        ? this.helperMethods.getModalStyleFull(this.screenSizeService.widthSize.value)
        : this.helperMethods.getModalDynamicStyle(this.screenSizeService.widthSize.value, size) + ' has-breakpoints'
    });
    await modal.present();
    return modal;
  }

}
