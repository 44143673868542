/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, TitleStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedCommonComponentsModule } from '@app-components/common/shared-common-components.module';
import { SharedCorporateComponentsModule } from '@app-components/corporate/shared-corporate-components.module';
import { SharedLayoutComponentsModule } from '@app-components/layout/shared-layout-components.module';
import { SharedMerchantComponentsModule } from '@app-components/merchant/shared-merchant-components.module';
import { SharedComponentsModule } from '@app-components/shared-components.module';
import { SharedDetailsComponentsModule } from '@app-components/user/details/shared-details-components.module';
import { SharedItemsComponentsModule } from '@app-components/user/items/shared-items-components.module';
import { SharedKreadeetComponentsModule } from '@app-components/user/kreadeet/shared-kreadeet-components.module';
import { SharedUserProfileComponentsModule } from '@app-components/user/profile/shared-profile-components.module';
import { SharedUtilityBillComponentsModule } from '@app-components/user/utility-bill/shared-utility-bill-components.module';
import { SharedDirectivesModule } from '@app-directives/shared-directives.module';
import { environment } from '@app-environments/environment';
import { ComponentPickerService } from '@app-services/component-picker/component-picker.service';
import { CustomTitleStrategy } from '@app-services/custom-title-strategy/custom-title-strategy.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Angular4PaystackModule } from 'angular4-paystack';
import { QRCodeModule } from 'angularx-qrcode';
import { IonicSelectableModule } from 'ionic-selectable';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SwiperModule } from 'swiper/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './Interceptor/auth-interceptor';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    IonicStorageModule.forRoot({
      name: '__kreadeetAppDB',
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    AppRoutingModule,
    NgPipesModule,
    MomentModule,
    FormsModule,
    SwiperModule,
    SharedCommonComponentsModule,
    SharedUtilityBillComponentsModule,
    SharedItemsComponentsModule,
    SharedDetailsComponentsModule,
    SharedComponentsModule,
    SharedKreadeetComponentsModule,
    SharedLayoutComponentsModule,
    SharedMerchantComponentsModule,
    SharedUserProfileComponentsModule,
    SharedCorporateComponentsModule,
    SharedDirectivesModule,
    NgChartsModule,
    NgIdleKeepaliveModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    IonicSelectableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    Angular4PaystackModule.forRoot(environment.paystack.publicKey),
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    OneSignal,
    AppRate,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    LaunchNavigator,
    InAppBrowser,
    AndroidPermissions,
    ComponentPickerService,
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
