import { QrViewComponent } from './qr-view.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { NgPipesModule } from 'ngx-pipes';
import { MomentModule } from 'ngx-moment';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    QRCodeModule
  ],
  declarations: [QrViewComponent]
})

export class QrViewComponentModule { }
