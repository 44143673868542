import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-card-item',
  templateUrl: './action-card-item.component.html',
  styleUrls: ['./action-card-item.component.scss'],
})
export class ActionCardItemComponent implements OnInit {

  @Input() icon = 'assets/img/loan.svg';
  @Input() title = 'Action Card Item';
  @Input() data: {
    icon?: string;
    title?: string;
  } = {};
  @Input() disabled = false;

  constructor() { }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'ActionCardItemComponent';
  }

  ngOnInit() { }

}
