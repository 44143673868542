/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, IonInput, LoadingController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
})
export class PaymentInfoComponent implements OnInit {



  @Input() data: any = {};
  @Input() paymentToken: any = '';
  @Input() switched_to_corporate = 0;
  @ViewChild('pin1', { read: IonInput }) pin1: IonInput;
  @ViewChild('pin2', { read: IonInput }) pin2: IonInput;
  @ViewChild('pin3', { read: IonInput }) pin3: IonInput;
  @ViewChild('pin4', { read: IonInput }) pin4: IonInput;

  isDesktop: boolean;
  isPwa: any;

  param: any = {
    pin: null,
  };
  pin: any = [null, null, null, null];

  constructor(// tslint:disable: align
    public navController: NavController,
    public loadingController: LoadingController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    public alertController: AlertController) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'PaymentInfoComponent';
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.navController.back();
  }

  ngOnInit() {
    console.log(this.data, this.paymentToken);
  }

  checkOTPInput(event: any, index: number) {
    const inputFields = [this.pin1, this.pin2, this.pin3, this.pin4];
    if (event.detail.value.length > 0 && index === 3) {
      this.param.pin = this.pin.join('');
      console.log(this.param);
      return;
    }
    if (event.detail.value.length > 0 && index !== 3) {
      this.pin[index] = event.detail.value.toUpperCase();
      setTimeout(() => {
        inputFields[index + 1].setFocus();
      }, 50);
    } else {
      for (index; index < this.pin.length; index++) {
        this.pin[index] = null;
      }
    }
  }

  makePaymentHandler() {
    if (this.switched_to_corporate) {
      this.corporateFinalizePayment();
      return;
    }
    this.makePayment();
  }

  async makePayment() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    console.log(this.paymentToken);
    this.param.paymentToken = this.paymentToken;
    await this.helperMethods.promiseTimeout
      (this.apiService.tokenFinalize(this.param))
      .then(async (res: ApiGenericResponseInfo) => {
        console.log(res);
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message);
        } else {
          this.param.pin = null;
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse) => {
        console.log(err);
        await loading.dismiss();
        this.param.pin = null;
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  async corporateFinalizePayment() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    this.param.paymentToken = this.paymentToken;
    await this.helperMethods.promiseTimeout
      (this.apiService.tokenCorporateFinalize(this.param))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message);
        } else {
          this.param.pin = null;
          this.apiService.errorAlert(res.message, res?.header);
        }
        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse) => {
        await loading.dismiss();
        this.param.pin = null;
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }


}
