
import { Component, OnInit } from '@angular/core';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-branch-form',
  templateUrl: './branch-form.component.html',
  styleUrls: ['./branch-form.component.scss'],
})
export class BranchFormComponent implements OnInit {
  /* eslint-disable @typescript-eslint/naming-convention */
  corporateBranch = {
    phone: null,
    branch_name: null,
    email: null,
    password: null,
    office_address: null,
    staff_size: null
  };

  constructor(
    private modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService
  ) { }

  ngOnInit() { }

  dismissModal(data?) {
    this.modalController.dismiss(data);
  }

  async branchFormHandler() {

    const loading = await this.apiService.getLoader();
    await loading.present();

    await this.helperMethods.promiseTimeout(this.apiService.corporateCreateBranch(this.corporateBranch))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.apiService.corporateAlert(
            'You have successfully added a branch',
            'Successful',
            null,
            'corporate-alert-success.png',
            'mr-green-success.png',
            null,
            'success',
          );
          this.dismissModal({ isNew: true });
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }

        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });

  }

}
