/* eslint-disable guard-for-in */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@app-environments/environment';
import { MerchantUsersInfo } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { DataService } from '@app-services/data/data.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-smile-id',
  templateUrl: './smile-id.component.html',
  styleUrls: ['./smile-id.component.scss'],
})
export class SmileIdComponent implements OnInit {

  @Input() currentUser: MerchantUsersInfo = {};
  currentImage: any = null;
  isPwa: any;
  asGenerated = false;
  user: any;
  token = '';
  amountValue: string | number = '0.00';
  data: any = {
    amount: 0,
    phone: null
  };
  isDesktop: boolean;
  formData: FormData = new FormData();

  constructor(
    private router: Router,
    public loadingController: LoadingController,
    public navController: NavController,
    private dataService: DataService,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService,
    private cryptoService: CryptoService,
    public alertController: AlertController) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'SmileIdComponent';
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.navController.back();
  }

  ngOnInit() {
    // window.screen.orientation.
    // window.screen.orientation.lock('portrait');
  }

  smileID() {
    this.choosePicture();
  }

  formatCurrencyOnBlur(event) {
    const uy = this.helperMethods.formatAmount(event.detail.value.toString(), '');
    this.data.amount = event.detail.value.split(',').join('');
    this.amountValue = (uy === 'NaN') ? 0 : uy;
  }

  formatCurrencyOnFocus() {
    const dValue = this.data.amount === 0 ? 0 : parseFloat(this.data.amount.toString()).toFixed(2).toString();
    this.amountValue = (dValue === 'NaN') ? 0 : dValue;
  }

  checkValue(event = null) {
    const uy = this.helperMethods.formatAmount(event.detail.value.toString(), '').split(',').join('');
    this.data.amount = (uy === 'NaN') ? 0 : parseFloat(uy);
    if (event) {
      this.formatCurrencyOnBlur(event);
    }
  }

  generateToken() {
    this.getUserProfileByPhone(this.data).then((data) => {
      // this.asGenerated = !this.asGenerated;
    });
  }

  async choosePicture() {
    this.setDataForm();
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera
    });
    console.log('image: ', image);
    this.data.image = image.base64String;
    const blobData = this.helperMethods.b64toBlob(image.base64String, `image/${image.format}`);
    const date = new Date(); const time = date.getTime(); const fileName = `${time}`;
    const file: File = this.helperMethods.blobToFile(blobData, `${fileName}.${image.format}`, `image/${image.format}`);
    console.log(file);
    if (file.size <= 10351930) {
      this.currentImage = {
        base64: `data:image/${image.format};base64,` + image.base64String,
        base64String: image.base64String,
        blob: blobData,
        fileName,
        format: image.format,
        file
      };
      // this.uploadImage(blobData, fileName, image.format);
      this.uploadImageFile(file);
    } else {
      this.apiService.errorAlert('Your Image should not be more that 10M');
    }
  }

  async uploadImageFile(file: File) {
  }

  setDataForm() {
    // tslint:disable-next-line:forin
    for (const i in this.data) {
      this.formData.set(i, this.data[i]);
    }
  }

  async getUserProfileByPhone(data) {

  }


}

