import { Component, OnInit } from '@angular/core';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-corporate-public-layout',
  templateUrl: './corporate-public-layout.component.html',
  styleUrls: ['./corporate-public-layout.component.scss'],
})
export class CorporatePublicLayoutComponent implements OnInit {
  isDesktop: boolean;

  constructor(
    public screenSizeService: ScreenSizeService,
    public navController: NavController,
  ) {
    this.screenSizeService.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit() { }

}
