import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import SwiperCore, {
  Keyboard,
  Pagination,
  Scrollbar,
  SwiperOptions,
  Zoom
} from 'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-registration-side',
  templateUrl: './registration-side.component.html',
  styleUrls: ['./registration-side.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class RegistrationSideComponent implements OnInit {
  @Input() currentStage: number;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  sliderConfig: SwiperOptions = {
    autoplay: true,
    initialSlide: 0,
    pagination: true,
  };
  stepperArr = [
    {
      title: 'Company Information',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
    {
      title: 'More Company Information',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
    {
      title: 'OTP Verification',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    },
  ];

  constructor() {}

  ngOnInit() {}

  slideNext() {
    this.swiper.swiperRef.slideNext(100);
  }
  slidePrev() {
    this.swiper.swiperRef.slidePrev(100);
  }
}
