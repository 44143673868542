import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Angular4PaystackModule } from 'angular4-paystack';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SharedDirectivesModule } from 'src/app/directives/shared-directives.module';
import { SwiperModule } from 'swiper/angular';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { ActionConfirmationModalComponent } from './action-confirmation-modal/action-confirmation-modal.component';
import { ActionModalFooterComponent } from './action-modal-footer/action-modal-footer.component';
import { AddServiceProviderComponent } from './add-service-provider/add-service-provider.component';
import { AddStaffProviderComponent } from './add-staff-provider/add-staff-provider.component';
import { AllocateKreadeetComponent } from './allocate-kreadeet/allocate-kreadeet.component';
import { BranchFormComponent } from './branch-form/branch-form.component';
import { CallbackKreadeetComponent } from './callback-kreadeet/callback-kreadeet.component';
import { ClearKreadeetComponent } from './clear-kreadeet/clear-kreadeet.component';
import { CorporateKreadeetDetailsComponent } from './corporate-kreadeet-details/corporate-kreadeet-details.component';
import { CorporateMetricCardComponent } from './corporate-metric-card/corporate-metric-card.component';
import { CorporateModalHeaderComponent } from './corporate-modal-header/corporate-modal-header.component';
import { CorporatePaginationComponent } from './corporate-pagination/corporate-pagination.component';
import { CorporateProvidersComponent } from './corporate-providers/corporate-providers.component';
import { CorporateStaffFormComponent } from './corporate-staff-form/corporate-staff-form.component';
import { CorporateStaffListComponent } from './corporate-staff-list/corporate-staff-list.component';
import { CorporateTransactionDetailsComponent } from './corporate-transaction-details/corporate-transaction-details.component';
import { CorporateTransactionsComponent } from './corporate-transactions/corporate-transactions.component';
import { FundAccountComponent } from './fund-account/fund-account.component';
import { LoginSideComponent } from './login-side/login-side.component';
import { RegistrationSideComponent } from './registration-side/registration-side.component';
import { RequestKreadeetComponent } from './request-kreadeet/request-kreadeet.component';
import { SuggestVendorComponent } from './suggest-vendor/suggest-vendor.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    Angular4PaystackModule,
    NgChartsModule,
    IonicSelectableModule,
    SharedDirectivesModule,
    TranslateModule,
    SwiperModule
  ],
  declarations: [
    RegistrationSideComponent,
    LoginSideComponent,
    CorporateMetricCardComponent,
    AccountDetailsComponent,
    RequestKreadeetComponent,
    CorporateModalHeaderComponent,
    AddServiceProviderComponent,
    CorporateTransactionDetailsComponent,
    ActionConfirmationModalComponent,
    ActionModalFooterComponent,
    CorporateStaffFormComponent,
    CallbackKreadeetComponent,
    AllocateKreadeetComponent,
    ClearKreadeetComponent,
    CorporateKreadeetDetailsComponent,
    SuggestVendorComponent,
    BranchFormComponent,
    CorporateStaffListComponent,
    CorporateProvidersComponent,
    CorporateTransactionsComponent,
    CorporatePaginationComponent,
    AddStaffProviderComponent,
    FundAccountComponent
  ],
  exports: [
    RegistrationSideComponent,
    LoginSideComponent,
    CorporateMetricCardComponent,
    NgChartsModule,
    AccountDetailsComponent,
    RequestKreadeetComponent,
    CorporateModalHeaderComponent,
    AddServiceProviderComponent,
    CorporateTransactionDetailsComponent,
    ActionConfirmationModalComponent,
    ActionModalFooterComponent,
    CorporateStaffFormComponent,
    CallbackKreadeetComponent,
    AllocateKreadeetComponent,
    ClearKreadeetComponent,
    CorporateKreadeetDetailsComponent,
    SuggestVendorComponent,
    BranchFormComponent,
    CorporateStaffListComponent,
    CorporateProvidersComponent,
    CorporateTransactionsComponent,
    CorporatePaginationComponent,
    AddStaffProviderComponent,
    FundAccountComponent
  ]
})
export class SharedCorporateComponentsModule { }
