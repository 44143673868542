import { Component, Input, OnInit } from '@angular/core';
import { CorporateTransaction } from '@app-models/app.interface';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-corporate-transaction-details',
  templateUrl: './corporate-transaction-details.component.html',
  styleUrls: ['./corporate-transaction-details.component.scss'],
})
export class CorporateTransactionDetailsComponent implements OnInit {
  @Input() transactionItem: CorporateTransaction;

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    console.log(this.transactionItem);
  }

  dismissModal() {
    this.modalController.dismiss();
  }

}
