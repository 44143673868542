import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-corporate-kreadeet-details',
  templateUrl: './corporate-kreadeet-details.component.html',
  styleUrls: ['./corporate-kreadeet-details.component.scss'],
})
export class CorporateKreadeetDetailsComponent implements OnInit {

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() { }

  dismissModal() {
    this.modalController.dismiss();
  }

}
