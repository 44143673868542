/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiErrorResponse, PaymentGatewayResponse } from '@app-models/api.response.interface';
import { BalanceInfo, PaymentGateway, UsersInfo, VirtualAccount } from '@app-models/app.interface';
import { PAYMENT_SERVICES } from '@app-models/constants';
import { ApiService } from '@app-services/api/api.service';
import { ComponentPickerService } from '@app-services/component-picker/component-picker.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { PaystackOptions } from 'angular4-paystack';
declare let RmPaymentEngine: any;
@Component({
  selector: 'app-fund-kreadeet',
  templateUrl: './fund-kreadeet.component.html',
  styleUrls: ['./fund-kreadeet.component.scss'],
})
export class FundKreadeetComponent implements OnInit {

  @ViewChild('PaystackButton') PaystackButton: any;
  @Input() balance: BalanceInfo;
  @Input() account: VirtualAccount;
  @Input() isModal = false;
  @Input() paystackOptions: PaystackOptions = {
    amount: 0,
    email: '',
    ref: ''
  };
  @Input() title: string;
  @Input() subTitle: string;
  @Input() buttonTitle: string;
  loading: HTMLIonLoadingElement;
  isPaymentGateway = true;
  amountValue: string | number = null;
  remitaConfig = {
    key: environment.remita.key,
    transactionId: Math.floor(Math.random() * 1101233),
    customerId: '',
    firstName: 'Kreadeet',
    lastName: 'Kreadeet',
    email: 'nKreadeet@gmail.com',
    amount: 1000,
    narration: 'Payment for Kreadeet',
  };
  currentUser: UsersInfo;
  isDesktop: boolean;
  data: any = {
    paymentGateway: null
  };
  stage = 1;
  paymentGateways: ReadonlyArray<PaymentGateway> = [];

  constructor(// tslint:disable: align
    public apiService: ApiService,
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public componentPickerService: ComponentPickerService,
    private helperMethods: HelperMethodsService,
    public modalController: ModalController,
    private ref: ChangeDetectorRef,
    public alertController: AlertController,
    public cryptoService: CryptoService) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit() {
    this.getProfile();
  }

  ionViewDidEnter() {
    this.getProfile();
    this.getPaymentGateways();
  }

  async getPaymentGateways() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.getPaymentGateways())
      .then(async (res: PaymentGatewayResponse) => {
        await loading.dismiss();
        this.paymentGateways = res.data;
        console.log(res);
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
        await loading.dismiss();
      });
  }


  async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: UsersInfo) => {
      this.currentUser = res;
      // Paystack
      this.paystackOptions.email = res.profile.email;

      // Remita
      this.remitaConfig.customerId = res.profile.id;
      this.remitaConfig.firstName = res.profile.firstName;
      this.remitaConfig.lastName = res.profile.lastName;
      this.remitaConfig.email = res.profile.email;

    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

  /**
   * Dismiss Modal
   */
  dismissModal(moreData: any = {}) {
    this.modalController.dismiss({
      dismissed: true,
      moreData
    }).then(() => {
      // console.log(data);
    }).catch((err) => console.log(err));
  }

  async fundKreadeetNow($event) {
    if (this.data.method === 1) {
      this.stage = 2;
      return;

    } else {
      const runPaymentGateway = this.data.paymentGateway === PAYMENT_SERVICES.REMITA
        ? await this.getGatewayTransactionRef().then(() => this.remitaPay())
        : this.payViaPaystackPromise($event);
    }
  }


  formatCurrencyOnBlur(event) {
    const uy = this.helperMethods.formatAmount(event.target.value.toString(), '');
    this.data.amount = event.target.value.toString().split(',').join('');
    // this.data.charges = this.helperMethods.payStackCharges(this.data.amount);
    this.data.charges = 0;
    this.amountValue = (uy === 'NaN') ? 0 : uy;
  }

  formatCurrencyOnFocus() {
    const dValue = this.data.amount === 0 ? 0 : parseFloat(this.data.amount.toString()).toFixed(2).toString();
    this.amountValue = (dValue === 'NaN') ? 0 : dValue;
  }

  calCharge(event = null) {
    if (event) {
      this.checkValue(event);
    } else {
      const dValue = this.data.amount === 0 ? '0' : parseFloat(this.data.amount.toString()).toFixed(2).toString();
      this.amountValue = this.helperMethods.formatAmount(((dValue === 'NaN') ? '0' : dValue), '');
    }
    this.data.amount = parseFloat(event.target.value.toString().split(',').join(''));
    // this.data.charges = this.helperMethods.payStackCharges(event.target.value);
    this.data.charges = 0;
    const amountWithCharges = parseFloat(((this.data.charges + this.data.amount) * 100).toFixed(2));

    // paystack
    this.paystackOptions.amount = amountWithCharges;

    // remita
    this.remitaConfig.amount = this.data.amount;

    console.log(this.data.charges, this.paystackOptions.amount);

    if (event) {
      this.formatCurrencyOnBlur(event);
    }
  }

  checkValue(event: any) {
    const uy = this.helperMethods.formatAmount(event.target.value.toString(), '').split(',').join('');
    // this.amountValue = (uy === 'NaN') ? 0 : (uy);
    this.data.amount = (uy === 'NaN') ? 0 : parseFloat(uy);
    console.log(event.target.value, this.data.amount);
  }



  /**
   * Paystack Payment Init
   */
  async paymentInit($event = null) {
    this.loading = await this.apiService.getLoader();
    await this.loading.present();
    this.paystackOptions.metadata = this.data;
    console.log('Payment initialized');
    this.isPaymentGateway = false;
    console.log(this.paystackOptions);
    this.ref.detectChanges();
  }

  /**
   * Paystack Payment Cancel
   */
  async paymentCancel($event = null) {
    await this.loading.dismiss();
    this.title = 'Payment failed';
    const ref = {
      reference: this.paystackOptions.ref,
      trans: null,
      status: 'failed',
      message: 'Closed',
      transaction: null,
      trxref: this.paystackOptions.ref
    };
    console.log(this.title, ref);
    this.apiService.errorAlert(this.title);
    this.dismissModal();
    this.isPaymentGateway = true;
    this.ref.detectChanges();
    this.apiService.abortTransaction({ reference: this.paystackOptions.ref });
  }

  /**
   * Paystack Payment Done
   */
  async paymentDone(ref: any) {
    await this.loading.dismiss();
    this.title = 'Payment successfully';
    if (ref.message === 'Approved') {
      ref.message = 'Payment was make successfully.';
    }
    if (ref.status === 'success') {
      // this.makePayment({});
    } else {
      // this.apiService.successAlert(this.title);
    }
    console.log(this.title, ref);
    this.dismissModal();
    this.isPaymentGateway = true;
    this.ref.detectChanges();
  }

  /**
   * Paystack async InlinePay
   */
  async payViaPaystackPromise($event) {
    this.loading = await this.apiService.getLoader();
    await this.loading.present();
    await this.getGatewayTransactionRef().then(() => {
      this.PaystackButton.el.click();
    });
    await this.loading.dismiss();
  }


  /**
   * Get Gateway Transaction Ref
   */
  async getGatewayTransactionRef() {
    let gotTransactionRef = false;
    const param = {
      amount: this.data.amount,
      serviceMode: 'TOP_UP',
      gateway: this.data.paymentGateway,
    };
    await this.helperMethods.promiseTimeout(this.apiService.initializeTransaction(param)).then(async (res) => {
      console.log(res);
      if (res.status) {
        this.data.gatewayTransactionRef = res?.data?.reference;
        // Paystack
        this.paystackOptions.ref = this.data.gatewayTransactionRef || res?.data?.reference;

        // Remita
        this.remitaConfig.transactionId = this.data.gatewayTransactionRef || res?.data?.reference;
      }
      gotTransactionRef = true;
    }).catch(async (err) => {
      this.apiService.errorAlert(err.message, err?.header);
      if (err?.shouldLogout) {
        await this.apiService.logout();
      }
      gotTransactionRef = false;
      console.log(err);
    });
    return gotTransactionRef;
  }

  async makePayment(moreData) {
    this.loading = await this.apiService.getLoader();
    await this.loading.present();
    const paramData = [
      {
        ...moreData,
        orderRef: this.data.gatewayTransactionRef,
        amount: this.data.amount,
      }
    ];
    await this.apiService.remitaTest(paramData).then(() => {
      // this.apiService.successAlert('Payment was make successfully.');
    }).catch(async (err) => {
      // this.apiService.errorAlert(err.message, err?.header);
      if (err?.shouldLogout) {
        await this.apiService.logout();
      }
    });
    this.dismissModal();
    await this.loading.dismiss();
  }

  remitaPay() {
    const that = this;
    const paymentEngine = RmPaymentEngine.init({
      ...this.remitaConfig,
      onSuccess: (response: any) => {
        console.log('callback Successful Response', response);
        this.apiService.successAlert('Payment was make successfully.');
        this.makePayment(response);
      },
      onError: (response: any) => {
        console.log('callback Error Response', response);
        that.apiService.errorAlert('Payment failed');
      },
      onClose: (response: any) => {
        console.log('closed', response);
      }
    });
    paymentEngine.showPaymentWidget();
  }

}
