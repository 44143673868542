import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '@app-environments/environment';
import { UtilityProvider } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { SliderConfigService } from '@app-services/slider-config/slider-config.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-select-network-provider',
  templateUrl: './select-network-provider.component.html',
  styleUrls: ['./select-network-provider.component.scss'],
})
export class SelectNetworkProviderComponent implements OnInit {

  @Input() selected: any = {};
  @Output() selectedEvent: EventEmitter<any> = new EventEmitter();
  @Input() networks: ReadonlyArray<UtilityProvider> = [
  ];
  isPwa: boolean;

  constructor(// tslint:disable: align
    public navController: NavController,
    public apiService: ApiService,
    private helperMethods: HelperMethodsService,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    public sliderConfigService: SliderConfigService,
    public alertController: AlertController) {
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'SelectNetworkProviderComponent';
  }

  ngOnInit() {
  }

  select(network: any) {
    this.selected = network;
    this.selectedEvent.emit(network);
  }


}
