import { Component, Input, OnInit } from '@angular/core';
import visitedMerchantsData from '@app-assets/mocks/visitedMerchants.json';
import { CategoryInfo, MerchantInfo } from '@app-models/app.interface';
import { ComponentPickerService } from '@app-services/component-picker/component-picker.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss'],
})
export class CategoryDetailsComponent implements OnInit {

  @Input() category: CategoryInfo = {};
  isDesktop: boolean;
  readonly merchants: Array<MerchantInfo> = visitedMerchantsData.data;

  constructor(// tslint:disable: align
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public componentPickerService: ComponentPickerService,
    public modalController: ModalController,
    public alertController: AlertController,
    public cryptoService: CryptoService) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'CategoryDetailsComponent';
  }

  ngOnInit() { }

  /**
   * Dismiss Modal
   */
  dismissModal(moreData: any = {}) {
    this.modalController.dismiss({
      dismissed: true,
      moreData
    }).then(() => {
      // console.log(data);
    }).catch((err) => console.log(err));
  }


}
