import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedCommonComponentsModule } from '@app-components/common/shared-common-components.module';
import { CategoryDetailsComponent } from '@app-components/user/details/category-details/category-details.component';
import { SharedItemsComponentsModule } from '@app-components/user/items/shared-items-components.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Angular4PaystackModule } from 'angular4-paystack';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SharedDirectivesModule } from 'src/app/directives/shared-directives.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    Angular4PaystackModule,
    NgChartsModule,
    SharedCommonComponentsModule,
    SharedItemsComponentsModule,
    IonicSelectableModule,
    SharedDirectivesModule,
    TranslateModule,
  ],
  declarations: [
    CategoryDetailsComponent
  ],
  exports: [
    CategoryDetailsComponent
  ]
})
export class SharedDetailsComponentsModule { }
