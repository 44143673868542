import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-action-modal-footer',
  templateUrl: './action-modal-footer.component.html',
  styleUrls: ['./action-modal-footer.component.scss'],
})
export class ActionModalFooterComponent implements OnInit {
  @Input() mainBtnTitle: string;
  @Input() btnType: 'danger' | 'warning' | 'freeze';
  @Input() cancelBtnTitle: string;
  @Output() actionHandler: EventEmitter<boolean> = new EventEmitter(false);

  constructor() { }

  ngOnInit() { }

  dismissHandler(val: boolean) {
    this.actionHandler.emit(val);
  }

}
