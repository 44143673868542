import { Component, OnInit } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiErrorResponse, ApiGenericResponseInfo, AuthUserResponse } from '@app-models/api.response.interface';
import { UsersInfo } from '@app-models/app.interface.d';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {

  isPwa: any;
  user: any = {
  };
  currentUser: UsersInfo;

  constructor(
    public navController: NavController,
    public apiService: ApiService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public toastController: ToastController,
    public loadingController: LoadingController) {
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'EditProfileComponent';
  }

  dismissModal(data: any = {}) {
    this.modalController.dismiss({
      dismissed: true,
      ...data
    });
  }

  async ionViewDidEnter() {
    await this.apiService.getAuthenticatedUser().then((res: UsersInfo) => {
      this.currentUser = res;
      this.user = {
        fName: res.profile.firstName,
        lName: res.profile.lastName,
        email: res.profile.email
      };
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
    this.getProfile();
  }

  ngOnInit() {
  }

  async getProfile() {
    await this.helperMethods.promiseTimeout(this.apiService.getUserProfile())
      .then((res: AuthUserResponse) => {
        if (res.status) {
          this.currentUser = res.data;
          this.apiService.setAuthenticatedPersistentUser(res?.data, res?.data?.userType);
          this.apiService.setAuthenticatedUser(res?.data);
        }
      }).catch((err: ApiErrorResponse) => {
        console.log(err);
      });
  }


  goBack(page: string = null) {
    if (page) {
      this.navController.navigateBack(page);
    } else {
      this.navController.back();
    }
  }

  async updateUserProfile() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    const param = this.helperMethods.cleanObjectData(this.user);
    await this.helperMethods.promiseTimeout(this.apiService.updateUserProfile(param))
      .then(async (res: ApiGenericResponseInfo) => {
        await loading.dismiss();
        if (res.status) {
          await this.getProfile();
          this.dismissModal(this.currentUser);
          this.apiService.successAlert(res.message, res.header || 'Successfully updated');
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

}
