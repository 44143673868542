import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './app-search.component.html',
  styleUrls: ['./app-search.component.scss'],
})
export class AppSearchComponent implements OnInit {

  @Input() value = '';
  @Input() showFilter = true;
  @Input() cssClass = '';
  @Input() searchBarCssClass = '';

  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  constructor() { }

  getClassName() {
    return 'AppSearchComponent';
  }

  ngOnInit() { }

  onChange(event) {
    console.log(event);
    this.valueChange.emit(event);
  }

}
