import { Component, Input, OnInit } from '@angular/core';
import { CategoryDetailsComponent } from '@app-components/user/details/category-details/category-details.component';
import { AppMock } from '@app-mocks/app-mock';
import { CategoryInfo } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-category-card-item',
  templateUrl: './category-card-item.component.html',
  styleUrls: ['./category-card-item.component.scss'],
})
export class CategoryCardItemComponent implements OnInit {

  @Input() category: CategoryInfo = {
    categoryId: 1,
    title: 'Gas Stations',
    imageUrl: 'https://res.cloudinary.com/nkreadeet/image/upload/v1655566696/GasStation_kdrf2g.svg',
    backgroundColor: '#D3EEE7',
    borderColor: '#0EA16D',
    isActive: 'YES',
    createdAt: '2022-07-15T20:57:42.000000Z',
    updatedAt: '2022-07-15T20:57:42.000000Z'
  };

  constructor(
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public apiService: ApiService,
    public modalController: ModalController,
    public appMock: AppMock,
    public alertController: AlertController) { }

  get getStyle() {
    return {
      'background-color': this.category.backgroundColor,
      'border-color': this.category.borderColor
    };
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'CategoryCardItemComponent';
  }

  ngOnInit() { }



  async openDetails(category: CategoryInfo = this.category) {
    const componentProps = {
      category
    };
    const modal = await this.apiService.openComponent(CategoryDetailsComponent, componentProps, 100);
    const { data } = await modal.onDidDismiss();
    console.log(data);
  }

}
