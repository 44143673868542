import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedCommonComponentsModule } from '@app-components/common/shared-common-components.module';
import { SharedItemsComponentsModule } from '@app-components/user/items/shared-items-components.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Angular4PaystackModule } from 'angular4-paystack';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SharedDirectivesModule } from 'src/app/directives/shared-directives.module';
import { SwiperModule } from 'swiper/angular';
import { AirtimeComponent } from './airtime/airtime.component';
import { CableComponent } from './cable/cable.component';
import { DataBundleComponent } from './data-bundle/data-bundle.component';
import { ElectricityComponent } from './electricity/electricity.component';
import { OtherBillComponent } from './other-bill/other-bill.component';
import { PayBillComponent } from './pay-bill/pay-bill.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    Angular4PaystackModule,
    NgChartsModule,
    SharedCommonComponentsModule,
    SwiperModule,
    SharedItemsComponentsModule,
    IonicSelectableModule,
    SharedDirectivesModule,
    TranslateModule,
  ],
  declarations: [
    PayBillComponent,
    AirtimeComponent,
    DataBundleComponent,
    OtherBillComponent,
    CableComponent,
    ElectricityComponent
  ],
  exports: [
    PayBillComponent,
    AirtimeComponent,
    DataBundleComponent,
    OtherBillComponent,
    CableComponent,
    ElectricityComponent
  ]
})
export class SharedUtilityBillComponentsModule { }
