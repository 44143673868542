/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { ApiService } from '@app-services/api/api.service';
import { FormValidatorService } from '@app-services/form-validator/form-validator.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ActionSheetController, ModalController, Platform } from '@ionic/angular';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { CorporateBranch, CorporateProfile, CorporateStaff } from '@app-models/app.interface';

@Component({
  selector: 'app-corporate-staff-form',
  templateUrl: './corporate-staff-form.component.html',
  styleUrls: ['./corporate-staff-form.component.scss'],
})
export class CorporateStaffFormComponent implements OnInit {
  @ViewChild('staffImg', { static: false }) staffImg: ElementRef;
  @Input() staff: CorporateStaff;
  @Input() corporateBranchId: string;

  formData = new FormData();
  staffForm: UntypedFormGroup;
  showPassword = false;

  staffImage: any = null;
  staffImageUrl: string;

  branchList: ReadonlyArray<CorporateBranch> = [];
  branchListLoader = false;

  currentUser: CorporateProfile;
  staffBranch = new FormControl('');

  constructor(
    private platform: Platform,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private fb: UntypedFormBuilder,
    public apiService: ApiService,
    private helperMethods: HelperMethodsService
  ) { }

  async ngOnInit() {
    this.initStaffForm();
    await this.getProfile();

    if (this.currentUser.isPrimary === 'YES' && !this.staff) {
      this.getBranches();
    }

    if (this.staff) {
      console.log(this.staff);
      this.staffImage = this.staff.user?.faceImage;
      this.staffBranch.setValue(this.staff.corporateBranch?.branchName);
    }
  }

  dismissModal(data?) {
    this.modalController.dismiss(data);
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  async choosePicture(source: CameraSource) {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source
    });
    console.log('image: ', image);
    this.staffImageUrl = image.base64String;
    const blobData = this.helperMethods.b64toBlob(image.base64String, `image/${image.format}`);
    const date = new Date(); const time = date.getTime(); const fileName = `${time}`;
    const file: File = this.helperMethods.blobToFile(blobData, `${fileName}.${image.format}`, `image/${image.format}`);
    if (file.size <= 10351930) {
      this.staffImage = {
        base64: `data:image/${image.format};base64,` + image.base64String,
        blob: blobData,
        fileName,
        format: image.format,
        file
      };
      this.uploadImageFile(file);
    } else {
      this.apiService.errorAlert('Your Image should not be more that 10M');
    }
  }

  async uploadFile(event: EventTarget | any) {
    const eventObj: any = event as any;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const file: File = target.files[0];
    let base64 = '';
    await this.helperMethods.resolveBase64(file).then((data) => {
      // console.log(data);
      base64 = data;
    });
    const blobData = this.helperMethods.b64toBlob(base64.split('base64,')[1], `${file.type}`);
    if (file.size <= 10351930) {
      this.staffImage = {
        base64,
        blob: blobData,
        fileName: file.name,
        format: file.type,
        file
      };
      console.log('file: ', file);
      this.uploadImageFile(file);
    } else {
      this.apiService.errorAlert('Your Image should not be more that 10MB');
    }
  }

  async uploadImageFile(file: File) {
    const date = new Date();
    const compressFile = await this.helperMethods.compressImage(file);
    this.formData.set('face_image', compressFile, `${compressFile.name}`);
  }



  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Choose Image Source',
      mode: 'md',
      buttons: [{
        text: 'Take Photo',
        icon: 'assets/icon/camera_alt-24px.svg',
        handler: () => {
          this.choosePicture(CameraSource.Camera);
          actionSheet.dismiss();
        }
      },
      {
        text: 'Choose Photo',
        icon: 'assets/icon/add_a_photo-24px.svg',
        handler: () => {
          if (!this.platform.is('hybrid')) {
            this.staffImg.nativeElement.click();
            actionSheet.dismiss();
          } else {
            this.choosePicture(CameraSource.Photos);
            actionSheet.dismiss();
          }
        }
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }],
      backdropDismiss: false
    });
    await actionSheet.present();
  }

  staffFormHandler() {
    if (!this.staff) {
      this.addStaffHandler();
      return;
    }

    this.updateStaffHandler();
  }

  private async addStaffHandler() {

    const loading = await this.apiService.getLoader();
    await loading.present();
    const param = {
      ...this.staffForm.value,
    };

    // eslint-disable-next-line guard-for-in
    for (const i in param) {
      this.formData.set(i, param[i]);
    }

    await this.helperMethods.promiseTimeout(this.apiService.corporateCreateStaff(this.formData))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.apiService.corporateAlert(
            res.message,
            'Staff Added',
            null,
            'corporate-alert-success.png',
            'mr-green-success.png',
            null,
            'success',
          );
          this.dismissModal(true);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }

        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });

  }

  private async updateStaffHandler() {
    console.log(this.staffForm.value);

    const loading = await this.apiService.getLoader();
    await loading.present();
    const param = {
      ...this.staffForm.value,
    };
    // eslint-disable-next-line guard-for-in
    for (const i in param) {
      this.formData.set(i, param[i]);
    }

    await this.helperMethods.promiseTimeout(this.apiService.corporateUpdateStaff(this.staff.staffId, this.formData))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.apiService.corporateAlert(
            res.message,
            'Successful',
            null,
            'corporate-alert-success.png',
            'mr-green-success.png',
            null,
            'success',
          );
          this.dismissModal(true);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }

        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });
    // this.dismissModal();

  }

  private initStaffForm() {
    const branchRequired = this.corporateBranchId ? null : [Validators.required];

    this.staffForm = this.fb.group({
      fName: new UntypedFormControl(
        this.staff ? this.staff.fName : '',
        [Validators.required, Validators.minLength(3)]
      ),
      lName: new UntypedFormControl(
        this.staff ? this.staff.lName : '',
        [Validators.required, Validators.minLength(3)]
      ),
      email: new UntypedFormControl(
        { value: this.staff ? this.staff.email : '', disabled: this.staff ? true : false },
        [Validators.required, Validators.email, FormValidatorService.emailValidator]
      ),
      phone: new UntypedFormControl(
        { value: this.staff ? this.staff.phone : '', disabled: this.staff ? true : false },
        [Validators.required, Validators.minLength(10), Validators.maxLength(11)]
      ),
      designation: new UntypedFormControl(
        this.staff ? this.staff.designation : '',
        [Validators.required, Validators.minLength(3)]
      ),
      corporate_branch_id: new UntypedFormControl(
        { value: this.corporateBranchId ? parseInt(this.corporateBranchId, 10) : '', disabled: this.staff ? true : false },
        branchRequired
      ),
    });
  }

  private async getBranches() {
    this.branchListLoader = true;
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetBranches({ paginate: false }))
      .then(async (res: any) => {
        this.branchListLoader = false;
        this.branchList = [...res?.data.branches];
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.branchListLoader = false;
      });
  }

  private async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: CorporateProfile) => {
      this.currentUser = res.profile;
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

}
