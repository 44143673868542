import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Angular4PaystackModule } from 'angular4-paystack';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SharedDirectivesModule } from 'src/app/directives/shared-directives.module';
import { ActionCardItemComponent } from './action-card-item/action-card-item.component';
import { BalanceCardItemComponent } from './balance-card-item/balance-card-item.component';
import { BorrowMeFuelItemComponent } from './borrow-me-fuel-item/borrow-me-fuel-item.component';
import { CategoryCardItemComponent } from './category-card-item/category-card-item.component';
import { EmptyItemComponent } from './empty-item/empty-item.component';
import { MerchantVisitedCardItemComponent } from './merchant-visited-card-item/merchant-visited-card-item.component';
import { SegmentItemComponent } from './segment-item/segment-item.component';
import { ServiceCardItemComponent } from './service-card-item/service-card-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    Angular4PaystackModule,
    NgChartsModule,
    IonicSelectableModule,
    SharedDirectivesModule,
    TranslateModule,
  ],
  declarations: [
    BalanceCardItemComponent,
    ActionCardItemComponent,
    CategoryCardItemComponent,
    SegmentItemComponent,
    MerchantVisitedCardItemComponent,
    ServiceCardItemComponent,
    BorrowMeFuelItemComponent,
    EmptyItemComponent
  ],
  exports: [
    BalanceCardItemComponent,
    ActionCardItemComponent,
    CategoryCardItemComponent,
    SegmentItemComponent,
    MerchantVisitedCardItemComponent,
    ServiceCardItemComponent,
    BorrowMeFuelItemComponent,
    EmptyItemComponent
  ]
})
export class SharedItemsComponentsModule { }
