import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Angular4PaystackModule } from 'angular4-paystack';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SharedDirectivesModule } from 'src/app/directives/shared-directives.module';
import { SwiperModule } from 'swiper/angular';
import { SharedPipeModule } from '../../pipes/shared-pipe.module';
import { AddOutletComponent } from './add-outlet/add-outlet.component';
import { AddUserComponent } from './add-user/add-user.component';
import { MerchantWebHeaderComponent } from './merchant-web-header/merchant-web-header.component';
import { PublicLeftSideComponent } from './public-left-side/public-left-side.component';
import { SaleItemComponent } from './sale-item/sale-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    SwiperModule,
    SharedPipeModule,
    Angular4PaystackModule,
    NgChartsModule,
    IonicSelectableModule,
    SharedDirectivesModule,
    TranslateModule,
  ],
  declarations: [
    PublicLeftSideComponent,
    MerchantWebHeaderComponent,
    SaleItemComponent,
    AddUserComponent,
    AddOutletComponent
  ],
  exports: [
    PublicLeftSideComponent,
    MerchantWebHeaderComponent,
    SaleItemComponent,
    AddUserComponent,
    AddOutletComponent
  ]
})
export class SharedMerchantComponentsModule { }
