/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@app-environments/environment';
import { ApiService } from '@app-services/api/api.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { DataService } from '@app-services/data/data.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, IonInput, LoadingController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
})
export class PinComponent implements OnInit {


  @ViewChild('pin1', { read: IonInput }) pin1: IonInput;
  @ViewChild('pin2', { read: IonInput }) pin2: IonInput;
  @ViewChild('pin3', { read: IonInput }) pin3: IonInput;
  @ViewChild('pin4', { read: IonInput }) pin4: IonInput;
  @Input() account: any;
  @Input() data: any;
  isDesktop: boolean;
  isPwa: any;
  currentUrl: string;
  currentUser: any;

  param: any = {
    pin: null,
    company_id: null
  };
  pin: any = [null, null, null, null];


  constructor(
    private router: Router,
    public navController: NavController,
    private dataService: DataService,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService,
    private cryptoService: CryptoService,
    public alertController: AlertController) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'PinComponent';
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.navController.back();
  }

  ngOnInit() {
    this.param.company_id = this.account.company.id;
  }


  ionViewDidLoad() {
    if (this.router.url) {
      this.currentUrl = this.getActiveTab(this.router.url);
      console.log(this.currentUrl);
    }
  }

  ionViewDidEnter() {
    if (this.router.url) {
      this.currentUrl = this.getActiveTab(this.router.url);
      console.log(this.currentUrl);
    }
    this.apiService.getAuthenticatedUser().then(data => {
      this.currentUser = data;
      console.log(data);
    }).catch((err) => {
      console.log(err);
    });
  }

  getActiveTab(c: string) {
    if (c.includes('/public/')) {
      const d = c.substr(8);
      const e = d.indexOf('/') !== -1 ? d.indexOf('/') : d.length;
      return d.substr(0, e);
    }
  }

  checkOTPInput(event: any, index: number) {
    const inputFields = [this.pin1, this.pin2, this.pin3, this.pin4];
    if (event.detail.value.length > 0 && index === 3) {
      this.param.pin = this.pin.join('');
      console.log(this.param);
      return;
    }
    if (event.detail.value.length > 0 && index !== 3) {
      this.pin[index] = event.detail.value.toUpperCase();
      setTimeout(() => {
        inputFields[index + 1].setFocus();
      }, 50);
    } else {
      for (index; index < this.pin.length; index++) {
        this.pin[index] = null;
      }
    }
  }

  async switchToThisAccount() {
  }

  goToMember(page: string) {
    this.navController.navigateRoot(`member/user/${page}`);
  }


}
