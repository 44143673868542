/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stage: 'dev',
  appVerCode: '0.0.1',
  isPwa: true,
  apiBaseUrlOnly: 'kreaddev-api.kreadeet.com',
  apiBaseUrlRoot: 'https://kreaddev-api.kreadeet.com/',
  apiBaseUrl: 'https://kreaddev-api.kreadeet.com/api/v1',
  defaultImageUrl: 'assets/img/avatar.svg',
  monnify: {
    isTestMode: false,
    apiKey: 'MK_PROD_A7RSTWKRQ3',
    contractCode: '458189397897',
    paymentDescription: 'Kreadeet',
  },
  paystack: {
    publicKey: 'pk_test_a7c9d331ecbf83f632afc01944be6d95afe9e13f',
  },
  mono: {
    publicKey: 'test_pk_btSskFWRSO2bKvRgvv8C',
  },
  remita: {
    key: 'QzAwMDAyNzEyNTl8MTEwNjE4NjF8OWZjOWYwNmMyZDk3MDRhYWM3YThiOThlNTNjZTE3ZjYxOTY5NDdmZWE1YzU3NDc0ZjE2ZDZjNTg1YWYxNWY3NWM4ZjMzNzZhNjNhZWZlOWQwNmJhNTFkMjIxYTRiMjYzZDkzNGQ3NTUxNDIxYWNlOGY4ZWEyODY3ZjlhNGUwYTY=',
  },
  cryptoInfo: {
    keyId: 'test',
    salt: '4321',
    keySize: 256,
    iterations: 23,
    keys: 'UbfKIjpofcgPrFAgk46P+hNM/Hs=',
    iv: '12345678909876541234567890987654',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBcT2irmArohRTHkTGwIYF2rZkg6mDJdkA',
    authDomain: 'kreadeet-2021.firebaseapp.com',
    projectId: 'kreadeet-2021',
    storageBucket: 'kreadeet-2021.appspot.com',
    messagingSenderId: '720406818729',
    appId: '1:720406818729:web:ce70db1352b4ddef761eb8',
    measurementId: 'G-24EP7J2RGY'
  },
  appInfo: {
    ios: 'com.kreadeet.app',
    android: 'com.kreadeet.app'
  },
  mapID: 'AIzaSyA29bOoP7ZKBTMKYAHLukB06ExZsQ4pFGs',
  onesignal: {
    appId: 'f5033814-8b61-4c19-9620-a76bf2e1c46d',
    googleProjectNumber: '720406818729'
  },
  socialCredential: {
    FACEBOOK_CLIENT_ID: '',
    FACEBOOK_CLIENT_SECRET: '',
    LINKEDIN_CLIENT_ID: '',
    LINKEDIN_CLIENT_SECRET: '',
    GOOGLE: {
      WEB: {
        GOOGLE_CLIENT_ID: '',
        GOOGLE_CLIENT_SECRET: '',
      },
      ANDROID: {
        GOOGLE_CLIENT_ID: '',
        GOOGLE_CLIENT_SECRET: '',
      },
      IOS: {
        GOOGLE_CLIENT_ID: '',
        GOOGLE_CLIENT_SECRET: '',
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
