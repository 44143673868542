/* eslint-disable guard-for-in */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Router } from '@angular/router';
// tslint:disable:forin
import {
  HttpErrorResponse, HttpHandler, HttpInterceptor, HttpParams, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@app-environments/environment';
import { TokenInfo } from '@app-models/app.interface';
import { DeviceInfo } from '@capacitor/device';
import { NavController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../services/api/api.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  deviceInfo: DeviceInfo | any;
  devicePlatform: any;
  activeDomain: 'user' | 'merchant' | 'corporate' | string = 'merchant';

  constructor(
    public apiService: ApiService,
    private router: Router,
    public navController: NavController
  ) {
    this.deviceInfo = this.apiService.deviceInfo ? this.apiService.deviceInfo : {};
    this.apiService.getLocalData('currentDomain').then((data) => {
      this.activeDomain = data;
      this.apiService.setCurrentDomain(this.activeDomain);
    }).catch(() => {
      this.getActiveSubdomain(this.router.url).then((data) => {
        this.activeDomain = data;
        this.apiService.setCurrentDomain(this.activeDomain);
        console.log('activeDomain:', this.activeDomain);
      });
    });
  }

  async getActiveSubdomain(c: string) {
    if (c.includes('/member/')) {
      const d = c.substr(8);
      const e = d.indexOf('/') !== -1 ? d.indexOf('/') : d.length;
      return d.substr(0, e);
    }
  }


  intercept(req: HttpRequest<any>, next: HttpHandler) {

    this.deviceInfo = this.apiService.deviceInfo ? this.apiService.deviceInfo : {};
    // execute this block if is not log in endpoint
    const excludedUrl: any[] = [
      `${environment.apiBaseUrl}/auth/users/login`,
      `${environment.apiBaseUrl}/auth/users/register`,
      `https://maps.googleapis.com/maps/api/geocode/`,
    ];

    this.devicePlatform = this.deviceInfo.platform
      ? this.deviceInfo.platform
      : 'android';

    const appVerCode =
      this.deviceInfo.appVersion &&
        this.deviceInfo.appVersion !== null &&
        this.deviceInfo.appVersion !== 'null' &&
        this.deviceInfo.appVersion !== ''
        ? this.deviceInfo.appVersion
        : '0.0.1';

    let params = new HttpParams();
    // if (req.method === 'GET' || req.method === 'POST') {

    params = new HttpParams()
      .set('appVersion', appVerCode)
      .set('version', appVerCode)
      .set('appName', 'kreadeet')
      .set('operatingSystem', this.deviceInfo.operatingSystem || '')
      .set('osVersion', this.deviceInfo.osVersion || '')
      .set('os', (this.deviceInfo.operatingSystem === 'ios' || this.deviceInfo.operatingSystem === 'android')
        ? this.deviceInfo.operatingSystem : 'web')
      .set('manufacturer', this.deviceInfo.manufacturer || '')
      .set('model', this.deviceInfo.model || '')
      .set('platform', this.deviceInfo.platform || '')
      .set('uuid', this.deviceInfo.uuid || '');

    // console.log(${authToken});
    if (excludedUrl.indexOf(req.url) === -1) {
      // Get the auth token from the service.
      let authToken: string;
      let tokenType: string;
      this.apiService.getLocalData('currentDomain').then((data) => {
        console.log(data);
        this.activeDomain = data;
      }).catch(() => {
        this.getActiveSubdomain(this.router.url).then((data) => {
          this.activeDomain = data;
          console.log('activeDomain:', this.activeDomain);
        });
      });
      if (!this.apiService.accessToken) {
        this.apiService.getAuthenticatedToken(this.activeDomain).then((token: TokenInfo) => {
          try {
            console.log(token);
            authToken = token.accessToken;
            tokenType = token.tokenType;
          } catch (err) {
            console.log(err);
          }
        }).catch((err) => console.log(err));
      } else {
        authToken = this.apiService.accessToken.accessToken;
        tokenType = this.apiService.accessToken.tokenType;
      }

      // Clone the request and replace the original headers with
      // cloned headers, updated with the authorization.
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `${tokenType || 'Bearer'} ${authToken}`)
          .set('X-Requested-With', `XMLHttpRequest`)
          .set('Accept', `application/json`),
        params,
      });
      // send cloned request with header to the next handler.
      return next.handle(authReq).pipe(
        // retry(1), // retry a failed request up to 3 times
        catchError(this.httpHandleError)
      );
    } else {
      const paramReq = req.clone({
        headers: req.headers.set('X-Requested-With', `XMLHttpRequest`)
          .set('Accept', `application/json`),
        params,
      });
      return next.handle(paramReq).pipe(
        // retry(2), // retry a failed request up to 2 times
        catchError(this.httpHandleError)
      );
    }
  }

  httpHandleError(error: HttpErrorResponse) {
    let result = null;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      result = error.error;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, body was: ${JSON.stringify(
          error
        )}`
      );
      let content = null;

      if (!error.error.success && error.error.message === 'The given data was invalid.') {
        content = '';
        content = getResponseErrors(error);
      } else if (!error.error.success) {
        content = error.error.message;
      }
      console.log(content);
      result = {
        status: false,
        title: error.error.header || error.error.title || 'Oops!',
        header: error.error.header || error.error.title || 'Something went wrong',
        message: content
          ? content
          : 'We are unable to perform the operation now. Please try again later...',
      };
      result.shouldLogout = result.header === 'Authorization Error' ? true : false;
      console.log(result);
    }

    return throwError(result);
  }
}

function getResponseErrors(error: HttpErrorResponse, type: 'string' | 'array' = 'string') {
  let content: string | Array<string>;
  if (type === 'string') {
    for (const i in error.error.errors) {
      content += `${error.error.errors[i]} <br>`;
    }
    for (const i in error.error.error) {
      content += `${error.error.error[i]} <br>`;
    }
    for (const i in error.error.data) {
      content += `${error.error.data[i]} <br>`;
    }
  } else {
    content = [];
    for (const i in error.error.errors) {
      content.push(error.error.errors[i]);
    }
    for (const i in error.error.error) {
      content.push(error.error.error[i]);
    }
    for (const i in error.error.data) {
      content.push(error.error.data[i]);
    }
  }
  return content;
}



