/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@app-environments/environment';
import { ApiErrorResponse } from '@app-models/api.response.interface';
import { CashierUsersInfo, MerchantUsersInfo } from '@app-models/app.interface.d';
import { ApiService } from '@app-services/api/api.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { DataService } from '@app-services/data/data.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-sweep-request',
  templateUrl: './sweep-request.component.html',
  styleUrls: ['./sweep-request.component.scss'],
})
export class SweepRequestComponent implements OnInit {

  @Input() currentUser: MerchantUsersInfo | CashierUsersInfo = {};
  isDesktop: boolean;
  isPwa: any;
  asGenerated = false;
  token = '';
  amountValue: string | number = '10,000.00';
  data: any = {
    amount: 10000,
  };
  paymentInfo: any;
  setTime = null;
  myAngularxQrCode = 'token';
  activeDomain: 'user' | 'merchant' | 'corporate' | string = 'merchant';
  authToken = '';
  tokenType = 'Bearer';


  constructor(
    private router: Router,
    public navController: NavController,
    private dataService: DataService,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService,
    private cryptoService: CryptoService,
    public alertController: AlertController) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
    this.isPwa = environment.isPwa;

  }
  ngOnInit(): void {
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'TokenPayComponent';
  }

  async getAuthToken() {
    this.authToken = this.currentUser.accessToken;
    this.tokenType = 'Bearer';
  }

  async ionViewDidEnter() {

    await this.getProfile();
    this.apiService.getLocalData('currentDomain').then((data) => {
      this.activeDomain = data;
      this.apiService.setCurrentDomain(this.activeDomain);

    }).catch(() => {
      this.getActiveSubdomain(this.router.url).then((data) => {
        this.activeDomain = data;
        this.apiService.setCurrentDomain(this.activeDomain);
        console.log('activeDomain:', this.activeDomain);

      });
    });
  }


  async getActiveSubdomain(c: string) {
    if (c.includes('/member/')) {
      const d = c.substr(8);
      const e = d.indexOf('/') !== -1 ? d.indexOf('/') : d.length;
      return d.substr(0, e);
    }
  }

  async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: MerchantUsersInfo) => {
      this.currentUser = res;
      console.log(res);
      this.getAuthToken();
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

  dismissModal() {
    clearInterval(this.setTime);
    this.modalController.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.navController.back();
  }

  async initiate() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    this.apiService.successAlert('Request Successful');
    this.dismissModal();
    await loading.dismiss();
  }

  formatCurrencyOnBlur(event: Event | any) {
    const uy = this.helperMethods.formatAmount(event.detail.value.toString(), '');
    this.data.amount = event.detail.value.split(',').join('');
    this.amountValue = (uy === 'NaN') ? 0 : uy;
  }

  formatCurrencyOnFocus() {
    const dValue = this.data.amount === 0 ? 0 : parseFloat(this.data.amount.toString()).toFixed(2).toString();
    this.amountValue = (dValue === 'NaN') ? 0 : dValue;
  }

  checkValue(event = null) {
    const uy = this.helperMethods.formatAmount(event.detail.value.toString(), '').split(',').join('');
    this.data.amount = (uy === 'NaN') ? 0 : parseFloat(uy);
    if (event) {
      this.formatCurrencyOnBlur(event);
    }
  }

}
