
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CustomRatingsComponent } from '@app-components/common/custom-ratings/custom-ratings.component';
import { QrViewComponentModule } from '@app-components/common/payment/qr-view/qr-view.component.module';
import { SharedCommonComponentsModule } from '@app-components/common/shared-common-components.module';
import {
  TransactionDetailsComponentModule
} from '@app-components/user/transaction/transaction-details/transaction-details.component.module';
import { TransactionItemComponent } from '@app-components/user/transaction/transaction-item/transaction-item.component';
import { IonicModule } from '@ionic/angular';
import { Angular4PaystackModule } from 'angular4-paystack';
import { QRCodeModule } from 'angularx-qrcode';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SwiperModule } from 'swiper/angular';
import { SharedItemsComponentsModule } from './user/items/shared-items-components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    NgChartsModule,
    QRCodeModule,
    IonicSelectableModule,
    Angular4PaystackModule,
    SharedCommonComponentsModule,
    SharedItemsComponentsModule,
    TransactionDetailsComponentModule,
    QrViewComponentModule,
    SwiperModule,
  ],
  declarations: [
    // NoArrowDirective,
    CustomRatingsComponent,
    TransactionItemComponent,
    CustomRatingsComponent,
  ],
  exports: [
    CustomRatingsComponent,
    TransactionItemComponent,
    CustomRatingsComponent,
    NgChartsModule,
  ],
})
export class SharedComponentsModule { }
