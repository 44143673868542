// tslint:disable: no-redundant-jsdoc
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private data: Array<any> = [];
  constructor() { }

  /**
   *
   *
   * @param id
   * @param data
   * @memberof DataService
   */
  setData(id: any, data: any): void {
    this.data[id] = data;
    console.log(this.data);
  }

  /**
   *
   *
   * @param id
   * @returns
   * @memberof DataService
   */
  getData(id: any): any {
    return this.data[id];
  }
}
