import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { VerifyPinComponent } from '@app-components/common/verify-pin/verify-pin.component';
import { balances } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { ComponentPickerService } from '@app-services/component-picker/component-picker.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { SliderConfigService } from '@app-services/slider-config/slider-config.service';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, SwiperOptions, Zoom } from 'swiper';
import { environment } from './../../../../../environments/environment.beta';
SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-pay-bill',
  templateUrl: './pay-bill.component.html',
  styleUrls: ['./pay-bill.component.scss'],
})
export class PayBillComponent implements OnInit, OnDestroy {

  @Input() title = 'Pay Bill';
  isDesktop: boolean;
  sliderConfig: SwiperOptions = {
    initialSlide: 0
  };
  data: any = {
    phone: !environment.production ? '08011111111' : '',
  };
  balances: balances = this.apiService.userBalance;
  balancesSubscription: any;

  constructor(// tslint:disable: align
    public apiService: ApiService,
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    private actionSheetCtrl: ActionSheetController,
    public componentPickerService: ComponentPickerService,
    private ref: ChangeDetectorRef,
    private sliderConfigService: SliderConfigService,
    public modalController: ModalController,
    public alertController: AlertController,
    public cryptoService: CryptoService) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
    this.sliderConfigService.getSliderConfig2().then((config) => {
      this.sliderConfig = config;
    });
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'PayBillComponent';
  }


  ngOnInit() {
    this.sliderConfigService.getSliderConfig2().then((config) => {
      this.sliderConfig = config;
    });
  }


  ionViewDidEnter() {
    this.subscribeToCurrentBalance();
  }

  subscribeToCurrentBalance() {
    this.balancesSubscription = this.apiService.getCurrentBalances.subscribe((newBalances) => {
      this.balances = newBalances;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.balancesSubscription.unsubscribe();
  }

  ionViewWillLeave() {
    this.balancesSubscription.unsubscribe();
  }

  /**
   * Dismiss Modal
   */
  dismissModal(moreData: any = {}) {
    this.modalController.dismiss({
      dismissed: true,
      moreData
    }).then(() => {
      // console.log(data);
    }).catch((err) => console.log(err));
  }


  async openVerifyPin() {
    const modal = await this.apiService.openComponent(VerifyPinComponent, {}, 80, false);
    await modal.onWillDismiss().then((info) => {
      console.log(info);
    });
    const { data } = await modal.onDidDismiss();
    console.log(data);
  }

  async canDismiss() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Are you sure you want cancel payment?',
      buttons: [
        {
          text: 'Yes, I want to',
          role: 'destructive'
        },
        {
          text: 'No, Continue',
          role: 'cancel',
        }
      ]
    });

    await actionSheet.present();
    const { role } = await actionSheet.onDidDismiss();
    if (role === 'destructive') {
      return true;
    }
    return false;
  }

}

