/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@app-environments/environment';
import { ApiErrorResponse } from '@app-models/api.response.interface';
import { PaymentsResponse } from '@app-models/api.response.interface.d';
import { CashierUsersInfo, MerchantUsersInfo } from '@app-models/app.interface.d';
import { ApiService } from '@app-services/api/api.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { DataService } from '@app-services/data/data.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import Echo, { Channel } from 'laravel-echo';
import Pusher from 'pusher-js';
import { WebSocketResponse } from './token-pay.const';
import { PARTNER_TYPES } from '@app-models/constants';

declare let window: any;
@Component({
  selector: 'app-token-pay',
  templateUrl: './token-pay.component.html',
  styleUrls: ['./token-pay.component.scss'],
})
export class TokenPayComponent implements OnInit {

  @Input() currentUser: MerchantUsersInfo | CashierUsersInfo = {};
  isDesktop: boolean;
  isPwa: any;
  asGenerated = false;
  token = '';
  amountValue: string | number = '0.00';
  data: any = {
    amount: 0,
  };
  paymentInfo: any;
  setTime = null;
  myAngularxQrCode = 'token';
  echo: Echo;
  activeDomain: 'user' | 'merchant' | 'corporate' | string = 'merchant';
  authToken = '';
  tokenType = 'Bearer';
  connection: Channel;


  constructor(
    private router: Router,
    public navController: NavController,
    private dataService: DataService,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService,
    private cryptoService: CryptoService,
    public alertController: AlertController) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      console.log('isDesktop: ', isDesktop);
      this.isDesktop = isDesktop;
    });
    this.isPwa = environment.isPwa;

  }
  ngOnInit(): void {
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'TokenPayComponent';
  }

  async getAuthToken() {
    this.authToken = this.currentUser.accessToken;
    this.tokenType = 'Bearer';
  }

  async ionViewDidEnter() {
    window.Pusher = Pusher;

    await this.getProfile();
    this.apiService.getLocalData('currentDomain').then((data) => {
      this.activeDomain = data;
      this.apiService.setCurrentDomain(this.activeDomain);

    }).catch(() => {
      this.getActiveSubdomain(this.router.url).then((data) => {
        this.activeDomain = data;
        this.apiService.setCurrentDomain(this.activeDomain);
        console.log('activeDomain:', this.activeDomain);

      });
    });
  }


  async getActiveSubdomain(c: string) {
    if (c.includes('/member/')) {
      const d = c.substr(8);
      const e = d.indexOf('/') !== -1 ? d.indexOf('/') : d.length;
      return d.substr(0, e);
    }
  }

  async setEcho() {
    /**
     * This is used to listen to events
     *
     * @type {Echo}
     */
    const options: any = {
      broadcaster: 'pusher',
      key: '9b6e8c53b9ca558aa25d',
      wsHost: `${environment.apiBaseUrlOnly || 'dev.api.kreadeet.com'}`,//this is the client's ip address or Network provider or vpn IP
      cluster: 'mt1',
      encrypted: false,
      wsPort: 6001,//this should not change
      wssPort: 6001,
      authEndpoint: `${environment.apiBaseUrlRoot}${this.currentUser.userType}/broadcasting/auth`,//the base apiUrl and broadcasting/auth
      disableStats: true,
      forceTLS: false,
      LogToConsole: true,
      enabledTransports: ['ws', 'wss'],
      auth: {
        headers: {
          Authorization: `${this.tokenType || 'Bearer'} ${this.authToken}`,
          Accept: 'application/json'
        }
      }
    };
    // console.log('options: ', options);
    this.echo = new Echo(options);

    /**
     * This is used to listen to events
     *
     * @type {Echo}
     */
    this.connection = this.echo.private(`payments.${this.token}`);
    this.connection.subscribed(() => {
      console.log('You\'re subscribed');
    }).listen('.completedPayment', (response: WebSocketResponse) => {
      console.log('socket received', response);
      this.verifyPayment(response);
    });
  }

  async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: MerchantUsersInfo) => {
      this.currentUser = res;
      console.log(res);
      this.getAuthToken();
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

  dismissModal() {
    clearInterval(this.setTime);
    this.modalController.dismiss({
      dismissed: true
    });
  }

  goBack() {
    this.navController.back();
  }

  initTokenPayment() {
    if (this.currentUser.userType === PARTNER_TYPES.CASHIER) {
      this.cashierGenerateToken();
      return;
    }
    this.generateToken();
  }

  async generateToken() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.merchantTokenInitialize(this.data))
      .then(async (res: PaymentsResponse) => {
        await loading.dismiss();
        if (res.status) {
          this.token = res?.data?.paymentToken;
          const paramData = {
            token: res?.data?.paymentToken,
            reference: res?.data?.reference,
            partnerId: res?.data?.partnerId,
            branchId: res?.data?.clerkable.branchId,
            totalAmount: res?.data?.totalAmount,
            id: res?.data?.id,
          };
          const encryptedData = this.cryptoService.encrypt(JSON.stringify(paramData));
          this.myAngularxQrCode = encryptedData;
          this.asGenerated = true;

          this.setEcho();
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  async cashierGenerateToken() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.cashierTokenInitialize(this.data))
      .then(async (res: PaymentsResponse) => {
        await loading.dismiss();
        if (res.status) {
          this.token = res?.data?.paymentToken;
          const paramData = {
            token: res?.data?.paymentToken,
            reference: res?.data?.reference,
            partnerId: res?.data?.partnerId,
            branchId: res?.data?.clerkable.branchId,
            totalAmount: res?.data?.totalAmount,
            id: res?.data?.id,
          };
          const encryptedData = this.cryptoService.encrypt(JSON.stringify(paramData));
          this.myAngularxQrCode = encryptedData;
          this.asGenerated = true;

          this.setEcho();
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  formatCurrencyOnBlur(event: Event | any) {
    const uy = this.helperMethods.formatAmount(event.detail.value.toString(), '');
    this.data.amount = event.detail.value.split(',').join('');
    this.amountValue = (uy === 'NaN') ? 0 : uy;
  }

  formatCurrencyOnFocus() {
    const dValue = this.data.amount === 0 ? 0 : parseFloat(this.data.amount.toString()).toFixed(2).toString();
    this.amountValue = (dValue === 'NaN') ? 0 : dValue;
  }

  checkValue(event = null) {
    const uy = this.helperMethods.formatAmount(event.detail.value.toString(), '').split(',').join('');
    this.data.amount = (uy === 'NaN') ? 0 : parseFloat(uy);
    if (event) {
      this.formatCurrencyOnBlur(event);
    }
  }

  async verifyPayment(response: WebSocketResponse) {
    const loading = await this.apiService.getLoader();
    await loading.present();
    if (response.data.status.toLowerCase() === 'PAID'.toLowerCase()) {
      this.apiService.successAlert(response.data.remarks);
      this.dismissModal();
      await loading.dismiss();
      this.connection.stopListening(`payments.${this.token}`);
    } else {
      this.apiService.errorAlert(response.data.remarks);
      this.dismissModal();
      await loading.dismiss();
    }
  }

  async verifyPaymentTest() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    this.apiService.successAlert('Payment Successful');
    this.dismissModal();
    await loading.dismiss();
  }


}
