/* eslint-disable max-len */
/* eslint-disable id-blacklist */
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@app-environments/environment';
import { BorrowMeFuel } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';
@Component({
  selector: 'app-borrow-me-fuel-item',
  templateUrl: './borrow-me-fuel-item.component.html',
  styleUrls: ['./borrow-me-fuel-item.component.scss'],
})
export class BorrowMeFuelItemComponent implements OnInit {

  @Input() borrowMeFuel: BorrowMeFuel = {};
  @Input() isLoading = false;
  @Input() cssClass = '';
  @Input() showToken = false;
  copied = false;
  isPwa: boolean;

  constructor(
    public apiService: ApiService,
    private helperMethods: HelperMethodsService
  ) {
    this.isPwa = environment.isPwa;
  }

  ngOnInit() { }

  async shareDetails() {
    if (!this.isPwa) {
      await Share.share({
        title: this.borrowMeFuel?.token,
        text: `Ye!, here is a token ${this.borrowMeFuel?.token}, with the value of ${this.helperMethods.formatAmount(this.borrowMeFuel?.fuelPrice + '', this.borrowMeFuel?.currency?.symbol)}`,
        url: ``,
        dialogTitle: 'Share with buddies',
      }).catch(() => {
        this.copyToken();
      });
    } else {
      this.copyToken();
    }
  }

  /**
   * copyToken
   */
  copyToken() {
    Clipboard.write({
      string: `Ye!, here is a token ${this.borrowMeFuel?.token}, with the value of ${this.helperMethods.formatAmount(this.borrowMeFuel?.fuelPrice + '', this.borrowMeFuel?.currency?.symbol)}`,
    }).then(() => {
      this.copied = true;
    });
    setTimeout(() => {
      this.copied = false;
    }, 2000);
    this.apiService.successPop('Message Copied to Clipboard');
  }

}
