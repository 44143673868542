import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appIsDevStage]'
})
export class IsDevStageDirective implements OnInit {

  @Input() appIsDevStage = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }


  ngOnInit(): void {
    // this.apiService.getAuthenticatedUser().then((user) => {
    if (this.appIsDevStage) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    // }).catch((err) => {
    //   this.viewContainer.clear();
    // });
  }
}
