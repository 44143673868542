import { Component, Input, OnInit } from '@angular/core';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { CorporateBranch, CorporateProfile, CorporateServiceProvider, ServiceProviderCategory } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-service-provider',
  templateUrl: './add-service-provider.component.html',
  styleUrls: ['./add-service-provider.component.scss'],
})
export class AddServiceProviderComponent implements OnInit {
  @Input() corporateBranchId: number;
  isDesktop = false;
  isPreview: boolean;
  categoryLoader = false;
  serviceProviderCategoryList: ReadonlyArray<ServiceProviderCategory> = [];

  serviceProviderLoader = false;
  serviceProviderList: CorporateServiceProvider[] = [];
  filteredServiceProviderList: CorporateServiceProvider[] = [];

  selectedCategoryID: number;
  selectedProviderArr: CorporateServiceProvider[] = [];
  providerCheckBoxList = [];

  branchList: ReadonlyArray<CorporateBranch> = [];
  branchListLoader = false;
  disableBranchSelection = false;

  currentUser: CorporateProfile;

  constructor(
    private modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public apiService: ApiService,
    public screenSizeService: ScreenSizeService,
  ) {
    this.screenSizeService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  async ngOnInit() {
    await this.getProfile();
    if (this.currentUser.isPrimary === 'YES') {
      this.getBranches();
    }
    this.getCategories();
    if (this.corporateBranchId) {
      this.corporateBranchId = +this.corporateBranchId;
      this.disableBranchSelection = true;
    }
  }

  dismissModal(data?) {
    this.modalController.dismiss(data);
  }

  suggestVendorHandler() {
    this.modalController.dismiss('SUGGEST_VENDOR');
  }

  setPreviewState(state) {
    this.isPreview = state;
  }

  categorySelectionHandler(categoryId: number) {
    this.selectedCategoryID = categoryId;
    this.getServiceProviders();
  }

  filterProviderHandler(e: any) {
    const searchVal = e.target.value.toLocaleLowerCase();
    if (searchVal.trim() !== '') {
      // eslint-disable-next-line arrow-body-style
      this.filteredServiceProviderList = this.serviceProviderList.filter((provider: CorporateServiceProvider) => {
        return provider.businessName.toLocaleLowerCase().includes(searchVal);
      });
    } else {
      this.filteredServiceProviderList = [...this.serviceProviderList];
    }
  }

  removeSelectedProvider(index: number) {
    const providerIndex = this.serviceProviderList.findIndex(({ merchantId }) => merchantId === this.serviceProviderList[index].merchantId);
    this.selectedProviderArr.splice(index, 1);
    this.providerCheckBoxList[providerIndex] = false;
  }

  selectedProviderChangeHandler(index: number, provider: CorporateServiceProvider) {
    console.log(index, provider, this.providerCheckBoxList[index]);
    const providerIndex = this.selectedProviderArr.findIndex(({ merchantId }) => merchantId === provider.merchantId);
    if (this.providerCheckBoxList[index]) {
      if (providerIndex < 0) {
        this.selectedProviderArr.push(provider);
      }
    } else {
      this.selectedProviderArr.splice(providerIndex, 1);
    }
    console.log(this.selectedProviderArr);
  }

  async addServiceProvider() {
    const loading = await this.apiService.getLoader();
    await loading.present();

    // eslint-disable-next-line arrow-body-style
    const selectedProviders = this.selectedProviderArr.map(elem => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      return elem.merchantId;
    });

    const payload = {
      corporateBranchId: this.corporateBranchId,
      partners: selectedProviders
    };

    await this.helperMethods.promiseTimeout(this.apiService.corporateAddProvider(payload))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.apiService.corporateAlert(
            res.message,
            'Successful',
            null,
            'corporate-alert-success.png',
            'mr-green-success.png',
            null,
            'success',
          );
          this.dismissModal(true);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }

        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });

  }

  private async getBranches() {
    this.branchListLoader = true;
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetBranches({ paginate: false }))
      .then(async (res: any) => {
        this.branchListLoader = false;
        this.branchList = [...res?.data.branches];
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.branchListLoader = false;
      });
  }

  private async getCategories() {
    this.categoryLoader = true;
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetCategories())
      .then(async (res: any) => {
        this.categoryLoader = false;
        if (res.status) {
          this.serviceProviderCategoryList = [...res?.data];
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.categoryLoader = false;
      });
  }

  private async getServiceProviders() {
    this.serviceProviderList = [];
    this.serviceProviderLoader = true;
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetPartnerByCategory(this.selectedCategoryID))
      .then(async (res: any) => {
        this.serviceProviderLoader = false;
        if (res.status) {
          this.serviceProviderList = [...res?.data.partners];
          this.filteredServiceProviderList = [...res?.data.partners];
          this.providerCheckBoxList = Array(this.serviceProviderList.length).fill(false);
          this.preSelectProvider();
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.serviceProviderLoader = false;
      });
  }

  private preSelectProvider() {
    let counter = 0;
    for (const provider of this.serviceProviderList) {
      const providerIndex = this.selectedProviderArr.findIndex(({ merchantId }) => merchantId === provider.merchantId);
      if (providerIndex > -1) {
        this.providerCheckBoxList[counter] = true;
      }
      counter++;
    }
  }

  private async getProfile() {
    await this.apiService.getAuthenticatedUser().then((res: CorporateProfile) => {
      this.currentUser = res.profile;
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

}
