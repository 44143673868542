import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppSelectableComponent } from '@app-components/common/app-selectable/app-selectable.component';
import { SelectableOption, SelectableOptionItem } from '@app-components/common/app-selectable/interface';
import { VerifyPinComponent } from '@app-components/common/verify-pin/verify-pin.component';
import { environment } from '@app-environments/environment';
import {
  ApiErrorResponse, ApiGenericResponseInfo,
  BundleOrBouquetResponse, SmartCardResponse
} from '@app-models/api.response.interface';
import { balances, BundleOrBouquet, SmartCard, UsersInfo, UtilityProvider, UtilityPurchase } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { ComponentPickerService } from '@app-services/component-picker/component-picker.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { SliderConfigService } from '@app-services/slider-config/slider-config.service';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-cable',
  templateUrl: './cable.component.html',
  styleUrls: ['./cable.component.scss'],
})
export class CableComponent implements OnInit, OnDestroy {

  @Input() title = 'Cable';
  isDesktop: boolean;
  sliderConfig: SwiperOptions = {
    initialSlide: 0
  };
  balances: balances = this.apiService.userBalance;
  balancesSubscription: any;
  cables: ReadonlyArray<UtilityProvider> = [];
  bouquets: ReadonlyArray<BundleOrBouquet> = [];
  selectedCable: UtilityProvider;
  selectedBundleOrBouquet: BundleOrBouquet;
  currentSmartCard: SmartCard;
  listOfMonth: Array<number> = Array.from({ length: 12 }, ((_, i) => i + 1));
  data: UtilityPurchase = {
    noOfMonths: 1,
    phone: !environment.production ? '08011111111' : '',
    shouldRenew: false
  };
  corporateMode = false;
  corporateBalance;

  constructor(// tslint:disable: align
    public apiService: ApiService,
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    private actionSheetCtrl: ActionSheetController,
    private helperMethods: HelperMethodsService,
    public componentPickerService: ComponentPickerService,
    private ref: ChangeDetectorRef,
    private sliderConfigService: SliderConfigService,
    public modalController: ModalController,
    public alertController: AlertController,
    public cryptoService: CryptoService) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
    this.sliderConfigService.getSliderConfig2().then((config) => {
      this.sliderConfig = config;
    });
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'CableComponent';
  }

  ngOnInit() {
    this.sliderConfigService.getSliderConfig2().then((config) => {
      this.sliderConfig = config;
    });
  }

  async ionViewDidEnter() {
    await this.apiService.getAuthenticatedUser().then((res: UsersInfo) => {
      this.corporateMode = res.profile.switchedToCorporate;
      if (!this.corporateMode) {
        this.subscribeToCurrentBalance();
        this.getCableTVProviders();
        return;
      }
      // this.getCorporateElectricityProviders();
      this.corporateBalance = {
        account:
          this.helperMethods.formatAmount(
            res?.profile?.loggedInCorporateAccount?.corporateStaffLimitConfig?.monthlyKreadeetSpent?.toString()
          ),
        balance: res.profile.loggedInCorporateAccount.corporateStaffLimitConfig.balance,
        balanceTitle: 'What you have spent: '
      };
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

  subscribeToCurrentBalance() {
    this.balancesSubscription = this.apiService.getCurrentBalances.subscribe((newBalances) => {
      this.balances = newBalances;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.balancesSubscription) {
      this.balancesSubscription.unsubscribe();
    }
  }

  ionViewWillLeave() {
    if (this.balancesSubscription) {
      this.balancesSubscription.unsubscribe();
    }
  }

  /**
   * Dismiss Modal
   */
  dismissModal(moreData: any = {}) {
    this.modalController.dismiss({
      dismissed: true,
      moreData
    }).then(() => {
      // console.log(data);
    }).catch((err) => console.log(err));
  }


  async openVerifyPin() {
    const modal = await this.apiService.openComponent(VerifyPinComponent, {}, 80, false);
    await modal.onWillDismiss().then((info) => {
      console.log(info);
    });
    const { data } = await modal.onDidDismiss();
    console.log(data);
  }

  async canDismiss() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Are you sure you want cancel payment?',
      buttons: [
        {
          text: 'Yes, I want to',
          role: 'destructive'
        },
        {
          text: 'No, Continue',
          role: 'cancel',
        }
      ]
    });

    await actionSheet.present();
    const { role } = await actionSheet.onDidDismiss();
    if (role === 'destructive') {
      return true;
    }
    return false;
  }

  async getCableTVProviders() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.getCableTVProviders())
      .then(async (res: BundleOrBouquetResponse) => {
        await loading.dismiss();
        const currentBundleOrBouquet = res.data;
        this.cables = currentBundleOrBouquet;
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
        await loading.dismiss();
      });
  }


  async getCableTvBouquet(selectedProvider: UtilityProvider) {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.getCableTvBouquet(selectedProvider))
      .then(async (res: BundleOrBouquetResponse) => {
        await loading.dismiss();
        const currentBundleOrBouquet = res.data;
        currentBundleOrBouquet.forEach(element => {
          element.formattedAmount = this.helperMethods.formatAmount(element.amount);
        });
        this.bouquets = currentBundleOrBouquet;
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
        await loading.dismiss();
      });
  }


  async openCustomCableTvSelector(items: any) {
    const selectableOption: SelectableOption = {
      title: 'Select Cable Tv Provider',
      enableSearch: true,
      items: items as Array<SelectableOptionItem>,
      itemTextField: 'name',
      // itemNoteField: 'formattedAmount',
      // itemIconField: 'imageUrl',
    };
    const modal = await this.apiService.openComponent(AppSelectableComponent, { selectableOption }, 70, false);
    await modal.onWillDismiss().then((info) => {
      console.log(info);
    });
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.selectedCable = data.moreData;
      this.data = { ...data.moreData, cableName: data.moreData.name };
      this.getCableTvBouquet(data.moreData);
      console.log(this.data);
    }
  }


  async openCustomBouquetSelector(items: any) {
    const selectableOption: SelectableOption = {
      title: 'Select Bouquet',
      enableSearch: true,
      items: items as Array<SelectableOptionItem>,
      itemTextField: 'name',
      itemNoteField: 'formattedAmount',
      // itemIconField: 'imageUrl',
    };
    const modal = await this.apiService.openComponent(AppSelectableComponent, { selectableOption }, 70, false);
    await modal.onWillDismiss().then((info) => {
      console.log(info);
    });
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.selectedBundleOrBouquet = data.moreData;
      this.data = { smartCardNumber: '', ...this.data, ...data.moreData, bouquetName: data.moreData.name };
      console.log(this.data);
    }
  }

  multiChoiceSwitchMode() {
    if (this.selectedBundleOrBouquet) {
      const isRenew = this.currentSmartCard.currentBouquetCode === this.selectedBundleOrBouquet.code;
      if (isRenew) {
        this.data = { ...this.data, shouldRenew: true };
      } else {
        this.data = { ...this.data, shouldRenew: false };
      }
    }

    return this.apiService.purchaseCableTv(this.data);
  }

  getCableToPay() {
    return this.selectedCable.className === 'STARTIMESService' ?
      this.apiService.purchaseCableTv(this.data) :
      this.multiChoiceSwitchMode();
  }


  async purchaseCableTv() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(
      this.getCableToPay()
    )
      .then(async (res: ApiGenericResponseInfo) => {
        await loading.dismiss();
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }


  async checkSmartCardNumber() {
    if (this.data.smartCardNumber.length === 10) {
      const loading = await this.apiService.getLoader();
      await loading.present();
      await this.helperMethods.promiseTimeout(this.apiService.validateSmartCard(this.data))
        .then(async (res: SmartCardResponse) => {
          await loading.dismiss();
          if (res.status) {
            this.data = {
              ...this.data,
              noOfMonths: 1,
              customerName: res?.data?.customerName,
              cableStatus: res?.data?.status,
            };
            if (res?.data?.currentBouquet !== 'N/A') {
              this.data = {
                ...this.data,
                noOfMonths: 1,
                bouquetName: res?.data?.currentBouquet,
                phone: `${res?.data?.customerNumber}`,
                code: res?.data?.currentBouquetCode,
                amount: `${res?.data?.renewalAmount || res?.data?.amount || 0}`,
              };
              this.currentSmartCard = res?.data;
            }
            console.log(this.data, res.data);
          } else {
            this.apiService.errorAlert(res.message, res?.header);
          }
        })
        .catch(async (err: ApiErrorResponse): Promise<void> => {
          console.log(err);
          await loading.dismiss();
        });
    }
  }

}
