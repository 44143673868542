import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Angular4PaystackModule } from 'angular4-paystack';
import { QRCodeModule } from 'angularx-qrcode';
import { IonicSelectableModule } from 'ionic-selectable';
import { NgChartsModule } from 'ng2-charts';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { SharedPipeModule } from 'src/app/pipes/shared-pipe.module';
import { SwiperModule } from 'swiper/angular';
import { PinComponent } from '../user/pin/pin.component';
import { AppSearchComponent } from './app-search/app-search.component';
import { AppSelectableComponent } from './app-selectable/app-selectable.component';
import { EmptyItemCardComponent } from './empty-item-card/empty-item-card.component';
import { IonicNumberInputComponent } from './ionic-number-input/ionic-number-input.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { PaymentInfoComponent } from './payment/payment-info/payment-info.component';
import { PaymentStatusComponent } from './payment/payment-status/payment-status.component';
import { QrScannerComponent } from './payment/qr-scanner/qr-scanner.component';
import { QrViewComponentModule } from './payment/qr-view/qr-view.component.module';
import { SmileIdComponent } from './payment/smile-id/smile-id.component';
import { SweepRequestComponent } from './payment/sweep-request/sweep-request.component';
import { TokenPayComponent } from './payment/token-pay/token-pay.component';
import { PublicLeftSideComponent } from './public-left-side/public-left-side.component';
import { SelectNetworkProviderComponent } from './select-network-provider/select-network-provider.component';
import { SpacerComponent } from './spacer/spacer.component';
import { VerifyPinComponent } from './verify-pin/verify-pin.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgPipesModule,
    MomentModule,
    SwiperModule,
    Angular4PaystackModule,
    NgChartsModule,
    IonicSelectableModule,
    TranslateModule,
    SharedPipeModule,
    QRCodeModule,
    QrViewComponentModule
  ],
  declarations: [
    PasswordStrengthComponent,
    IonicNumberInputComponent,
    SpacerComponent,
    AppSearchComponent,
    SelectNetworkProviderComponent,
    VerifyPinComponent,
    AppSelectableComponent,
    PublicLeftSideComponent,
    EmptyItemCardComponent,
    PaymentInfoComponent,
    PaymentStatusComponent,
    QrScannerComponent,
    SmileIdComponent,
    TokenPayComponent,
    SweepRequestComponent,
    PinComponent,
  ],
  exports: [
    PasswordStrengthComponent,
    IonicNumberInputComponent,
    SpacerComponent,
    AppSearchComponent,
    SelectNetworkProviderComponent,
    VerifyPinComponent,
    AppSelectableComponent,
    PublicLeftSideComponent,
    EmptyItemCardComponent,
    PaymentInfoComponent,
    PaymentStatusComponent,
    QrScannerComponent,
    SmileIdComponent,
    TokenPayComponent,
    SweepRequestComponent,
    PinComponent,
  ]
})
export class SharedCommonComponentsModule { }
