import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

@Component({
  selector: 'app-ionic-number-input',
  templateUrl: './ionic-number-input.component.html',
  styleUrls: ['./ionic-number-input.component.scss'],
})
export class IonicNumberInputComponent implements OnInit {

  @Output() currentValueOutput: EventEmitter<string> = new EventEmitter();
  @Input() currentValue = '';
  @Input() length = 6;
  @Input() isDisable = false;
  @Input() isNumDisable = false;
  @Input() enableBiometric = false;
  @Input() cssClass = '';

  allNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

  row1 = [1, 2, 3];
  row2 = [4, 5, 6];
  row3 = [7, 8, 9];
  row4 = [0];

  constructor() { }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'IonicNumberInputComponent';
  }

  ngOnInit() {
    this.allNumber = this.shuffle(this.allNumber);
    this.row1 = this.allNumber.slice(0, 3);
    this.row2 = this.allNumber.slice(3, 6);
    this.row3 = this.allNumber.slice(6, 9);
    this.row4 = this.allNumber.slice(9, 10);
    console.log(this.allNumber);
  }

  add(value: any) {
    this.currentValue += value;
    this.callEvent();
    // console.log(this.currentValue);
    // if (this.currentValue.length <= this.length) {
    //   this.callEvent();
    // }
  }

  removeData() {
    console.log('clicked');
    this.currentValue = this.currentValue.slice(0, -1);
    this.callEvent();
  }

  callEvent() {
    const hapticsImpactMedium = async () => {
      await Haptics.impact({ style: ImpactStyle.Medium });
    };
    this.currentValueOutput.emit(this.currentValue);
    if (this.currentValue.length === this.length) {
      this.currentValue = '';
    }
    hapticsImpactMedium();
  }

  shuffle(array: Array<any>): Array<any> {
    let currentIndex = array.length;
    let randomIndex = 0;
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

}
