/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { ApiErrorResponse, ApiGenericResponseInfo } from '@app-models/api.response.interface';
import { CorporateBranch, CorporatePartner, CorporateStaff } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { ModalController } from '@ionic/angular';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-add-staff-provider',
  templateUrl: './add-staff-provider.component.html',
  styleUrls: ['./add-staff-provider.component.scss'],
})
export class AddStaffProviderComponent implements OnInit {
  @Input() staff: CorporateStaff;
  branchList: ReadonlyArray<CorporateBranch> = [];
  branchListLoader = false;
  branchId: number;

  providerList: ReadonlyArray<CorporatePartner> = [];
  providerLoader = false;

  addProviderForm = {
    partners: null,
    staffId: null,
    // corporateBranchId: null
  };
  staffName = '';
  isDesktop = false;

  constructor(
    private modalController: ModalController,
    private apiService: ApiService,
    private helperMethods: HelperMethodsService,
    public screenSizeService: ScreenSizeService,
  ) {
    this.screenSizeService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit() {
    this.branchId = this.staff.corporateBranch.corporateBranchId;
    this.addProviderForm.staffId = this.staff.staffId;
    this.staffName = `${this.staff.fName} ${this.staff.lName} (${this.staff.email})`;
    this.getBranches();
    this.getServiceProviders();
  }

  dismissModal(data?: boolean) {
    this.modalController.dismiss(data);
  }

  async addStaffProvider() {
    const loading = await this.apiService.getLoader();
    await loading.present();

    await this.helperMethods.promiseTimeout(this.apiService.corporateAddStaffProvider(this.addProviderForm))
      .then(async (res: ApiGenericResponseInfo) => {
        if (res.status) {
          this.apiService.corporateAlert(
            res.message,
            'Successful',
            null,
            'corporate-alert-success.png',
            'mr-green-success.png',
            null,
            'success',
          );
          this.dismissModal(true);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }

        await loading.dismiss();
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message);
      });
  }

  private async getBranches() {
    this.branchListLoader = true;
    await this.helperMethods.promiseTimeout(this.apiService.corporateGetBranches({ paginate: false }))
      .then(async (res: any) => {
        this.branchListLoader = false;
        this.branchList = [...res?.data.branches];
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.branchListLoader = false;
      });
  }

  private async getServiceProviders() {
    this.providerLoader = true;
    const providerParams = {
      branchId: this.staff.corporateBranch.corporateBranchId,
      paginate: true
    };

    await this.helperMethods.promiseTimeout(this.apiService.corporateGetServiceProviders(providerParams))
      .then(async (res: any) => {
        this.providerLoader = false;
        this.providerList = [...res?.data.partners];
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        this.providerLoader = false;
      });
  }

}
