import { Component, OnInit } from '@angular/core';
import { environment } from '@app-environments/environment';
import { ApiService } from '@app-services/api/api.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  isPwa: any;
  data: any = {};

  constructor(
    public navController: NavController,
    public apiService: ApiService,
    public modalController: ModalController,
    private helperMethods: HelperMethodsService,
    public toastController: ToastController,
    public loadingController: LoadingController) {
    this.isPwa = environment.isPwa;
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'ChangePasswordComponent';
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  ngOnInit() {
  }

  goBack(page: string = null) {
    if (page) {
      this.navController.navigateBack(page);
    } else {
      this.navController.back();
    }
  }

  async changeUserPin() {
  }

}
