/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: true,
  stage: 'beta',
  appVerCode: '0.0.1',
  isPwa: true,
  apiBaseUrlOnly: 'kreadbeta-api.kreadeet.com',
  apiBaseUrlRoot: 'https://kreadbeta-api.kreadeet.com/',
  apiBaseUrl: 'https://kreadbeta-api.kreadeet.com/api/v1',
  defaultImageUrl: 'assets/img/avatar.svg',
  monnify: {
    isTestMode: false,
    apiKey: 'MK_PROD_A7RSTWKRQ3',
    contractCode: '458189397897',
    paymentDescription: 'Kreadeet',
  },
  paystack: {
    publicKey: 'pk_live_5657aef4ae27d2fec6ed98b3d962e1bc3d67f644',
  },
  mono: {
    publicKey: 'live_pk_1zDw1YrB1sqRHgGqVqQo',
  },
  remita: {
    key: 'QzAwMDAyNzEyNTl8MTEwNjE4NjF8OWZjOWYwNmMyZDk3MDRhYWM3YThiOThlNTNjZTE3ZjYxOTY5NDdmZWE1YzU3NDc0ZjE2ZDZjNTg1YWYxNWY3NWM4ZjMzNzZhNjNhZWZlOWQwNmJhNTFkMjIxYTRiMjYzZDkzNGQ3NTUxNDIxYWNlOGY4ZWEyODY3ZjlhNGUwYTY=',
  },
  cryptoInfo: {
    keyId: 'test',
    salt: '4321',
    keySize: 256,
    iterations: 23,
    keys: 'UbfKIjpofcgPrFAgk46P+hNM/Hs=',
    iv: '12345678909876541234567890987654',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBcT2irmArohRTHkTGwIYF2rZkg6mDJdkA',
    authDomain: 'kreadeet-2021.firebaseapp.com',
    projectId: 'kreadeet-2021',
    storageBucket: 'kreadeet-2021.appspot.com',
    messagingSenderId: '720406818729',
    appId: '1:720406818729:web:ce70db1352b4ddef761eb8',
    measurementId: 'G-24EP7J2RGY'
  },
  appInfo: {
    ios: 'com.kreadeet.app',
    android: 'com.kreadeet.app'
  },
  mapID: 'AIzaSyA29bOoP7ZKBTMKYAHLukB06ExZsQ4pFGs',
  onesignal: {
    appId: 'f5033814-8b61-4c19-9620-a76bf2e1c46d',
    googleProjectNumber: '720406818729'
  },
  socialCredential: {
    FACEBOOK_CLIENT_ID: '',
    FACEBOOK_CLIENT_SECRET: '',
    LINKEDIN_CLIENT_ID: '',
    LINKEDIN_CLIENT_SECRET: '',
    GOOGLE: {
      WEB: {
        GOOGLE_CLIENT_ID: '',
        GOOGLE_CLIENT_SECRET: '',
      },
      ANDROID: {
        GOOGLE_CLIENT_ID: '',
        GOOGLE_CLIENT_SECRET: '',
      },
      IOS: {
        GOOGLE_CLIENT_ID: '',
        GOOGLE_CLIENT_SECRET: '',
      }
    }
  }
};
