import { Component, Input, OnInit } from '@angular/core';
import { TransactionsInfo } from '@app-models/app.interface';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { SliderConfigService } from '@app-services/slider-config/slider-config.service';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-transaction-item',
  templateUrl: './transaction-item.component.html',
  styleUrls: ['./transaction-item.component.scss'],
})
export class TransactionItemComponent implements OnInit {

  @Input() loading = false;
  @Input() data: TransactionsInfo = {
  };
  @Input() type = '';
  @Input() receiptType = 1;
  constructor(// tslint:disable: align
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    public modalController: ModalController,
    public sliderConfigService: SliderConfigService,
    public helperMethods: HelperMethodsService,
    public alertController: AlertController) { }

  ngOnInit() { }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'TransactionItemComponent';
  }

  goTo(page: string) {
    this.navController.navigateForward(page);
  }

  async viewDetails() {
    // const modal = await this.modalController.create({
    //   component: TransactionDetailsComponent,
    //   backdropDismiss: true,
    //   canDismiss: true,
    //   componentProps: {
    //     data: this.data,
    //     type: this.type,
    //     receiptType: this.receiptType
    //   },
    //   animated: false,
    //   mode: 'ios',
    //   cssClass: this.helperMethods.getModalStyleFull(this.screenSizeService.widthSize.value),
    // });
    // await modal.present();
    // const { data } = await modal.onDidDismiss();
    // if (data) {
    //   console.log(data);
    // }
  }

  getUpOrDown() {
    const types = ['up', 'down'];
    return this.pickRandom(types);
  }

  pickRandom(arr: any[]) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

}
