import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-item-card',
  templateUrl: './empty-item-card.component.html',
  styleUrls: ['./empty-item-card.component.scss'],
})
export class EmptyItemCardComponent implements OnInit {

  @Input() icon = 'assets/icon/empty-state.svg';
  @Input() title = 'No Items';
  @Input() desc = '';

  constructor() { }

  ngOnInit() { }

}
