import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppSelectableComponent } from '@app-components/common/app-selectable/app-selectable.component';
import { SelectableOption, SelectableOptionItem } from '@app-components/common/app-selectable/interface';
import { VerifyPinComponent } from '@app-components/common/verify-pin/verify-pin.component';
import { environment } from '@app-environments/environment.beta';
import { ApiErrorResponse, BundleOrBouquetResponse, ProviderResponse } from '@app-models/api.response.interface';
import { balances, BundleOrBouquet, UsersInfo, UtilityProvider } from '@app-models/app.interface';
import { ApiService } from '@app-services/api/api.service';
import { ComponentPickerService } from '@app-services/component-picker/component-picker.service';
import { CryptoService } from '@app-services/crypto/crypto.service';
import { HelperMethodsService } from '@app-services/helper-methods/helper-methods.service';
import { ScreenSizeService } from '@app-services/screen-size/screen-size.service';
import { SliderConfigService } from '@app-services/slider-config/slider-config.service';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, SwiperOptions, Zoom } from 'swiper';
SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-data-bundle',
  templateUrl: './data-bundle.component.html',
  styleUrls: ['./data-bundle.component.scss'],
})
export class DataBundleComponent implements OnInit, OnDestroy {

  @Input() title = 'Data Bundle';
  isDesktop: boolean;
  sliderConfig: SwiperOptions = {
    initialSlide: 0
  };
  data: UtilityProvider = {
    bundle: '',
    phone: !environment.production ? '08011111111' : '',
  };
  bundle: ReadonlyArray<BundleOrBouquet> = [];
  balances: balances = this.apiService.userBalance;
  balancesSubscription: any;
  networks: ReadonlyArray<UtilityProvider> = [];
  corporateMode = false;
  corporateBalance;

  constructor(// tslint:disable: align
    public apiService: ApiService,
    public navController: NavController,
    public loadingController: LoadingController,
    public screenSizeService: ScreenSizeService,
    private actionSheetCtrl: ActionSheetController,
    public componentPickerService: ComponentPickerService,
    private ref: ChangeDetectorRef,
    private helperMethods: HelperMethodsService,
    private sliderConfigService: SliderConfigService,
    public modalController: ModalController,
    public alertController: AlertController,
    public cryptoService: CryptoService) {
    this.screenSizeService.isDesktop.subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
    this.sliderConfigService.getSliderConfig2().then((config) => {
      this.sliderConfig = config;
    });
  }

  /**
   * Get Class Name
   */
  getClassName() {
    return 'DataBundleComponent';
  }

  ngOnInit() {
    this.sliderConfigService.getSliderConfig2().then((config) => {
      this.sliderConfig = config;
    });
    // this.openCustomSelector();
  }

  async ionViewDidEnter() {
    await this.apiService.getAuthenticatedUser().then((res: UsersInfo) => {
      this.corporateMode = res.profile.switchedToCorporate;
      if (!this.corporateMode) {
        this.subscribeToCurrentBalance();
        this.getNetworks();
        return;
      }
      this.getCorporateNetworks();
      this.corporateBalance = {
        account:
          this.helperMethods.formatAmount(
            res?.profile?.loggedInCorporateAccount?.corporateStaffLimitConfig?.monthlyKreadeetSpent?.toString()
          ),
        balance: res.profile.loggedInCorporateAccount.corporateStaffLimitConfig.balance,
        balanceTitle: 'What you have spent: '
      };
    }).catch((err: ApiErrorResponse) => {
      console.log(err);
    });
  }

  subscribeToCurrentBalance() {
    this.balancesSubscription = this.apiService.getCurrentBalances.subscribe((newBalances) => {
      this.balances = newBalances;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.balancesSubscription) {
      this.balancesSubscription.unsubscribe();
    }
  }

  ionViewWillLeave() {
    if (this.balancesSubscription) {
      this.balancesSubscription.unsubscribe();
    }
  }

  /**
   * Dismiss Modal
   */
  dismissModal(moreData: any = {}) {
    this.modalController.dismiss({
      dismissed: true,
      moreData
    }).then(() => {
      // console.log(data);
    }).catch((err) => console.log(err));
  }

  async getNetworks() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.getDataProviders())
      .then(async (res: ProviderResponse) => {
        await loading.dismiss();
        console.log(res);
        if (res.status) {
          this.networks = res.data;
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
        await loading.dismiss();
      });
  }

  async getCorporateNetworks() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.getCorporateDataProviders())
      .then(async (res: ProviderResponse) => {
        await loading.dismiss();
        console.log(res);
        if (res.status) {
          this.networks = res.data;
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
        await loading.dismiss();
      });
  }

  onNetworkSelected(selectedProvider: UtilityProvider) {
    console.log(selectedProvider);
    this.data.className = selectedProvider.className;
    if (!this.corporateMode) {
      this.getDataBundles(selectedProvider);
      return;
    }
    this.getCorporateDataBundles(selectedProvider);

  }


  async getDataBundles(selectedProvider: UtilityProvider) {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.getDataBundles(selectedProvider))
      .then(async (res: BundleOrBouquetResponse) => {
        await loading.dismiss();
        const currentBundleOrBouquet = res.data;
        currentBundleOrBouquet.forEach(element => {
          element.formattedAmount = this.helperMethods.formatAmount(element.amount);
        });
        this.bundle = currentBundleOrBouquet;
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
        await loading.dismiss();
      });
  }

  async getCorporateDataBundles(selectedProvider: UtilityProvider) {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.getCorporateDataBundles(selectedProvider))
      .then(async (res: BundleOrBouquetResponse) => {
        await loading.dismiss();
        const currentBundleOrBouquet = res.data;
        currentBundleOrBouquet.forEach(element => {
          element.formattedAmount = this.helperMethods.formatAmount(element.amount);
        });
        this.bundle = currentBundleOrBouquet;
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        console.log(err);
        await loading.dismiss();
      });
  }

  dataPurchaseHandler() {
    if (!this.corporateMode) {
      this.purchaseData();
      return;
    }
    this.purchaseCorporateData();
  }

  async purchaseData() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.purchaseData(this.data))
      .then(async (res: ProviderResponse) => {
        await loading.dismiss();
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }

  async purchaseCorporateData() {
    const loading = await this.apiService.getLoader();
    await loading.present();
    await this.helperMethods.promiseTimeout(this.apiService.purchaseCorporateData(this.data))
      .then(async (res: ProviderResponse) => {
        await loading.dismiss();
        if (res.status) {
          this.dismissModal();
          this.apiService.successAlert(res.message);
        } else {
          this.apiService.errorAlert(res.message, res?.header);
        }
      })
      .catch(async (err: ApiErrorResponse): Promise<void> => {
        await loading.dismiss();
        this.apiService.errorAlert(err.message, err?.header);
        if (err?.shouldLogout) {
          await this.apiService.logout().then(() => {
            this.navController.navigateRoot(`public/${this.apiService.currentDomain}`);
          }).catch((logoutErr) => console.log(logoutErr));
        }
      });
  }


  async openVerifyPin() {
    const modal = await this.apiService.openComponent(VerifyPinComponent, {}, 80, false);
    await modal.onWillDismiss().then((info) => {
      console.log(info);
    });
    const { data } = await modal.onDidDismiss();
    console.log(data);
  }

  async canDismiss() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Are you sure you want cancel payment?',
      buttons: [
        {
          text: 'Yes, I want to',
          role: 'destructive'
        },
        {
          text: 'No, Continue',
          role: 'cancel',
        }
      ]
    });

    await actionSheet.present();
    const { role } = await actionSheet.onDidDismiss();
    if (role === 'destructive') {
      return true;
    }
    return false;
  }

  async openCustomSelector(items = this.bundle) {
    const selectableOption: SelectableOption = {
      title: 'Select Bundle',
      enableSearch: true,
      items: items as Array<SelectableOptionItem>,
      itemTextField: 'name',
      itemNoteField: 'formattedAmount',
    };
    const modal = await this.apiService.openComponent(AppSelectableComponent, { selectableOption }, 70, false);
    await modal.onWillDismiss().then((info) => {
      console.log(info);
    });
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.data = { ...this.data, ...data.moreData, bundle: data.moreData.name };
    }
    console.log(this.data);
  }
}
